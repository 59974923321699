import { InfoModal, Spacer } from "../index";
import React from "react";

const WrongWalletModal: React.FC<any> = ({ toWallet, onDismiss }) => {
  return (
    <InfoModal
      message={
        <div>
          You are connected to another wallet.
          <br />
          <Spacer size="xs" />
          That's cool, but switch to <b>{toWallet}</b> in order to use our dApp.
          <br />
          <Spacer size="xs" />
          Logout connected wallet and connect with <b>{toWallet}</b>.
        </div>
      }
      withCloseButton={false}
      handleActionButton={onDismiss}
      actionButtonText={`Close`}
      actionButtonNoDismiss={true}
      onDismiss={onDismiss}
    />
  );
};

export default WrongWalletModal;
