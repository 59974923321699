import React from "react";
import styled from "styled-components";
import { Column } from "../../components";

const MainContainer: React.FC<any> = ({ children, style }) => {
  return (
    <StyledMainContainer style={{ ...style }}>{children}</StyledMainContainer>
  );
};

const StyledMainContainer = styled(Column)`
  padding: 2rem 0px 4.6em;
  width: min(1040px, calc(100vw - 32px));
`;

export default MainContainer;
