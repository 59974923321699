import React from "react";

const CloseIcon: React.FC<{ fill?: string; style?: any }> = ({
  fill,
  style,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      style={{ ...style }}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-384 -535)">
          <g transform="translate(250 431)">
            <g transform="translate(134 104)">
              <path
                fill={fill ? fill : "#090C0D"}
                d="M3.613 2.21l.094.083L8 6.585l4.293-4.292a1 1 0 011.497 1.32l-.083.094L9.415 8l4.292 4.293a1 1 0 01-1.32 1.497l-.094-.083L8 9.415l-4.293 4.292a1 1 0 01-1.497-1.32l.083-.094L6.585 8 2.293 3.707a1 1 0 011.32-1.497z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
