import styled from "styled-components";

interface ITypo {
  color?: string;
  weight?: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900 | "bold";
}

const Typo = styled.p<ITypo>`
  color: ${(props) =>
    props.color ? props.color : props.theme.fontColor.secondary};
  font-weight: ${(props) => props.weight && props.weight};
  margin-bottom: 0;
  margin-top: 0;
`;

export const Typo1 = styled(Typo)`
  font-size: 18px;
`;

export const Typo2 = styled(Typo)`
  font-size: 16px;
`;

export const Typo3 = styled(Typo)`
  font-size: 14px;
`;

export const Typo4 = styled(Typo)`
  font-size: 12px;
`;
