import styled from "styled-components";

export {
  animateBottom,
  animateTop,
  slideDown,
  slideUp,
  slideToTop,
  zoomIn,
} from "./animations";
export { default as Card, CardSkeleton, CardLink } from "./Card";
export { default as CardCaroussel } from "./CardCaroussel";
export { default as Column } from "./Column";
export { default as ConsentModal } from "./ConsentModal";
export { default as CountDown } from "./CountDown";
export { default as DropDownButton } from "./DropDownButton";
export { LabeledCheckbox, Checkbox, Label } from "./FormFields";
export type { ICheckbox, ILabel } from "./FormFields";
export { default as FractalDisplay } from "./Fractal";
export { Heading1, Heading2, Heading3, Heading4, Heading5 } from "./Heading";
export { default as InfoModal } from "./InfoModal";
export { default as InputCurrency, InputCurrencyBox } from "./InputCurrency";
export { default as LanguageSelector } from "./LanguageSelector";
export { default as FSLoader } from "./Loader";
export { default as Modal } from "./Modal";
export type { ModalProps, ModalStyling } from "./Modal";
export { default as ModalActions } from "./ModalActions";
export { default as ModalClose } from "./ModalClose";
export { default as ModalContent } from "./ModalContent";
export { default as ModalImage } from "./ModalImage";
export { default as ModalTitle } from "./ModalTitle";
export { default as Row } from "./Row";
export { default as Scrollbar } from "./Scrollbar";
export { default as SliderWithMarks } from "./Slider";
export { default as SocialLinks } from "./SocialLinks";
export { default as Spacer } from "./Spacer";
export { default as StatPair } from "./StatPair";
export { default as TxModal } from "./TxModal";
export { Typo1, Typo2, Typo3, Typo4 } from "./Typo";
export { default as VideoBackground } from "./VideoBackground";
export { default as WalletButton } from "./WalletButton";
export { default as WalletSelector, WalletSelectView } from "./WalletSelector";
export { default as InstallBanner } from "./InstallBanner";
export { default as Banner } from "./Banner";

export const Body = styled.div`
  font-family: "NeuzeitOfficeW04", sans-serif;
  background-color: ${(props) => props.theme.color.greys.first()};
  color: ${(props) => props.theme.fontColor.primary};
  display: flex;
  min-height: calc(100vh);
  display: flex;
  flex-direction: row;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  button {
    font-family: "Inter";
  }
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #61dafb;
  margin-top: 10px;
`;

export const WrapA = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const LinkExt = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: ${(props) => props.theme.color.primary.first()};

  :visited {
    color: ${(props) => props.theme.color.greys.third()};
  }
`;

export const OverlayButton = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  border: none;
  text-decoration: none;
  cursor: ${(props) => !props.disabled && "pointer"};
  color: inherit;
`;

export const Button = styled.button<{
  variant: "primary" | "secondary" | "tertiary" | "quaternary" | "quinary";
  padding?: string;
  color?: string;
  fontSize?: string;
  disabled?: boolean;
  width?: string;
}>`
  background: ${(props) =>
    props.variant === "primary"
      ? props.theme.color.primary.third(props.disabled ? 0.6 : 1)
      : props.variant === "secondary"
      ? props.theme.color.secondary.first(props.disabled ? 0.6 : 1)
      : props.variant === "tertiary"
      ? "linear-gradient(90deg,#765cde 0,#df73ec 50%,#f8b16b 99%)"
      : props.variant === "quinary"
      ? "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)"
      : "#e6e6e6"};
  border: none;
  border-radius: 20px;
  color: ${(props) =>
    !props.disabled
      ? props.variant === "quaternary"
        ? "#0c1727"
        : props.color || props.theme.color.primary.second()
      : "#bac3ba"};
  cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  padding: ${(props) => (props.padding ? props.padding : "10px 24px")};
  transition: 0.2s all;
  width: ${(props) => props.width && props.width};

  ${(props) => props.hidden && "hidden"} :focus {
    border: ${(props) => props.variant === "primary" && "none"};
    outline: none;
  }

  :active {
    transform: scale(0.98);
  }
`;

export const IconButton = styled.button<{
  icon: string;
  size: number;
  height?: string;
  width?: string;
}>`
  background-color: transparent;
  background-image: ${(props) => `url(${props.icon})`};
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 5em;
  cursor: pointer;
  height: ${(props) => (props.height ? props.height : `${props.size}em`)};
  width: ${(props) => (props.height ? props.height : `${props.size}em`)};

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    background-color: #000;
    background-position-x: calc(100% - 1.7em);
    color: #fff;
    padding-left: 2.5em;
    padding-right: 4.9em;
    width: ${(props) => (props.width ? props.width : "unset")};
  }
`;

export const Container = styled.div<{ padding?: string }>`
  border: ${(props) => `1px solid ${props.theme.color.primary.second()}`};
  padding: ${(props) => (props.padding ? props.padding : "2rem")};
  background-color: ${(props) => props.theme.color.greys.second()};
  border-radius: 8px;
`;

export const ContentBox = styled.div<{ padding?: string }>`
  background-color: ${(props) => props.theme.color.greys.third()};
  display: inline-flex;
  padding: ${(props) => (props.padding ? props.padding : "2rem")};
  border-radius: 8px;
`;

export const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
`;

export const Input = styled.input<{
  icon?: string;
  size?: number;
  small?: boolean;
}>`
  flex: 1;
  background-color: transparent;
  border: none;
  color: #4c525b;
  font-size: ${({ size }) => (size ? `${size}rem` : "1.25rem")};
  // width: ${({ small }) => (small ? "5rem" : "initial")};
  ${(props) => props.small && `width: 5rem`};

  :focus {
    outline: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type="number"] {
    -moz-appearance: textfield;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 90px;
  }
`;

export const TextArea = styled.textarea`
  // font-family: "proxima-nova", sans-serif;
  flex: 1;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 20px;
  padding-top: 1.5rem;
  height: 170px;
  resize: none;
  line-height: 24px;

  :focus {
    outline: none;
  }
`;

type Breakpoint = "mobile" | "tablet" | "desktop" | "ultra" | "giga";

export const HideForBreakpoint = styled.div<{ size: Breakpoint }>`
  @media (min-width: ${(props) => props.theme.breakpoints[props.size]}px) {
    display: none;
  }
`;

export const ShowForBreakpoint = styled.div<{ size: Breakpoint }>`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints[props.size]}px) {
    display: block;
  }
`;
