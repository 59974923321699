// @ts-ignore
import * as uritemplate from "uri-template";
const prefs = {
  frontendServer: process.env.REACT_APP_FRACTAL_FRONTEND_SERVER,
  authServer: process.env.REACT_APP_FRACTAL_AUTH_SERVER,
  resourceServer: process.env.REACT_APP_FRACTAL_RESOURCE_SERVER,
  client_id: process.env.REACT_APP_FRACTAL_CLIENT_ID,
  client_secret: process.env.REACT_APP_FRACTAL_CLIENT_SECRET,
  scope:
    "contact:read verification.basic:read verification.basic.details:read verification.liveness:read " +
    "verification.liveness.details:read verification.wallet:read verification.wallet.details:read",
  redirect_uri: `${process.env.REACT_APP_FANTOM_STARTER_URI}/projects/oauth/callback`,
  response_type: "code",
};

const useFractal = () => {
  const loginTemplate = uritemplate.parse(
    `${prefs.frontendServer}/authorize{?client_id,redirect_uri,response_type,scope}`
  );

  return {
    loginUri: (redirectQueryParamString?: string) => {
      const updatedPrefs = {
        ...prefs,
        redirect_uri: `${prefs.redirect_uri}${
          redirectQueryParamString ? redirectQueryParamString : ""
        }`,
      };
      return loginTemplate.expand({ ...updatedPrefs });
    },
  };
};

export default useFractal;
