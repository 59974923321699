import React from "react";
import Modal from "../Modal";
import { Heading3, OverlayButton, Typo2, Heading5, Heading4 } from "../index";
import Spacer from "../Spacer";
import { fantomStarterLogo } from "../../assets";
import { AppleShareIcon } from "../../assets";
import styled from "styled-components";

const InstructionModal: React.FC<any> = ({ onClose, onDismiss }) => {
  return (
    <Modal
      style={{
        borderRadius: "8px",
      }}
    >
      <ImgWrapper>
        <img
          alt="FS Logo"
          src={fantomStarterLogo}
          style={{ width: "64px", height: "64px", flexShrink: 0 }}
        />
      </ImgWrapper>
      <Heading3
        style={{
          marginTop: "15px",
        }}
      >
        Install FantomStarter
      </Heading3>
      <Spacer size="xxs" />
      <Typo2>
        Install this application on your homescreen for a better experience.
      </Typo2>
      <Spacer size="sm" />
      <Heading5>
        {"Tap "}
        <img
          alt="Apple Share"
          src={AppleShareIcon}
          style={{ width: "20px", height: "20px", flexShrink: 0 }}
        />{" "}
        then &quot;Add to Home Screen&quot;
      </Heading5>
      <Spacer />
      <OverlayButton
        onClick={() => {
          onClose();
          onDismiss();
        }}
      >
        <Heading4
          style={{
            color: "#3D86EC",
          }}
        >
          CLOSE
        </Heading4>
      </OverlayButton>
    </Modal>
  );
};

const ImgWrapper = styled.div`
  position: absolute;
  margin-top: -64px;
  left: calc(50% - 32px);
`;

export default InstructionModal;
