import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import NavMenuLink from "./NavMenuLink";

const NavMenu: React.FC<any> = ({ linkCallback }) => {
  const location = useLocation();

  return (
    <StyledNavMenu>
      {process.env.REACT_APP_FS_PROJECTS !== "off" && (
        <NavMenuLink
          linkCallback={linkCallback}
          name="Projects"
          path="/projects"
          currentPath={location.pathname}
        />
      )}
      {process.env.REACT_APP_FS_STAKE !== "off" && (
        <NavMenuLink
          linkCallback={linkCallback}
          name="FSmart Club"
          path="/staking"
          currentPath={location.pathname}
        />
      )}
      {process.env.REACT_APP_FS_STORE !== "off" && (
        <NavMenuLink
          linkCallback={linkCallback}
          name="Shop"
          path="/store"
          currentPath={location.pathname}
        />
      )}
      {process.env.REACT_APP_FS_FAUCET !== "off" && (
        <NavMenuLink
          linkCallback={linkCallback}
          name="Faucet"
          path="/faucet"
          currentPath={location.pathname}
        />
      )}
      {process.env.REACT_APP_FS_REWARDS !== "off" && (
        <NavMenuLink
          linkCallback={linkCallback}
          name="Rewards"
          path="/rewards"
          currentPath={location.pathname}
        />
      )}
      {process.env.REACT_APP_FS_EARN !== "off" && (
        <NavMenuLink
          linkCallback={linkCallback}
          name="Earn"
          path="/earn"
          currentPath={location.pathname}
        />
      )}
      {process.env.REACT_APP_FS_PROPOSE_DEAL !== "off" && (
        <NavMenuLink
          linkCallback={() => {
            linkCallback();
            window.open("https://form.typeform.com/to/yQnDGQUZ", "_blank");
          }}
          name="Propose deal"
          path="/"
          currentPath={location.pathname}
        />
      )}
      {process.env.REACT_APP_FS_GOVERNANCE !== "off" && (
        <NavMenuLink
          linkCallback={() => {
            linkCallback();
            window.open("https://vote.fantomstarter.io/", "_blank");
          }}
          name="Governance"
          path="/"
          currentPath={location.pathname}
        />
      )}
    </StyledNavMenu>
  );
};

const StyledNavMenu = styled.div`
  background-color: ${(props) => props.theme.color.white};
  display: flex;
  gap: 4em;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1em;
  }
`;

export default NavMenu;
