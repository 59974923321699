export default function registerServiceWorker() {
  if (
    "serviceWorker" in navigator &&
    process.env.REACT_APP_FS_SERVICE_WORKER !== "off"
  ) {
    const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/serviceworker.js?firebaseApiKey=${apiKey}`;
      navigator.serviceWorker
        .register(swUrl)
        .then((reg) => console.log("Success: ", reg.scope))
        .catch((err) => console.log("Failure: ", err));
    });
  }
}
