import erc20 from "./abis/erc20.json";
import pair from "./abis/pair.json";
import router01 from "./abis/router01.json";
import router02 from "./abis/router02.json";
import tierNFTFactory from "./abis/fantom-starter/tierNFTFactory.abi.json";
import tierNFTStore from "./abis/fantom-starter/tierNFTStore.abi.json";
import tierStake from "./abis/fantom-starter/tierStake.abi.json";
import tierStakeV2 from "./abis/fantom-starter/tierStakeV2.abi.json";
import fsFaucet from "./abis/fantom-starter/fsFaucet.abi.json";
import fsIdo from "./abis/fantom-starter/fsIdo.abi.json";
import fsLottery from "./abis/fantom-starter/fsLottery.abi.json";
import fsIdoLottery from "./abis/fantom-starter/fsIdoLottery.abi.json";
import tierNftAirdrop from "./abis/fantom-starter/tierNftAirdrop.abi.json";
import stakingContract from "./abis/fantom-starter/stakingContract.abi.json";
import nftSaleContract from "./abis/fantom-starter/fsNFTSale.abi.json";
import fsSaleV4 from "./abis/fantom-starter/fsSaleV4.abi.json";

export default {
  erc20,
  pair,
  router01,
  router02,
  tierNFTFactory,
  tierNFTStore,
  tierStake,
  tierStakeV2,
  fsFaucet,
  fsIdo,
  fsLottery,
  fsIdoLottery,
  tierNftAirdrop,
  stakingContract,
  nftSaleContract,
  fsSaleV4,
};
