import { useEffect, useState } from "react";
import { Contract } from "@ethersproject/contracts";
import { loadContract, loadDefaultProvider } from "../utils/wallet";
import { useWalletProvider } from "./index";
// @ts-ignore
import { addresses, abis } from "@fs/contracts";

const useLoadFSContract = (contractName: string, chainId: number) => {
  const { walletContext } = useWalletProvider();
  const [contract, setContract] = useState<Promise<Contract>>(
    loadContract(
      loadDefaultProvider(walletContext, chainId),
      addresses[chainId][contractName],
      abis[contractName]
    )
  );
  const [withSigner, setWithSigner] = useState(false);
  const [isLoadedOnChain, setIsLoadedOnChain] = useState(null);

  const getLoadedContract = async () => {
    const loadedContract = await contract;
    if (!loadedContract) {
      console.error(
        `[${contractName}][${chainId}] Missing contract or contract not (yet) loaded`
      );
    }
    return loadedContract;
  };

  useEffect(() => {
    if (!addresses[chainId][contractName]) {
      console.warn(
        `[${contractName}] no address found for chainId: ${chainId}`
      );
      return;
    }

    if (walletContext.activeWallet.chainId === chainId) {
      setContract(walletContext.activeWallet.contracts.get(contractName));
      setWithSigner(true);
      return;
    }

    setContract(
      loadContract(
        loadDefaultProvider(walletContext, chainId),
        addresses[chainId][contractName],
        abis[contractName]
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chainId,
    walletContext.activeWallet.chainId,
    walletContext.activeWallet.address,
  ]);

  useEffect(() => {
    setIsLoadedOnChain(null);
    getLoadedContract().then((loadedContract) => {
      loadedContract.provider
        .getNetwork()
        .then(({ chainId: loadedChainId }) => {
          if (parseInt(chainId.toString()) === loadedChainId) {
            setIsLoadedOnChain(chainId);
          }
        });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  return { getLoadedContract, withSigner, isLoadedOnChain };
};

export default useLoadFSContract;
