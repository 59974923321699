import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Row } from "../index";
import { getNFTInfoById } from "../../utils";
import { backArrow } from "../../assets";
import Column from "../Column";
import { useDetectResolutionType } from "../../hooks";
import { StoreCardDetails } from "../../containers/Store/StoreCardDetails";

const DotsCarrousel: React.FC<any> = ({
  cards,
  activeCard,
  getCardColor,
  sortCards,
}) => {
  const [index, setIndex] = useState(1);
  const [filteredCards, setFilteredCards] = useState([]);
  const resolutionType = useDetectResolutionType();

  const updateIndex = (direction: "sub" | "add") => {
    if (direction === "sub") {
      if (index === 1) {
        setIndex(cards.length);
        sortCards(cards.length);
      } else {
        setIndex(index - 1);
        sortCards(index - 1);
      }
    } else {
      if (index === cards.length) {
        setIndex(1);
        sortCards(1);
      } else {
        setIndex(index + 1);
        sortCards(index + 1);
      }
    }
  };

  useEffect(() => {
    if (activeCard.tier + 4 >= cards.length) {
      setFilteredCards(
        cards
          .sort((a: number, b: number) => a - b)
          .slice(cards.length - 5, cards.length)
      );
    } else {
      setFilteredCards(
        cards.sort((a: number, b: number) => a - b).slice(index - 1, index + 4)
      );
    }
  }, [activeCard, index, cards]);

  return (
    <>
      <Row
        style={{
          justifySelf: "end",
          width:
            resolutionType === "mobile" || resolutionType === "tablet"
              ? "100%"
              : "205px",
          marginTop: "auto",
          gap:
            resolutionType === "mobile" || resolutionType === "tablet"
              ? "1.2rem"
              : ".75rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledArrow
          onClick={() => updateIndex("sub")}
          direction="sub"
          aria-label="back"
        />
        {filteredCards.map((cardId: number) => {
          const isCurrentCard = getNFTInfoById(cardId) === activeCard;
          return (
            <div
              key={`key-link-${cardId}`}
              style={{
                height: isCurrentCard
                  ? resolutionType === "mobile" || resolutionType === "tablet"
                    ? "30px"
                    : "16px"
                  : resolutionType === "mobile" || resolutionType === "tablet"
                  ? "32px"
                  : "20px",
                paddingLeft: isCurrentCard
                  ? resolutionType === "mobile" || resolutionType === "tablet"
                    ? "30px"
                    : "16px"
                  : resolutionType === "mobile" || resolutionType === "tablet"
                  ? "32px"
                  : "20px",
                borderRadius: "50%",
                border: isCurrentCard && "2px solid white",
                backgroundColor: getCardColor[cardId],
                boxShadow:
                  isCurrentCard && "rgb(226, 226, 226) 0px 2px 2px 1px",
                cursor: "pointer",
              }}
              onClick={() => {
                sortCards(cardId);
                setIndex(index);
              }}
            />
          );
        })}
        <StyledArrow
          onClick={() => updateIndex("add")}
          direction="add"
          aria-label="back"
        />
      </Row>
    </>
  );
};

const CardCarrousel: React.FC<any> = ({
  cards,
  getActiveCard,
  position,
  isStore,
  cardSupply,
  activeCardId,
}) => {
  const [cardsOrder, setCardsOrder] = useState(cards.reverse());
  const activeCard = getNFTInfoById(cardsOrder[cardsOrder.length - 1]);
  const resolutionType = useDetectResolutionType();
  const getCardColor = {
    1: "#ffe075",
    2: "#fcc3d6",
    3: "#333c4a",
    4: "#765cdf",
    5: "#d9d9d9",
    6: "#BAFD3A",
    7: "#3AB8FE",
  } as any;

  const sortCards = (selectedId: number) => {
    const selectedIndex = cardsOrder.findIndex(
      (id: number) => id === selectedId
    );
    setCardsOrder([
      ...Array(cards.length - 1)
        .fill("fill")
        .map((fill, index) => {
          return cardsOrder[(selectedIndex + index + 1) % cards.length];
        }),
      cardsOrder[selectedIndex],
    ]);

    getActiveCard(selectedId);
  };

  useEffect(() => {
    setCardsOrder(cards.reverse());
  }, [cards]);

  return (
    <>
      <Column
        style={{
          position: position ? position : "relative",
          height:
            resolutionType === "mobile" || resolutionType === "tablet"
              ? "365px"
              : "350px",
          alignItems:
            resolutionType === "mobile" || resolutionType === "tablet"
              ? "center"
              : "end",
          minWidth:
            resolutionType === "mobile" || resolutionType === "tablet"
              ? ""
              : `${180 + 20 * cards.length}px`,
        }}
      >
        <Row>
          {cardsOrder.map((cardId: number, index: number) => {
            return (
              <img
                key={`key-img-${cardId}`}
                style={{
                  position:
                    resolutionType === "mobile" || resolutionType === "tablet"
                      ? "initial"
                      : "absolute",
                  height:
                    resolutionType === "mobile" || resolutionType === "tablet"
                      ? "309px"
                      : 315 - (40 * (cards.length - 1) - index * 40) + "px",
                  left: index * 20,
                  top: (cardsOrder.length - 1 - index) * 20,
                  opacity: 1 - 0.1 * (cardsOrder.length - 1 - index),
                  cursor: "pointer",
                  display:
                    getNFTInfoById(cardId) === activeCard
                      ? "block"
                      : resolutionType === "mobile" ||
                        resolutionType === "tablet"
                      ? "none"
                      : "block",
                }}
                alt={`tier id ${cardId}`}
                src={getNFTInfoById(cardId).img}
              />
            );
          })}
          {isStore && (
            <StoreCardDetails
              activeCard={activeCard}
              cardSupply={cardSupply}
              activeCardId={activeCardId}
            />
          )}
        </Row>
        {cards.length > 1 && (
          <DotsCarrousel
            {...{
              cards,
              activeCard,
              getCardColor,
              sortCards,
            }}
          />
        )}
      </Column>
    </>
  );
};

const StyledArrow = styled.button<{ direction: "sub" | "add" }>`
  background-color: #eaeaea;
  background-image: url(${backArrow});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;

  & {
    transform: ${({ direction }) => direction === "add" && "scaleX(-1)"};
  }
`;

export default CardCarrousel;
