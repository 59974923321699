import { useState, useEffect } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import { Contract } from "@ethersproject/contracts";
// @ts-ignore
import { addresses, abis } from "@fs/contracts";
import {
  useTierNFTFactory,
  TIER_NFT_FACTORY_CALL_METHODS,
  TIER_NFT_FACTORY_TX_METHODS,
  useWalletProvider,
  useSendTransaction,
  useTransaction,
} from "./index";
import { loadContract, ALL_FCFS_CARDS, send } from "../utils";
import config from "../config/config";

const useTierNFTAirdrop = () => {
  const {
    walletContext,
    walletContext: {
      activeWallet: { chainId, address, provider, signer },
    },
  } = useWalletProvider();
  const {
    callTierNFTFactoryMethod,
    txTierNFTFactoryMethod,
    contractIsLoaded,
  } = useTierNFTFactory(config.defaultChainId);
  const { dispatchTx } = useTransaction();
  const [isApproved, setIsApproved] = useState(false);
  const [fcfsTierOwned, setFcfsTierOwned] = useState(false);
  const [contract, setContract] = useState<Contract>();
  const [claimState, setClaimState] = useState({
    isWhitelisted: false,
    hasWalletClaimedAirdropNFT: false,
    hasWalletClaimedAirdropTokens: false,
  });

  const tierNftAirdropContractAddress = addresses[chainId].tierNftAirdrop;

  const {
    sendTx: handleApproveForAll,
    isPending: isSetApprovalForAllPending,
    isCompleted: isSetApprovalForAllCompleted,
  } = useSendTransaction(() =>
    txTierNFTFactoryMethod(TIER_NFT_FACTORY_TX_METHODS.SET_APPROVAL_FOR_ALL, [
      tierNftAirdropContractAddress,
    ])
  );

  const {
    sendTx: claimNFT,
    isPending: isClaimingNFT,
    isCompleted: isClaimingNFTCompleted,
  } = useSendTransaction(() =>
    send(
      walletContext.activeWallet.provider,
      () => contract.ClaimAirdropNFT(),
      dispatchTx
    )
  );

  const {
    sendTx: claimAirdropTokens,
    isPending: isClaimingAirdrop,
    isCompleted: isClaimingAirdropCompleted,
  } = useSendTransaction(() =>
    send(
      walletContext.activeWallet.provider,
      () => contract.ClaimAirdropTokens(),
      dispatchTx
    )
  );

  useEffect(() => {
    if (!signer) return;
    (async () => {
      setContract(
        await loadContract(
          provider,
          tierNftAirdropContractAddress,
          abis.tierNftAirdrop
        )
      );
    })();
  }, [provider, signer, walletContext, tierNftAirdropContractAddress]);

  useEffect(() => {
    if (!contractIsLoaded) return;
    callTierNFTFactoryMethod(TIER_NFT_FACTORY_CALL_METHODS.BALANCE_OF_BATCH, [
      ALL_FCFS_CARDS,
      address,
    ]).then((balance: any[]) => {
      setFcfsTierOwned(balance.length && balance[0].gt(BigNumber.from(0)));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    address,
    contractIsLoaded,
    isClaimingNFTCompleted,
    isClaimingAirdropCompleted,
  ]);

  useEffect(() => {
    if (!contractIsLoaded) return;
    callTierNFTFactoryMethod(
      TIER_NFT_FACTORY_CALL_METHODS.IS_APPROVED_FOR_ALL,
      [address, tierNftAirdropContractAddress]
    ).then((isApproved) => {
      setIsApproved(isApproved);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    address,
    tierNftAirdropContractAddress,
    contractIsLoaded,
    isSetApprovalForAllCompleted,
  ]);

  useEffect(() => {
    if (!contract || !address) return;

    (async () => {
      const hasWalletClaimedAirdropTokens = await contract.hasWalletClaimedAirdropTokens(
        address
      );
      const hasWalletClaimedAirdropNFT = await contract.hasWalletClaimedAirdropNFT(
        address
      );
      const isWhitelisted = await contract.isWhitelisted(address);

      setClaimState({
        ...claimState,
        isWhitelisted,
        hasWalletClaimedAirdropNFT,
        hasWalletClaimedAirdropTokens,
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, contract, isClaimingAirdropCompleted, isClaimingNFTCompleted]);

  // useEffect(() => {
  //   if (
  //     !contract &&
  //     !claimState.isClaimingNFT &&
  //     !claimState.isClaimingAirdropTokens
  //   )
  //     return;
  // }, [contract]);

  // const claimNFT = async () => {
  //   try {
  //     setClaimState({
  //       ...claimState,
  //       isClaimingNFT: true,
  //     });
  //     await contract.ClaimAirdropNFT();
  //   } catch (error) {
  //     console.log(error);
  //   }
  //
  //   setClaimState({
  //     ...claimState,
  //     isClaimingNFT: false,
  //   });
  // };

  // const claimAirdropTokens = async () => {
  //   try {
  //     setClaimState({
  //       ...claimState,
  //       isClaimingAirdropTokens: true,
  //     });
  //     await contract.ClaimAirdropTokens();
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setClaimState({
  //     ...claimState,
  //     isClaimingAirdropTokens: false,
  //   });
  // };

  return {
    handleApproveForAll,
    isSetApprovalForAllPending,
    isApproved,
    claimState,
    claimNFT,
    claimAirdropTokens,
    fcfsTierOwned,
    isClaimingAirdrop,
    isClaimingNFT,
  };
};

export default useTierNFTAirdrop;
