import { Web3Provider } from "@ethersproject/providers";
import { isAddress } from "@ethersproject/address";
import { Contract } from "@ethersproject/contracts";
import { Connection } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

// @ts-ignore
import { addresses, abis } from "@fs/contracts";
import config from "../config/config";
import ftmLogo from "../assets/logo/fantom@2x.png";
import bscLogo from "../assets/logo/bsc@2x.png";
import polygonLogo from "../assets/logo/polygon@2x.png";
import ethLogo from "../assets/logo/ethereum@2x.png";
import arbitrumLogo from "../assets/logo/arbitrum@2x.png";
import optimismLogo from "../assets/logo/optimism@2x.png";
import avalancheLogo from "../assets/logo/avalanche@2x.png";

export const isValidAddress = (address: string): boolean => {
  return isAddress(address);
};

export const isSameAddress = (address1: string, address2: string): boolean => {
  if (!address1 || !address2) {
    return false;
  }
  return address1.toLowerCase() === address2.toLowerCase();
};

export const formatAddress = (address: string) => {
  return `${address.substr(0, 5)}...${address.substr(-3, 3)}`;
};

export const createWeb3Provider = (provider: any) => {
  return new Web3Provider(provider, "any");
};

export const createWalletContext = async (
  provider: Web3Provider,
  chainId: number
) => {
  if (!provider) {
    console.error("provider missing");
    return;
  }

  const accounts = await provider.listAccounts();
  const contracts = await loadContracts(provider, chainId);

  let validSigner = null;
  try {
    validSigner = await provider.getSigner();
  } catch (err) {
    console.info(`Failed to load Signer`);
  }

  return {
    contracts,
    chainId,
    address: accounts[0],
    provider,
    signer: validSigner,
  };
};

export const createSolanaWalletConext = async (
  provider: any,
  network: WalletAdapterNetwork,
  endpoint: string,
  walletContext: any,
  defaultChianId: number
) => {
  if (!provider) {
    console.error("provider missing");
    return;
  }

  const address = provider.publicKey.toString();
  const connection = new Connection(endpoint);

  const defaultProvider = loadDefaultProvider(walletContext, defaultChianId);
  const contracts = await loadContracts(defaultProvider, defaultChianId);

  return {
    contracts,
    address,
    network,
    connection,
    wallet: provider,
  };
};

export const createAptosWalletContext = async (
  provider: any,
  chainId: number,
  client: any,
  walletContext: any,
  defaultChainId: number
) => {
  if (!provider) {
    console.error("provider missing");
    return;
  }
  const address = provider.address;

  const defaultProvider = loadDefaultProvider(walletContext, defaultChainId);
  const contracts = await loadContracts(defaultProvider, defaultChainId);

  return {
    contracts,
    chainId,
    address,
    provider,
    client,
  };
};

export const loadDefaultProvider = (
  walletContext: any,
  chainId: number | string
) => {
  const comparableChainId = parseInt(chainId.toString());

  if (comparableChainId === 250 || comparableChainId === 4002) {
    return walletContext.RPCProvider.fantom.provider;
  }
  if (comparableChainId === 137 || comparableChainId === 80001) {
    return walletContext.RPCProvider.polygon.provider;
  }
  if (comparableChainId === 56 || comparableChainId === 97) {
    return walletContext.RPCProvider.bsc.provider;
  }
  if (comparableChainId === 1 || comparableChainId === 5) {
    return walletContext.RPCProvider.eth.provider;
  }
  if (comparableChainId === 42161 || comparableChainId === 421611) {
    return walletContext.RPCProvider.arbitrum.provider;
  }
  if (comparableChainId === 10 || comparableChainId === 420) {
    return walletContext.RPCProvider.optimism.provider;
  }
  if (comparableChainId === 43114 || comparableChainId === 43113) {
    return walletContext.RPCProvider.avalanche.provider;
  }
  if (comparableChainId === 64240 || comparableChainId === 64240) {
    return walletContext.RPCProvider.sonic.provider;
  }
  console.warn("[loadProvider] no RPCProvider found for network");
};

export const loadProvider = async (
  walletContext: any,
  chainId: number | string
) => {
  if (walletContext.activeWallet.provider) {
    if (
      parseInt(walletContext.activeWallet.chainId) ===
      parseInt(chainId.toString())
    ) {
      return walletContext.activeWallet.provider;
    }
  }

  return loadDefaultProvider(walletContext, chainId);
};

export const loadContract = async (
  provider: any,
  contractAddress: string,
  abi: any
) => {
  if (!provider) {
    console.error("Missing provider: cant load contract");
  }

  let validSigner =
    provider.connection.url === "metamask" && provider.getSigner();
  return new Contract(
    contractAddress,
    abi,
    validSigner ? validSigner : provider
  );
};

export const loadContracts = async (provider: any, chainId: number) => {
  if (!provider || !config.supportedChains.includes(chainId)) {
    console.info("[loadContracts] no provider or unsupported chain");
    return;
  }

  let validSigner =
    provider.connection.url === "metamask" && provider.getSigner();

  const initialContracts = [
    addresses[chainId].tokens.FS && [
      "fs",
      new Contract(
        addresses[chainId].tokens.FS,
        abis.erc20.abi,
        validSigner ? validSigner : provider
      ),
    ],
    addresses[chainId].tierNFTFactory && [
      "tierNFTFactory",
      new Contract(
        addresses[chainId].tierNFTFactory,
        abis.tierNFTFactory,
        validSigner ? validSigner : provider
      ),
    ],
    process.env.REACT_APP_FS_STORE !== "off" &&
      addresses[chainId].tierNFTStore && [
        "tierNFTStore",
        new Contract(
          addresses[chainId].tierNFTStore,
          abis.tierNFTStore,
          validSigner ? validSigner : provider
        ),
      ],
    process.env.REACT_APP_FS_STAKE !== "off" &&
      addresses[chainId].tierStake && [
        "tierStake",
        new Contract(
          addresses[chainId].tierStake,
          abis.tierStake,
          validSigner ? validSigner : provider
        ),
      ],
    process.env.REACT_APP_FS_TIER_STAKE_V2 !== "off" &&
      addresses[chainId].tierStakeV2 && [
        "tierStakeV2",
        new Contract(
          addresses[chainId].tierStakeV2,
          abis.tierStakeV2,
          validSigner ? validSigner : provider
        ),
      ],
    process.env.REACT_APP_FS_FAUCET !== "off" &&
      addresses[chainId].fsFaucet && [
        "fsFaucet",
        new Contract(
          addresses[chainId].fsFaucet,
          abis.fsFaucet,
          validSigner ? validSigner : provider
        ),
      ],
  ].filter((contract) => !!contract);

  return new Map(initialContracts as any);
};

export const loadERC20Contract = async (
  contractAddress: string,
  provider: any
) => {
  let validSigner =
    provider.connection.url === "metamask" && provider.getSigner();

  return new Contract(
    contractAddress,
    abis.erc20.abi,
    validSigner ? validSigner : provider
  );
};

export const chainToNetworkNameMap = {
  250: "Fantom",
  4002: "Fantom Testnet",
  137: "Polygon",
  80001: "Polygon Testnet",
  56: "BNB",
  97: "BNB Testnet",
  1: "Ethereum",
  5: "Goerli Testnet",
  42161: "Arbitrum",
  421611: "Arbitrum Testnet",
  10: "Optimism",
  420: "Optimism Testnet",
  43114: "Avalanche",
  43113: "Avalanche Testnet",
  64240: "Sonic Testnet",
} as any;

export const chainToNetworkImageMap = {
  250: ftmLogo,
  4002: ftmLogo,
  137: polygonLogo,
  80001: polygonLogo,
  56: bscLogo,
  97: bscLogo,
  1: ethLogo,
  5: ethLogo,
  42161: arbitrumLogo,
  421611: arbitrumLogo,
  10: optimismLogo,
  420: optimismLogo,
  43114: avalancheLogo,
  43113: avalancheLogo,
  64240: ftmLogo,
} as any;

export const chainToExplorerDomainMap = {
  250: "ftmscan.com",
  4002: "testnet.ftmscan.com",
  137: "polygonscan.com",
  80001: "mumbai.polygonscan.com",
  56: "bscscan.com",
  97: "testnet.bscscan.com",
  1: "etherscan.io",
  5: "goerli.etherscan.io",
  42161: "arbiscan.io",
  421611: "rinkeby.arbiscan.io",
  10: "optimistic.etherscan.io",
  420: "kovan-optimistic.etherscan.io",
  43114: "snowtrace.io",
  43113: "testnet.snowtrace.io",
  64240: "public-sonic.fantom.network",
} as any;

export const isTestnet = (chainId: number) => {
  return (
    chainId === 4002 ||
    chainId === 80001 ||
    chainId === 97 ||
    chainId === 5 ||
    chainId === 421611 ||
    chainId === 420 ||
    chainId === 43113 ||
    chainId === 64240
  );
};

export const chainToNativeToken = {
  250: {
    name: "Fantom",
    code: "fantom",
    symbol: "FTM", // 2-6 characters long
    decimals: 18,
    network: "Fantom Opera",
  },
  4002: {
    name: "Fantom",
    code: "fantom",
    symbol: "FTM", // 2-6 characters long
    decimals: 18,
    network: "Fantom Testnet",
  },
  137: {
    name: "Matic",
    code: "polygon",
    symbol: "MATIC", // 2-6 characters long
    decimals: 18,
    network: "Polygon",
  },
  80001: {
    name: "Matic",
    code: "polygon",
    symbol: "MATIC", // 2-6 characters long
    decimals: 18,
    network: "Polygon Testnet",
  },
  56: {
    name: "BNB",
    code: "binance-coin",
    symbol: "BNB", // 2-6 characters long
    decimals: 18,
    network: "Binance Smart Chain",
  },
  97: {
    name: "tBNB",
    code: "binance-coin",
    symbol: "tBNB", // 2-6 characters long
    decimals: 18,
    network: "BSC Testnet",
  },
  1: {
    name: "Ethereum",
    code: "ethereum",
    symbol: "ETH", // 2-6 characters long
    decimals: 18,
    network: "Ethereum Mainnet",
  },
  5: {
    name: "Ethereum",
    code: "ethereum",
    symbol: "ETH", // 2-6 characters long
    decimals: 18,
    network: "Goerli Testnet",
  },
  42161: {
    name: "Ethereum",
    code: "ethereum",
    symbol: "ETH", // 2-6 characters long
    decimals: 18,
    network: "Arbitrum",
  },
  421611: {
    name: "Ethereum",
    code: "ethereum",
    symbol: "ETH", // 2-6 characters long
    decimals: 18,
    network: "Arbitrum Testnet",
  },
  10: {
    name: "Ethereum",
    code: "ethereum",
    symbol: "ETH", // 2-6 characters long
    decimals: 18,
    network: "Optimism",
  },
  420: {
    name: "Ethereum",
    code: "ethereum",
    symbol: "ETH", // 2-6 characters long
    decimals: 18,
    network: "Optimism Testnet",
  },
  43114: {
    name: "Avalanche",
    code: "avalanche",
    symbol: "AVAX", // 2-6 characters long
    decimals: 18,
    network: "Avalanche",
  },
  43113: {
    name: "Avalanche",
    code: "avalanche",
    symbol: "AVAX", // 2-6 characters long
    decimals: 18,
    network: "Avalanche Testnet",
  },
  64240: {
    name: "Sonic",
    code: "sonic",
    symbol: "FTM",
    decimals: 18,
    network: "Sonic Testnet",
  },
} as any;

export const switchToChain = async (provider: any, chainId: number) => {
  const getNetworkDetails = () => {
    if (chainId === 250) {
      return {
        chainId: "0xfa", // A 0x-prefixed hexadecimal string
        chainName: "Fantom Opera",
        nativeCurrency: chainToNativeToken[250],
        rpcUrls: ["https://rpc.ftm.tools/"],
        blockExplorerUrls: ["https://ftmscan.com/"],
      };
    }
    if (chainId === 4002) {
      return {
        chainId: "0xfa2", // A 0x-prefixed hexadecimal string
        chainName: "Fantom Testnet",
        nativeCurrency: chainToNativeToken[4002],
        rpcUrls: ["https://xapi.testnet.fantom.network/lachesis"],
        blockExplorerUrls: ["https://testnet.ftmscan.com/"],
      };
    }
    if (chainId === 137) {
      return {
        chainId: "0x89", // A 0x-prefixed hexadecimal string
        chainName: "Polygon",
        nativeCurrency: chainToNativeToken[137],
        rpcUrls: ["https://rpc-mainnet.maticvigil.com"],
        blockExplorerUrls: ["https://polygonscan.com/"],
      };
    }
    if (chainId === 80001) {
      return {
        chainId: "0x13881", // A 0x-prefixed hexadecimal string
        chainName: "Polygon Testnet",
        nativeCurrency: chainToNativeToken[80001],
        rpcUrls: ["https://rpc-mumbai.matic.today"],
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
      };
    }
    if (chainId === 56) {
      return {
        chainId: "0x38", // A 0x-prefixed hexadecimal string
        chainName: "Binance Smart Chain",
        nativeCurrency: chainToNativeToken[56],
        rpcUrls: ["https://bsc-dataseed.binance.org/"],
        blockExplorerUrls: ["https://bscscan.com/"],
      };
    }
    if (chainId === 97) {
      return {
        chainId: "0x61", // A 0x-prefixed hexadecimal string
        chainName: "Binance Smart Chain Testnet",
        nativeCurrency: chainToNativeToken[97],
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
        blockExplorerUrls: ["https://testnet.bscscan.com/"],
      };
    }
    if (chainId === 1) {
      return {
        chainId: "0x1", // A 0x-prefixed hexadecimal string
        chainName: "Ethereum Mainnet",
        nativeCurrency: chainToNativeToken[1],
        rpcUrls: [
          "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        ],
        blockExplorerUrls: ["https://etherscan.io/"],
      };
    }
    if (chainId === 5) {
      return {
        chainId: "0x5", // A 0x-prefixed hexadecimal string
        chainName: "Goerli Testnet",
        nativeCurrency: chainToNativeToken[5],
        rpcUrls: ["https://rpc.ankr.com/eth_goerli"],
        blockExplorerUrls: ["https://goerli.etherscan.io/"],
      };
    }
    if (chainId === 42161) {
      return {
        chainId: "0xa4b1", // A 0x-prefixed hexadecimal string
        chainName: "Arbitrum",
        nativeCurrency: chainToNativeToken[42161],
        rpcUrls: ["https://rpc.ankr.com/arbitrum"],
        blockExplorerUrls: ["https://arbiscan.io/"],
      };
    }
    if (chainId === 421611) {
      return {
        chainId: "0x66eeb", // A 0x-prefixed hexadecimal string
        chainName: "Arbitrum Testnet",
        nativeCurrency: chainToNativeToken[421611],
        rpcUrls: ["https://rinkeby.arbitrum.io/rpc"],
        blockExplorerUrls: ["https://testnet.arbiscan.io/"],
      };
    }
    if (chainId === 10) {
      return {
        chainId: "0xa", // A 0x-prefixed hexadecimal string
        chainName: "Optimism",
        nativeCurrency: chainToNativeToken[10],
        rpcUrls: ["https://mainnet.optimism.io"],
        blockExplorerUrls: ["https://optimistic.etherscan.io/"],
      };
    }
    if (chainId === 420) {
      return {
        chainId: "0x1a4", // A 0x-prefixed hexadecimal string
        chainName: "Optimism Testnet",
        nativeCurrency: chainToNativeToken[420],
        rpcUrls: ["https://goerli.optimism.io"],
        blockExplorerUrls: ["https://goerli-optimism.etherscan.io"],
      };
    }
    if (chainId === 43114) {
      return {
        chainId: "0xa86a", // A 0x-prefixed hexadecimal string
        chainName: "Avalanche",
        nativeCurrency: chainToNativeToken[43114],
        rpcUrls: ["https://rpc.ankr.com/avalanche"],
        blockExplorerUrls: ["https://snowtrace.io/"],
      };
    }
    if (chainId === 43113) {
      return {
        chainId: "0xa869", // A 0x-prefixed hexadecimal string
        chainName: "Avalanche Testnet",
        nativeCurrency: chainToNativeToken[43113],
        rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
        blockExplorerUrls: ["https://testnet.snowtrace.io"],
      };
    }
    if (chainId === 64240) {
      return {
        chainId: "0xfaf0", // A 0x-prefixed hexadecimal string
        chainName: "Sonic Testnet",
        nativeCurrency: chainToNativeToken[64240],
        rpcUrls: ["https://rpcapi.sonic.fantom.network/"],
        blockExplorerUrls: ["https://public-sonic.fantom.network"],
      };
    }
  };

  const networkDetails = getNetworkDetails();
  if (!networkDetails || !provider) {
    return;
  }

  try {
    await provider.provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: networkDetails.chainId }],
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [networkDetails],
        });
      } catch (addError) {
        console.error(addError);
        // handle "add" error
      }
    }
    console.error(switchError);
    // handle other "switch" errors
  }
};
