import { Contract } from "@ethersproject/contracts";
import { useWalletProvider, useTransaction } from "./index";
import { send } from "../utils";

const CONTRACT_NAME = "fsFaucet";
export enum FS_FAUCET_TX_METHODS {
  FAUCET_TOKENS = "faucetTokens",
}

const fsFaucetContractTx: {
  [key in FS_FAUCET_TX_METHODS]: any;
} = {
  [FS_FAUCET_TX_METHODS.FAUCET_TOKENS]: async (
    contract: Contract,
    nftId: number
  ) => {
    return contract.faucetTokens();
  },
};

const useFsFaucet = () => {
  const { walletContext } = useWalletProvider();
  const { dispatchTx } = useTransaction();

  const contractIsLoaded = (contracts: Map<string, any>) => {
    if (!contracts || !contracts.has(CONTRACT_NAME)) {
      console.error(
        `[txFsFaucetMethod] Missing contracts or contract not (yet) loaded`
      );
      return false;
    }
    return true;
  };

  const txFsFaucetMethod = async (method: FS_FAUCET_TX_METHODS, args: any) => {
    if (!contractIsLoaded(walletContext.activeWallet.contracts)) {
      return;
    }

    const sendTx = (callback: any) => {
      try {
        return send(walletContext.activeWallet.provider, callback, dispatchTx);
      } catch (err) {
        throw err;
      }
    };

    if (!fsFaucetContractTx[method]) {
      console.error(`[txFsFaucetMethod] method: ${method} not found`);
    }

    return sendTx(() =>
      fsFaucetContractTx[method](
        walletContext.activeWallet.contracts.get(CONTRACT_NAME),
        ...args
      )
    );
  };

  return {
    txFsFaucetMethod: async (method: FS_FAUCET_TX_METHODS, args: any[]) =>
      txFsFaucetMethod(method, args),
  };
};

export default useFsFaucet;
