export const serializeProject = (project: any) => {
  return {
    capIndividual: project?.cap_individual_dollars,
    capTotal: project?.cap_total_dollars,
    chain: project.chain,
    company: project?.company,
    description: project?.description,
    startDateFunding: project?.start_date_funding
      ? new Date(project.start_date_funding).getTime()
      : 0,
    endDateFunding: project?.end_date_funding
      ? new Date(project.end_date_funding).getTime()
      : 0,
    startDateWhitelist: project?.start_date_whitelist
      ? new Date(project.start_date_whitelist).getTime()
      : 0,
    endDateWhitelist: project?.end_date_whitelist
      ? new Date(project.end_date_whitelist).getTime()
      : 0,
    headerImage: project?.header_image_url,
    logoImage: project?.logo_image_url,
    mainImage: project?.main_image_url,
    id: project.id,
    name: project.name,
    idoTokenSymbol: project?.ido_token_symbol,
    payTokenSymbol: project?.pay_token_symbol,
    location: project?.location,
    payTokenContractAddress: project?.paytoken_contract_address,
    idoTokenContractAddress: project?.token_contract_address,
    saleContractAddress: project?.sale_contract_address,
    lotteryContractAddress: project?.lottery_contract_address,
    ratioIdoPerPay: project?.ratio_idotoken_per_paytoken,
    ratioPayPerIdo: project?.ratio_paytoken_per_idotoken,
    tags: project?.tags || [],
    whitelistedCount: project.whitelisted_count,
    preWhitelist: project.pre_whitelist,
    saleVersion: project.sale_version,
    onlyStaked: project.only_staked,
    withWhitelist: project.with_whitelist,
    withLottery: project.with_lottery,
    withKYC: project.with_kyc,
    isHidden: project.is_hidden,
    totalRaised: project.total_raised,
  };
};
