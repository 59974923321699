import { useCallback, useEffect, useState } from "react";
import { Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import appConfig from "../config/config";
import { useWalletProvider } from "./index";
import {
  createWalletContext,
  createWeb3Provider,
  isSameAddress,
  createSolanaWalletConext,
  createAptosWalletContext,
} from "../utils";
import { phantomLogo, fantomStarterLogo } from "../assets";
import aptosLogo from "../assets/logo/aptos@2x.png";
import { WalletProvider as SolanaWalletProvider } from "@solana/wallet-adapter-react";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import {
  SolanaMobileWalletAdapter,
  createDefaultAddressSelector,
  createDefaultAuthorizationResultCache,
} from "@solana-mobile/wallet-adapter-mobile";
import useDetectResolutionType from "./useDetectResolutionType";
import { AptosClient } from "aptos";
import { coin98Logo } from "../assets";

function useWeb3Modal(config = {}) {
  const { walletContext, dispatchWalletContext } = useWalletProvider();
  const [autoLoaded, setAutoLoaded] = useState(false);
  const { autoLoad = true }: any = config;
  const resolutionType = useDetectResolutionType();

  const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions: {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: "7a9c4ff3188d481f9143904079638424",
          rpc: appConfig.rpc,
        },
      },
      "custom-phantom": process.env.REACT_APP_FS_SOLANA !== "off" && {
        display: {
          logo: phantomLogo,
          name: "Phantom",
          description: "Connect to your Phantom Wallet",
        },
        package: SolanaWalletProvider,
        connector: async () => {
          // let wallet;
          // if (resolutionType === "mobile" || resolutionType === "tablet") {
          //   wallet = new SolanaMobileWalletAdapter({
          //     addressSelector: createDefaultAddressSelector(),
          //     appIdentity: {
          //       name: "FantomStarter",
          //       uri: window.location.origin,
          //       icon: fantomStarterLogo,
          //     },
          //     authorizationResultCache: createDefaultAuthorizationResultCache(),
          //     cluster: appConfig.solNetwork,
          //   });
          // } else {
          //   wallet = new PhantomWalletAdapter();
          // }
          const wallet = new PhantomWalletAdapter();
          await wallet.connect();
          return wallet;
        },
      },
      "custom-martian": process.env.REACT_APP_FS_APTOS !== "off" && {
        display: {
          logo: aptosLogo,
          name: "Martian",
          description: "Connect to your Martain Wallet",
        },
        package: window,
        connector: async () => {
          if ("martian" in window) {
            // @ts-ignore
            const wallet = window.martian;
            try {
              await wallet.connect();
              return wallet;
            } catch (error) {
              console.error(error);
            }
          } else {
            window.open("https://www.martianwallet.xyz/", "_blank");
          }
        },
      },
      "custom-coin98": {
        display: {
          logo: coin98Logo,
          name: "Coin98",
          description: "Connect to your Coin98 Wallet",
        },
        // @ts-ignore
        package: window.coin98 ? window.coin98 : null,
        connector: async () => {
          try {
            // @ts-ignore
            await window.coin98.provider.request({
              method: "eth_accounts",
            });
            // @ts-ignore
            return window.coin98.provider;
          } catch (error) {
            console.error("err connect", error);
          }
        },
      },
    },
  });

  const loadAndDispatchContext = async (web3Provider: Web3Provider) => {
    if (!web3Provider) {
      return;
    }

    const { chainId } = await web3Provider.getNetwork();

    const walletProvider = await createWalletContext(web3Provider, chainId);
    return dispatchWalletContext({
      type: "setActiveWallet",
      data: {
        ...walletProvider,
        providerType: "metamask",
      },
    });
  };

  const loadAndDispatchContextForSolana = async (
    provider: PhantomWalletAdapter
  ) => {
    if (!provider) {
      return;
    }

    const walletProvider = await createSolanaWalletConext(
      provider,
      appConfig.solNetwork,
      appConfig.solEndpoint,
      walletContext,
      appConfig.defaultChainId
    );
    return dispatchWalletContext({
      type: "setActiveWallet",
      data: {
        ...walletProvider,
        providerType: "Phantom",
      },
    });
  };

  const loadAndDispatchContextForAptos = async (provider: any) => {
    if (!provider) {
      return;
    }
    const chainIdObj = await provider.getChainId();
    const chainId = chainIdObj.chainId;
    const client = new AptosClient(appConfig.aptosNodeUrl);
    const walletProvider = await createAptosWalletContext(
      provider,
      chainId,
      client,
      walletContext,
      appConfig.defaultChainId
    );
    return dispatchWalletContext({
      type: "setActiveWallet",
      data: {
        ...walletProvider,
        providerType: "Martian",
      },
    });
  };

  const resetApp = async () => {
    walletContext.activeWallet.provider &&
      walletContext.activeWallet.provider.close &&
      (await walletContext.activeWallet.provider.close());

    await web3Modal.clearCachedProvider();
    await dispatchWalletContext({
      type: "reset",
    });
    window.location.reload();
  };

  const subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }

    provider.removeAllListeners();

    provider.on("chainChanged", async (chainId: string) => {
      console.info("[PROVIDER] chain changed to ", parseInt(chainId));
      if (appConfig.supportedChains.includes(parseInt(chainId))) {
        const web3Provider = createWeb3Provider(provider);
        const walletProvider = await createWalletContext(
          web3Provider,
          parseInt(chainId)
        );
        return dispatchWalletContext({
          type: "chainChanged",
          data: {
            ...walletProvider,
            providerType: "metamask",
          },
        });
      }

      return dispatchWalletContext({
        type: "web3ProviderChainChanged",
        data: {
          chainChanged: parseInt(chainId),
        },
      });
    });

    provider.on("accountsChanged", async (accountChanged: string) => {
      console.info("[PROVIDER] account changed to ", accountChanged[0]);

      const web3Provider = createWeb3Provider(provider);
      const { chainId } = await web3Provider.getNetwork();
      const walletProvider = await createWalletContext(web3Provider, chainId);

      return dispatchWalletContext({
        type: "setActiveWallet",
        data: {
          ...walletProvider,
          providerType: "metamask",
        },
      });
      // dispatchWalletContext({
      //   type: "web3ProviderAccountChanged",
      //   data: {
      //     accountSelected: accountChanged[0],
      //     // walletProvider: walletProvider,
      //   },
      // });
    });
  };

  const subscribeAptosProvider = async (provider: any) => {
    if (!provider) {
      return;
    }

    provider.onNetworkChange(async (name: string) => {
      const chainIdObj = await provider.getChainId();
      const chainId = chainIdObj.chainId;
      console.info(
        "[PROVIDER] chain changed to ",
        parseInt(chainId),
        " ",
        name
      );
      if (appConfig.aptosChainId === parseInt(chainId)) {
        const client = new AptosClient(appConfig.aptosNodeUrl);
        const walletProvider = await createAptosWalletContext(
          provider,
          chainId,
          client,
          walletContext,
          appConfig.defaultChainId
        );
        return dispatchWalletContext({
          type: "chainChanged",
          data: {
            ...walletProvider,
            providerType: "Martian",
          },
        });
      }
      return dispatchWalletContext({
        type: "web3ProviderChainChanged",
        data: {
          chainChanged: parseInt(chainId),
        },
      });
    });

    provider.onAccountChange(async (address: string) => {
      console.info("[PROVIDER] account changed to ", address);

      const chainIdObj = await provider.getChainId();
      const chainId = chainIdObj.chainId;
      console.log(appConfig.aptosNodeUrl);
      const client = new AptosClient(appConfig.aptosNodeUrl);
      const walletProvider = await createAptosWalletContext(
        provider,
        chainId,
        client,
        walletContext,
        appConfig.defaultChainId
      );

      return dispatchWalletContext({
        type: "setActiveWallet",
        data: {
          ...walletProvider,
          providerType: "Martian",
        },
      });
    });
  };
  // Open wallet selection modal.
  const loadWeb3Modal = useCallback(
    async (cached = false) => {
      if (!cached) {
        localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
      }

      const connectProvider = await web3Modal.connect();

      if (!connectProvider) {
        web3Modal.clearCachedProvider();
        return;
      }

      if (connectProvider.name === "Phantom") {
        if (
          walletContext.activeWallet.address &&
          isSameAddress(
            connectProvider.publicKey.toString(),
            walletContext.activeWallet.address
          )
        ) {
          console.info("Wallet already connected");
          return;
        }
        // subscribe to provider events
        loadAndDispatchContextForSolana(connectProvider).then(() => {
          console.log("Contracts LOADED");
          // Remove the modal (they are stacking otherwise)
          const element = document.getElementById("WEB3_CONNECT_MODAL_ID");
          element.remove();
        });
      } else if (connectProvider.constructor.name === "MartianWeb3") {
        if (
          walletContext.activeWallet.address &&
          isSameAddress(
            walletContext.activeWallet.address,
            connectProvider.address
          )
        ) {
          console.info("Wallet already connected");
          return;
        }

        await subscribeAptosProvider(connectProvider);

        loadAndDispatchContextForAptos(connectProvider).then(() => {
          console.log("Contracts LOADED");
          // Remove the modal (they are stacking otherwise)
          const element = document.getElementById("WEB3_CONNECT_MODAL_ID");
          element.remove();
        });
      } else {
        if (
          walletContext.activeWallet.address &&
          isSameAddress(
            walletContext.activeWallet.address,
            connectProvider.selectedAddress
          )
        ) {
          console.info("Wallet already connected");
          return;
        }

        await subscribeProvider(connectProvider);
        const web3Provider = createWeb3Provider(connectProvider);

        loadAndDispatchContext(web3Provider).then(() => {
          console.log("Contracts LOADED");

          // Remove the modal (they are stacking otherwise)
          const element = document.getElementById("WEB3_CONNECT_MODAL_ID");
          element.remove();
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3Modal]
  );

  const logoutOfWeb3Modal = useCallback(
    async function () {
      await resetApp();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3Modal]
  );

  // If autoLoad is enabled and the the wallet had been loaded before, load it automatically now.
  useEffect(() => {
    if (autoLoad && !autoLoaded && web3Modal.cachedProvider) {
      loadWeb3Modal(true);
      setAutoLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLoad, autoLoaded, web3Modal.cachedProvider]);

  return [loadWeb3Modal, logoutOfWeb3Modal];
}

export default useWeb3Modal;
