import React from "react";
import styled from "styled-components";
import { tick } from "../../assets";
import { Row, Typo2 } from "../../components";

export interface ICheckbox {
  name: string;
  onChange: any;
}

export const Checkbox: React.FC<ICheckbox> = ({ name, onChange }) => {
  return <StyledCheckbox name={name} id={name} onClick={() => onChange()} />;
};

export interface ILabel extends ICheckbox {
  text: string;
}

export const Label: React.FC<ILabel> = ({ text, name }) => {
  return (
    <StyledLabel htmlFor={name}>
      <Typo2>{text}</Typo2>
    </StyledLabel>
  );
};

const LabeledCheckbox: React.FC<ILabel> = ({ onChange, text, name }) => {
  return (
    <Row align="center" gap={0.5}>
      <Checkbox {...{ name, onChange }} />
      <Label {...{ text, name, onChange }} />
    </Row>
  );
};

const StyledCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  appearance: none;
  background: #e2e2e2;
  background-position: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 2em;
  outline: none;
  width: 2em;

  &:checked {
    background-image: linear-gradient(
      135deg,
      #765cde 0,
      #df73ec 50%,
      #f8b16b 99%
    );
  }

  &::after {
    background-image: url(${tick});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 85%;
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
  }
`;

const StyledLabel = styled.label`
  cursor: pointer;
  width: 90%;
`;

export default LabeledCheckbox;
