import React, { useContext, useEffect } from "react";
import { ModalContext } from "../../context";
import {
  Button,
  Typo3,
  DropDownButton,
  InfoModal,
  Row,
  WalletSelectView,
} from "../index";
import { useModal, useWeb3Modal, useWalletProvider } from "../../hooks";
import { isSameAddress } from "../../utils";

const WalletSelector: React.FC<any> = () => {
  const modalContext = useContext(ModalContext);
  const { walletContext, dispatchWalletContext } = useWalletProvider();
  const [loadWeb3Modal] = useWeb3Modal();
  // const [onPresentWrongChainSelected, onDismissWrongChainSelected] = useModal(
  //   <InfoModal
  //     message={
  //       <div>
  //         You are connected to a different blockchain network
  //         <br />
  //         <Spacer size="xs" />
  //         That's cool, but switch to{" "}
  //         <b>
  //           {parseInt(config.chainId) === 250
  //             ? "Fantom Opera"
  //             : "Fantom Testnet"}{" "}
  //         </b>
  //         in order to use our dApp
  //       </div>
  //     }
  //     withCloseButton={false}
  //     handleActionButton={async () =>
  //       await switchToChain(
  //         walletContext.activeWallet.provider,
  //         parseInt(config.chainId)
  //       )
  //     }
  //     actionButtonText={`Switch to ${
  //       parseInt(config.chainId) === 250 ? "Fantom Opera" : "Fantom Testnet"
  //     }`}
  //     actionButtonNoDismiss={true}
  //   />,
  //   "metamask-wrong-network-modal",
  //   true
  // );
  //
  const [onPresentUnknownAccountModal, onDismissUnknownAccountModal] = useModal(
    <InfoModal
      message={
        <div>
          You changed wallets. <br />
          <br /> Switch back to old wallet address
          <br /> <b>{walletContext.activeWallet.address}</b>
          <br /> to continue. <br />
          <br /> <b>OR</b>
        </div>
      }
      withCloseButton={false}
      handleActionButton={() => loadWeb3Modal()}
      actionButtonText="Connect with current wallet"
    />,
    "metamask-unknown-account-modal",
    true
  );

  // // TODO move chain check to other place (own hook preferably)
  // // Present or dismiss warning modal if wrong network is selected
  // useEffect(() => {
  //   if (
  //     walletContext.activeWallet.address &&
  //     walletContext.web3ProviderState.chainSelected !==
  //       walletContext.activeWallet.chainId
  //   ) {
  //     return onPresentWrongChainSelected();
  //   }
  //   if (
  //     walletContext.web3ProviderState.chainSelected ===
  //       walletContext.activeWallet.chainId &&
  //     modalContext.modalKey === "metamask-wrong-network-modal"
  //   ) {
  //     return onDismissWrongChainSelected();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [walletContext.web3ProviderState, modalContext.isOpen]);

  // Present or dismiss warning modal if wrong metamask account is selected
  useEffect(() => {
    if (
      walletContext.activeWallet.address &&
      !isSameAddress(
        walletContext.activeWallet.address,
        walletContext.web3ProviderState.accountSelected
      )
    ) {
      return onPresentUnknownAccountModal();
    }

    if (
      isSameAddress(
        walletContext.activeWallet.address,
        walletContext.web3ProviderState.accountSelected
      ) &&
      modalContext.modalKey === "metamask-unknown-account-modal"
    ) {
      return onDismissUnknownAccountModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    walletContext.activeWallet.address,
    walletContext.web3ProviderState.accountSelected,
    walletContext.web3ProviderState.chainSelected,
    modalContext.isOpen,
  ]);

  return (
    <Button
      onClick={!walletContext.activeWallet.address ? loadWeb3Modal : undefined}
      variant={"tertiary"}
      style={{
        background: !walletContext.activeWallet.address && "white",
        color: !walletContext.activeWallet.address && "black",
        display: "flex",
        alignItems: "center",
        height: "32px",
        width: !walletContext.activeWallet.address && "155px",
        border: !walletContext.activeWallet.address && "1px solid black",
        marginLeft: "auto",
        zIndex: 10,
      }}
    >
      <Row style={{ flex: 1, justifyContent: "space-between" }}>
        <Row
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {walletContext.activeWallet.address ? (
            <DropDownButton
              DropDown={() => (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    dispatchWalletContext({
                      type: "reset",
                    });
                  }}
                  style={{
                    backgroundColor: "grey",
                    borderRadius: "12px",
                    padding: "1rem 0",
                    fontSize: "18px",
                  }}
                >
                  Logout
                </div>
              )}
              dropdownWidth={138}
              dropdownTop={28}
              dropdownLeft={-25}
            >
              <WalletSelectView wallet={walletContext.activeWallet} />
            </DropDownButton>
          ) : (
            <div>
              <Typo3 style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                Connect wallet
              </Typo3>
            </div>
          )}
        </Row>
      </Row>
    </Button>
  );
};

export default WalletSelector;
