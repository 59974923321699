import { useEffect, useState } from "react";
import { Contract } from "@ethersproject/contracts";
// @ts-ignore
import { abis } from "@fs/contracts";
import { useWalletProvider } from "./index";
import { loadContract } from "../utils";
import { loadProvider } from "../utils/wallet";

export enum LOTTERY_CALL_METHODS {
  LOTTERY_PLAYED = "hasLotteryBeenPlayed",
  IS_LOTTERY_WINNER = "isAlreadyWinner",
  WINNER_ALLOCATION = "getWinnerTokenAllocation",
  LOTTERY_WINNERS_LENGTH = "getLotteryWinnersLength",
}

const lotteryContractCall: {
  [key in LOTTERY_CALL_METHODS]: any;
} = {
  [LOTTERY_CALL_METHODS.LOTTERY_PLAYED]: async (contract: Contract) => {
    return contract.hasLotteryBeenPlayed();
  },
  [LOTTERY_CALL_METHODS.LOTTERY_WINNERS_LENGTH]: async (contract: Contract) => {
    return contract.getLotteryWinnersLength();
  },
  [LOTTERY_CALL_METHODS.IS_LOTTERY_WINNER]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.isAlreadyWinner(address);
  },
  [LOTTERY_CALL_METHODS.WINNER_ALLOCATION]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.getWinnerTokenAllocation(address);
  },
} as any;

const useLotteryContract = (contractAddress: string, chainId: number) => {
  const { walletContext } = useWalletProvider();
  const [contract, setContract] = useState(null);
  // const [, setWithSigner] = useState(false);

  const loadLotteryContract = async (provider: any) => {
    if (!provider) {
      console.error("[loadLotteryContract] Failed to load provider");
      return;
    }

    // Check if provider can also be the signer
    // const signer = await provider.getSigner();
    // const address = signer?.getAddress && (await signer.getAddress());
    // setWithSigner(isSameAddress(address, walletContext.activeWallet.address));

    return loadContract(provider, contractAddress, abis.fsLottery);
  };

  useEffect(() => {
    if (contractAddress) {
      loadProvider(walletContext, chainId).then((provider) => {
        loadLotteryContract(provider).then((contract) => {
          setContract(contract);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contractAddress,
    walletContext.activeWallet.chainId,
    walletContext.activeWallet.address,
  ]);

  const contractIsLoaded = () => {
    return !!contract;
  };

  const callLotteryMethod = async (method: LOTTERY_CALL_METHODS, args: any) => {
    if (!contractIsLoaded()) {
      console.error(
        `[callLotteryMethod] method: ${method} | failed loading lottery contract`
      );
      return;
    }
    if (!lotteryContractCall[method]) {
      console.error(`[callLotteryMethod] method: ${method} | not found`);
      return;
    }

    return lotteryContractCall[method as LOTTERY_CALL_METHODS](
      contract,
      ...args
    );
  };

  return {
    callLotteryMethod: async (method: LOTTERY_CALL_METHODS, args: any[]) =>
      callLotteryMethod(method, args),
    contractIsLoaded: !!contract,
    contract: contract,
  };
};

export default useLotteryContract;
