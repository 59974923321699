import React from "react";
import styled from "styled-components";

interface VideoBackgroundProps {
  src: string;
  poster?: string;
}

const VideoBackground: React.FC<VideoBackgroundProps> = ({ src, poster }) => {
  return (
    <StyledVideo playsInline autoPlay loop muted poster={poster}>
      <source src={src} type="video/mp4" />
    </StyledVideo>
  );
};

const StyledVideo = styled.video`
  position: absolute;
  width: 100%;
`;

export default VideoBackground;
