import { Contract } from "@ethersproject/contracts";
import { useWalletProvider, useTransaction } from "./index";
import { send } from "../utils";
import useLoadFSContract from "./useLoadFSContract";

const CONTRACT_NAME = "tierNFTFactory";
export enum TIER_NFT_FACTORY_CALL_METHODS {
  URI = "uri",
  BALANCE_OF_BATCH = "balanceOfBatch",
  IS_APPROVED_FOR_ALL = "isApprovedForAll",
  TOTAL_SUPPLY = "totalSupply",
  MAX_SUPPLY = "maxSupply",
}
export enum TIER_NFT_FACTORY_TX_METHODS {
  CLAIM_REWARDS = "claimRewards",
  SET_APPROVAL_FOR_ALL = "setApprovalForAll",
}

const tierNFTFactoryContractCall: {
  [key in TIER_NFT_FACTORY_CALL_METHODS]: any;
} = {
  [TIER_NFT_FACTORY_CALL_METHODS.URI]: async (
    contract: Contract,
    id: string
  ) => {
    return contract.uri(id);
  },
  [TIER_NFT_FACTORY_CALL_METHODS.BALANCE_OF_BATCH]: async (
    contract: Contract,
    ids: string[],
    address: string
  ) => {
    return contract.balanceOfBatch(Array(ids.length).fill(address), ids);
  },
  [TIER_NFT_FACTORY_CALL_METHODS.IS_APPROVED_FOR_ALL]: async (
    contract: Contract,
    address: string,
    approvedAddress: string
  ) => {
    return contract.isApprovedForAll(address, approvedAddress);
  },
  [TIER_NFT_FACTORY_CALL_METHODS.TOTAL_SUPPLY]: async (
    contract: Contract,
    cardId: number
  ) => {
    return contract.totalSupply(cardId);
  },
  [TIER_NFT_FACTORY_CALL_METHODS.MAX_SUPPLY]: async (
    contract: Contract,
    cardId: number
  ) => {
    return contract.maxSupply(cardId);
  },
} as any;
const tierNFTFactoryContractTx: {
  [key in TIER_NFT_FACTORY_TX_METHODS]: any;
} = {
  [TIER_NFT_FACTORY_TX_METHODS.CLAIM_REWARDS]: async (
    contract: Contract,
    toStakerID: number
  ) => {
    return contract.claimRewards(toStakerID);
  },
  [TIER_NFT_FACTORY_TX_METHODS.SET_APPROVAL_FOR_ALL]: async (
    contract: Contract,
    approveAddress: number
  ) => {
    return contract.setApprovalForAll(approveAddress, true);
  },
};

const useTierNFTFactory = (chainId: number) => {
  const { walletContext } = useWalletProvider();
  const { dispatchTx } = useTransaction();
  const { getLoadedContract, withSigner, isLoadedOnChain } = useLoadFSContract(
    CONTRACT_NAME,
    chainId
  );

  const callTierNFTFactoryMethod = async (
    method: TIER_NFT_FACTORY_CALL_METHODS,
    args: any
  ) => {
    const loadedContract = await getLoadedContract();
    if (!loadedContract) {
      return;
    }

    if (!tierNFTFactoryContractCall[method]) {
      console.error(`[callTierNFTFactoryMethod] method: ${method} not found`);
      return;
    }

    return tierNFTFactoryContractCall[method as TIER_NFT_FACTORY_CALL_METHODS](
      loadedContract,
      ...args
    );
  };

  const txTierNFTFactoryMethod = async (
    method: TIER_NFT_FACTORY_TX_METHODS,
    args: any
  ) => {
    if (!withSigner) {
      console.error(`[txTierNFTFactoryMethod] contract loaded without signer`);
      return;
    }

    let loadedContract = await getLoadedContract();
    if (!loadedContract) {
      return;
    }

    if (walletContext.activeWallet.provider.connection.url === "eip-1193:") {
      console.log("[eip-1193] connecting signer to TierNFTFactory contract!");
      loadedContract = loadedContract.connect(
        walletContext.activeWallet.signer
      );
    }

    const sendTx = (callback: any) => {
      try {
        return send(walletContext.activeWallet.provider, callback, dispatchTx);
      } catch (err) {
        throw err;
      }
    };

    if (!tierNFTFactoryContractTx[method]) {
      console.error(`[txTierNFTFactoryMethod] method: ${method} not found`);
    }

    return sendTx(() =>
      tierNFTFactoryContractTx[method](loadedContract, ...args)
    );
  };

  return {
    callTierNFTFactoryMethod: async (
      method: TIER_NFT_FACTORY_CALL_METHODS,
      args: any[]
    ) => callTierNFTFactoryMethod(method, args),
    txTierNFTFactoryMethod: async (
      method: TIER_NFT_FACTORY_TX_METHODS,
      args: any[]
    ) => txTierNFTFactoryMethod(method, args),
    contractIsLoaded: isLoadedOnChain,
  };
};

export default useTierNFTFactory;
