import { useEffect, useState } from "react";
import { Contract } from "@ethersproject/contracts";
// @ts-ignore
import { abis } from "@fs/contracts";
import { useTransaction, useWalletProvider } from "./index";
import { isSameAddress, loadContract, send } from "../utils";
import { loadProvider } from "../utils/wallet";

export enum V1_SALE_CALL_METHODS {
  IS_WHITELISTED = "isWhitelisted",
  GET_RECEIPT_BY_ID = "getReceiptById",
  GET_MY_RECEIPT_IDS = "getMyReceiptIds",
  MAX_CAP = "maxPayTokensSpendingPerWallet",
  MIN_CAP = "minPayTokensSpendingPerWallet",
  IS_REFUNDED = "walletHasRefundedPayTokensOnCancelIdo",
  HAS_CLAIMED_FCFS = "hasClaimedNFT",
  HAS_BURNED_FCFS = "hasClaimedBurned",

  // TEMP
  HAS_CLAIMED = "walletHasClaimedIdoTokens",
  IS_CLAIMABLE = "claimInitialMonth",
  CLAIM_FIRST = "claimFirstMonth",
  CLAIM_SECOND = "claimSecondMonth",
  CLAIM_THIRD = "claimThirdMonth",
  CLAIMED_INITIAL = "claimedInitialMonth",
  CLAIMED_FIRST = "claimedFirstMonth",
  CLAIMED_SECOND = "claimedSecondMonth",
  CLAIMED_THIRD = "claimedThirdMonth",
}
export enum V2_SALE_CALL_METHODS {
  GET_BUYER_STATUS = "getTotalIdoTokensBoughtAndPayTokensSpend",
  GET_MAX_INVEST_PAYMENT = "getPaymentForEntireAllocation",
  IDO_TOKENS_BOUGHT = "idoTokensToGetMapping",
  CLAIM_OPEN = "isClaimingOpen",
  CLAIM_INITIAL_PERCENTAGE = "initialPercentageAllocationIdoTokens",
  CLAIM_START_LINEAR = "startDateOfClaimingTokens",
  CLAIM_END_LINEAR = "endDateOfClaimingTokens",
  CLAIMED_TOTAL = "idoTokensClaimedMapping",
  ONLY_MAX_INVEST = "inOneTransaction",
  GET_CLAIMABLE_TOKENS = "getClaimableTokens",
}
export enum V4_SALE_CALL_METHODS {
  GET_MAX_INVEST_PAYMENT = "getPaymentForEntireAllocation",
  GET_MAX_ITEMS_PER_USER = "maxItemsPerUser",
  CALCULATE_MAX_PAYMENT_TOKENS = "calculateMaxPaymentTokens",
  IS_FCFS = "isFCFS",
}
export enum SALE_CALL_METHODS {
  INVESTOR_COUNT = "investorCount",
  TOTAL_SPEND = "totalSpendPayTokens",
  IS_CANCELED = "isFundingCanceled",
  SPEND_PAYTOKENS = "spendPayTokensMapping",
}
export enum SALE_TX_METHODS {
  BUY = "buy",
  CLAIM = "claimTokens",
  REFUND = "claimPayedTokensOnIdoCancel",
}

const saleContractCall: {
  [key in
    | SALE_CALL_METHODS
    | V1_SALE_CALL_METHODS
    | V2_SALE_CALL_METHODS
    | V4_SALE_CALL_METHODS]: any;
} = {
  [V1_SALE_CALL_METHODS.IS_WHITELISTED]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.isWhitelisted(address);
  },
  [V1_SALE_CALL_METHODS.GET_RECEIPT_BY_ID]: async (
    contract: Contract,
    receiptId: string
  ) => {
    return contract.getReceiptById(receiptId);
  },
  [V1_SALE_CALL_METHODS.GET_MY_RECEIPT_IDS]: async (contract: Contract) => {
    return contract.getMyReceiptIds();
  },
  // [SALE_CALL_METHODS.IS_CLAIMABLE]: async (contract: Contract) => {
  //   return contract.isIdoTokenFundedToContract();
  // },
  [V1_SALE_CALL_METHODS.IS_CLAIMABLE]: async (contract: Contract) => {
    return contract.claimInitialMonth();
  },
  [SALE_CALL_METHODS.INVESTOR_COUNT]: async (contract: Contract) => {
    return contract.investorCount();
  },
  [SALE_CALL_METHODS.TOTAL_SPEND]: async (contract: Contract) => {
    return contract.totalSpendPayTokens();
  },
  [V1_SALE_CALL_METHODS.HAS_CLAIMED]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.walletHasClaimedIdoTokens(address);
  },
  [SALE_CALL_METHODS.IS_CANCELED]: async (contract: Contract) => {
    return contract.isFundingCanceled();
  },
  [SALE_CALL_METHODS.SPEND_PAYTOKENS]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.spendPayTokensMapping(address);
  },
  [V1_SALE_CALL_METHODS.IS_REFUNDED]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.walletHasRefundedPayTokensOnCancelIdo(address);
  },
  [V1_SALE_CALL_METHODS.MAX_CAP]: async (contract: Contract) => {
    return contract.maxPayTokensSpendingPerWallet();
  },
  [V1_SALE_CALL_METHODS.MIN_CAP]: async (contract: Contract) => {
    return contract.minPayTokensSpendingPerWallet();
  },

  // TMP
  [V1_SALE_CALL_METHODS.CLAIMED_INITIAL]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.claimedInitialMonth(address);
  },
  [V1_SALE_CALL_METHODS.CLAIMED_FIRST]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.claimedFirstMonth(address);
  },
  [V1_SALE_CALL_METHODS.CLAIMED_SECOND]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.claimedSecondMonth(address);
  },
  [V1_SALE_CALL_METHODS.CLAIMED_THIRD]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.claimedThirdMonth(address);
  },
  [V1_SALE_CALL_METHODS.CLAIM_FIRST]: async (contract: Contract) => {
    return contract.claimFirstMonth();
  },
  [V1_SALE_CALL_METHODS.CLAIM_SECOND]: async (contract: Contract) => {
    return contract.claimSecondMonth();
  },
  [V1_SALE_CALL_METHODS.CLAIM_THIRD]: async (contract: Contract) => {
    return contract.claimThirdMonth();
  },
  [V1_SALE_CALL_METHODS.HAS_CLAIMED_FCFS]: async (contract: Contract) => {
    return contract.hasClaimedNFT();
  },
  [V1_SALE_CALL_METHODS.HAS_BURNED_FCFS]: async (contract: Contract) => {
    return contract.hasClaimedBurned();
  },
  [V2_SALE_CALL_METHODS.GET_BUYER_STATUS]: async (contract: Contract) => {
    return contract.getTotalIdoTokensBoughtAndPayTokensSpend();
  },
  [V2_SALE_CALL_METHODS.GET_MAX_INVEST_PAYMENT]: async (
    contract: Contract,
    tokenAmountInWei: string
  ) => {
    return contract.calculateMaxPaymentToken(tokenAmountInWei);
  },
  [V2_SALE_CALL_METHODS.IDO_TOKENS_BOUGHT]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.idoTokensToGetMapping(address);
  },
  [V2_SALE_CALL_METHODS.CLAIM_OPEN]: async (contract: Contract) => {
    return contract.isClaimingOpen();
  },
  [V2_SALE_CALL_METHODS.CLAIM_INITIAL_PERCENTAGE]: async (
    contract: Contract
  ) => {
    return contract.initialPercentageAllocationIdoTokens();
  },
  [V2_SALE_CALL_METHODS.CLAIM_START_LINEAR]: async (contract: Contract) => {
    return contract.startDateOfClaimingTokens();
  },
  [V2_SALE_CALL_METHODS.CLAIM_END_LINEAR]: async (contract: Contract) => {
    return contract.endDateOfClaimingTokens();
  },
  [V2_SALE_CALL_METHODS.CLAIMED_TOTAL]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.idoTokensClaimedMapping(address);
  },
  [V2_SALE_CALL_METHODS.ONLY_MAX_INVEST]: async (contract: Contract) => {
    return contract.inOneTransaction();
  },
  [V2_SALE_CALL_METHODS.GET_CLAIMABLE_TOKENS]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.getClaimableTokens(address);
  },
  [V4_SALE_CALL_METHODS.GET_MAX_INVEST_PAYMENT]: async (
    contract: Contract,
    tokenAmountInWei: string
  ) => {
    return contract.calculateMaxPaymentToken(tokenAmountInWei);
  },
  [V4_SALE_CALL_METHODS.IS_FCFS]: async (contract: Contract) => {
    return contract.isFCFS();
  },
  [V4_SALE_CALL_METHODS.GET_MAX_ITEMS_PER_USER]: async (contract: Contract) => {
    return contract.maxItemsPerUser();
  },
} as any;
const saleContractTx: {
  [key in SALE_TX_METHODS]: any;
} = {
  [SALE_TX_METHODS.BUY]: async (contract: Contract, amountInWei: string) => {
    return contract.buy(amountInWei);
  },
  [SALE_TX_METHODS.CLAIM]: async (contract: Contract) => {
    return contract.claimTokens();
  },
  [SALE_TX_METHODS.REFUND]: async (contract: Contract) => {
    return contract.claimPayedTokensOnIdoCancel();
  },
};

const useSaleContract = (
  contractAddress: string,
  saleVersion: number,
  chainId: number
) => {
  const { walletContext } = useWalletProvider();
  const { dispatchTx } = useTransaction();
  const [contract, setContract] = useState(null);
  const [withSigner, setWithSigner] = useState(false);

  const loadSaleContract = async (provider: any) => {
    if (!provider) {
      console.error("[loadSaleContract] Failed to load provider");
      return;
    }

    let address;
    try {
      // Check if provider can also be the signer
      const signer = await provider.getSigner();
      address = await signer.getAddress();
    } catch (err) {
      console.warn("[loadSaleContract] Failed to load as Signer");
    }

    setWithSigner(isSameAddress(address, walletContext.activeWallet.address));

    return loadContract(
      provider,
      contractAddress,
      saleVersion === 1
        ? abis.fsIdo
        : saleVersion === 4
        ? abis.fsSaleV4
        : abis.fsIdoLottery
    );
  };

  useEffect(() => {
    if (contractAddress) {
      loadProvider(walletContext, chainId).then((provider) => {
        loadSaleContract(provider).then((contract) => {
          if (provider.connection.url === "eip-1193:") {
            console.log("[eip-1193] connecting signer to sale contract!");
            contract = contract.connect(walletContext.activeWallet.signer);
          }
          setContract(contract);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contractAddress,
    walletContext.activeWallet.chainId,
    walletContext.activeWallet.address,
  ]);

  const contractIsLoaded = () => {
    return !!contract;
  };

  const callSaleMethod = async (
    method:
      | SALE_CALL_METHODS
      | V1_SALE_CALL_METHODS
      | V2_SALE_CALL_METHODS
      | V4_SALE_CALL_METHODS,
    args: any
  ) => {
    if (!contractIsLoaded()) {
      console.error(
        `[callSaleMethod] method: ${method} | failed loading sale contract`
      );
      return;
    }
    if (!saleContractCall[method]) {
      console.error(`[callSaleMethod] method: ${method} | not found`);
      return;
    }

    return saleContractCall[method as SALE_CALL_METHODS](contract, ...args);
  };

  const txSaleMethod = async (method: SALE_TX_METHODS, args: any) => {
    if (!contractIsLoaded()) {
      console.error(`[callSaleMethod] failed loading sale contract`);
      return;
    }

    if (!withSigner) {
      console.error(`[callSaleMethod] need signer for sending tx`);
      return;
    }

    const sendTx = (callback: any) => {
      try {
        return send(walletContext.activeWallet.provider, callback, dispatchTx);
      } catch (err) {
        throw err;
      }
    };

    if (!saleContractTx[method]) {
      console.error(`[txSaleMethod] method: ${method} not found`);
    }

    return sendTx(() => saleContractTx[method](contract, ...args));
  };

  return {
    callSaleMethod: async (
      method:
        | SALE_CALL_METHODS
        | V1_SALE_CALL_METHODS
        | V2_SALE_CALL_METHODS
        | V4_SALE_CALL_METHODS,
      args: any[]
    ) => callSaleMethod(method, args),
    txSaleMethod: async (method: SALE_TX_METHODS, args: any[]) =>
      txSaleMethod(method, args),
    contractIsLoaded: !!contract,
    contract,
  };
};

export default useSaleContract;
