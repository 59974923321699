import { useWalletProvider, useTransaction } from "./index";
import { send } from "../utils";
import { loadProvider } from "../utils/wallet";
import config from "../config/config";

const useFantomNative = () => {
  const { walletContext } = useWalletProvider();
  const { dispatchTx } = useTransaction();

  const sendNativeTokens = async (toAddress: string, amount: string) => {
    if (!walletContext.activeWallet.signer) {
      console.error("[sendTransation] signer not found");
      return;
    }
    if (parseFloat(amount) <= 0) {
      console.error("[sendTransation] amount <= 0");
      return;
    }

    return send(
      walletContext.activeWallet.provider,
      () =>
        walletContext.activeWallet.signer.sendTransaction({
          to: toAddress,
          value: amount,
        }),
      dispatchTx
    );
  };

  const getNativeBalance = async (chainId = config.defaultChainId) => {
    const provider = await loadProvider(walletContext, chainId);
    return provider.getBalance(walletContext.activeWallet.address);
  };
  return {
    sendNativeTokens: async (toAddress: string, amount: string) =>
      await sendNativeTokens(toAddress, amount),
    getNativeBalance,
  };
};

export default useFantomNative;
