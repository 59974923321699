import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Typo2 } from "../../components";

interface NavMenuProps {
  name: string;
  path: string;
  currentPath: string;
  linkCallback: any;
}

const NavMenu: React.FC<NavMenuProps> = ({
  name,
  path,
  currentPath,
  linkCallback,
}) => {
  const isActive = path === currentPath;

  return (
    <StyledNavLink to={path} onClick={linkCallback}>
      <StyledLinkContainer active={isActive}>
        <StyledLinkName active={isActive}>{name}</StyledLinkName>
      </StyledLinkContainer>
    </StyledNavLink>
  );
};

interface StyledLinkNameProps {
  active: boolean;
}
const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;
const StyledLinkContainer = styled.div<any>`
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  white-space: nowrap;
`;
const StyledLinkName = styled(Typo2)<StyledLinkNameProps>`
  color: ${(props) => props.theme.fontColor.secondary};
  font-weight: ${(props) => (props.active ? "bold" : "unset")};
  font-size: 16px;
`;

export default NavMenu;
