import React, { useState, useCallback } from "react";
import {
  Button,
  Heading1,
  Heading3,
  Typo1,
  Typo2,
  LabeledCheckbox,
  Modal,
  ModalTitle,
  Spacer,
} from "../../components";
import type { ModalStyling } from "../../components";
import { IConsent } from "../../shared";

interface IConsentModal {
  message: string | JSX.Element;
  title: string;
  consent_requirements: IConsent[];
  setConsented: () => void;
  onDismiss?: () => void;
  styling?: ModalStyling;
  padding?: string;
}

const ConsentModal: React.FC<IConsentModal> = ({
  message,
  title,
  consent_requirements = [],
  onDismiss,
  styling = "full",
  padding,
  setConsented,
}) => {
  const [consent, setConsent] = useState({
    consentCount: [],
    consented: false,
  });

  const handleCheckbox = useCallback(
    (index: number) => {
      const consentCount = consent.consentCount;

      if (consentCount.indexOf(index) !== -1) {
        consentCount.splice(consentCount.indexOf(index), 1);
      } else {
        consentCount.push(index);
      }

      setConsent({ ...consent, consentCount });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setConsent]
  );

  const allowedToConsent =
    consent.consentCount.length === consent_requirements.length;

  return (
    <Modal onDismiss={onDismiss} styling={styling} style={{ padding: padding }}>
      {consent_requirements.length > 0 ? (
        <Heading1>{title}</Heading1>
      ) : (
        <ModalTitle text={title} />
      )}
      <Typo2>
        {typeof message === "string" ? <Typo1>{message}</Typo1> : message}
      </Typo2>
      <div>
        {consent_requirements.map((requirement, index) => {
          return (
            <div key={index}>
              <Spacer size="xl" />
              <Heading3>{`${index + 1}. ${requirement.heading}`}</Heading3>
              <Spacer size="xs" />
              <Typo2>{requirement.text}</Typo2>
              <Spacer size="lg" />
              <LabeledCheckbox
                {...{
                  name: requirement.labeledCheckbox.name,
                  text: requirement.labeledCheckbox.text,
                }}
                onChange={() => handleCheckbox(index)}
              />
            </div>
          );
        })}
        <Spacer size="xl" />
        <Typo2>
          By continuing I agree to submit any and all disputes involving
          FantomStarter, any of its users or employees, or any offering on
          FantomStarter, to binding arbitration.
        </Typo2>
        <Spacer size="xs" />
        <Button
          style={{ width: "300px" }}
          onClick={setConsented}
          disabled={!allowedToConsent}
          variant={!allowedToConsent ? "quaternary" : "tertiary"}
        >
          I agree and accept
        </Button>
      </div>
    </Modal>
  );
};

export default ConsentModal;
