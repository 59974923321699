import React, { useContext, useEffect, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { ModalContext } from "../../context";
import {
  Column,
  Spacer,
  Button,
  Heading4,
  Modal,
  ModalTitle,
  Row,
  Typo1,
  Typo2,
  Typo3,
} from "../../components";
import {
  useFantomStarterApi,
  FANTOM_STARTER_API_CALL,
  useAccount,
  useModal,
  useApiData,
  useWalletProvider,
} from "../../hooks";
import { fractalLogoWithTextImg, fractalLogoImg } from "../../assets";

const WalletManagementModal: React.FC<any> = ({ onDismiss }) => {
  const [resultMessage, setResultMessage] = useState(null);
  const { account } = useAccount();
  const { walletContext } = useWalletProvider();
  const { updateAccountWallet } = useFantomStarterApi();
  const { apiData } = useApiData();
  const updateWalletResponse =
    apiData[FANTOM_STARTER_API_CALL.UPDATE_WALLET_ADDRESS];
  const isPending = updateWalletResponse?.state === "pending";
  const isCompleted = updateWalletResponse?.state === "success";

  const isWalletMismatch =
    account.accountWalletAddress?.toLowerCase().trim() !==
    account.fractalWalletAddress?.toLowerCase().trim();
  const isWrongWalletConnected =
    !isWalletMismatch &&
    account.accountWalletAddress?.toLowerCase().trim() !==
      walletContext.activeWallet.address?.toLowerCase().trim();

  const handleUpdateAccountWallet = async () => {
    try {
      await updateAccountWallet(
        account.sessionID,
        account.fractalWalletAddress
      );
    } catch (err) {
      console.error(err);
      setResultMessage(
        "Failed to update wallet-address, please try again later."
      );
    }
  };

  useEffect(() => {
    let timeout: any;
    if (isCompleted && isWalletMismatch) {
      timeout = setTimeout(() => {
        onDismiss();
      }, 1000);
    }
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted]);

  useEffect(() => {
    if (!isWalletMismatch && !isWrongWalletConnected) {
      onDismiss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.walletAddress, walletContext.activeWallet.address]);

  return (
    <Modal onDismiss={onDismiss}>
      <ModalTitle text={"Wallet management"} />
      <Spacer />
      <Column style={{ maxWidth: "50rem" }}>
        <Column>
          <Typo2>
            {" "}
            FantomStarter registered wallet-address:{" "}
            <b>{account.accountWalletAddress || "None"}</b>
          </Typo2>
          <Spacer size="xxs" />
          {isWrongWalletConnected && (
            <Typo2>
              {" "}
              Web3 connected wallet-address:{" "}
              <b>{walletContext.activeWallet.address || "None"}</b>
            </Typo2>
          )}
          {isWalletMismatch && (
            <Typo2>
              {" "}
              Fractal registered wallet-address:{" "}
              <b>{account.fractalWalletAddress || "None"}</b>
            </Typo2>
          )}
        </Column>
        <Spacer />
        {isWalletMismatch && (
          <Column>
            <Typo2 style={{ fontWeight: "bold" }}>Issue found!</Typo2>
            <Spacer size="xxs" />
            <Typo2 style={{ color: "red" }}>
              There is a mismatch between your registered Fractal wallet-address
              and FantomStarter wallet address.
            </Typo2>
            <Spacer size="sm" />
            <Typo2>To fix this you can OR:</Typo2>
            <ul
              style={{ paddingInlineStart: "20px", marginBlockStart: ".5rem" }}
            >
              <li>
                <Typo2 style={{ wordWrap: "break-word" }}>
                  {" "}
                  Update your registered wallet-address on your{" "}
                  <a
                    href={"https://www.fractal.id"}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    Fractal account page
                  </a>{" "}
                  to match your FantomStarter registered wallet-address.
                </Typo2>
              </li>
              <Spacer size="xs" />
              <Typo2>
                {" "}
                <li>
                  Update your FantomStarter registered wallet-address to match
                  your Fractal registered wallet-address. You can do so by
                  clicking the "Sync with Fractal" button below.
                </li>
                {/*<Typo3>*/}
                {/*  Note: Updating your registered FantomStarter wallet-address*/}
                {/*  might exclude you from currently running sales.*/}
                {/*</Typo3>*/}
              </Typo2>
            </ul>
            <Spacer />
            <Button
              variant="primary"
              onClick={handleUpdateAccountWallet}
              disabled={isCompleted || isPending}
            >
              {isPending
                ? "Syncing..."
                : isCompleted
                ? "Syncing Completed"
                : "Sync with Fractal"}
            </Button>
            {resultMessage && (
              <>
                <Spacer size="xs" />
                <Typo3 style={{ color: "red" }}>{resultMessage}</Typo3>
              </>
            )}
          </Column>
        )}
        {isWrongWalletConnected && (
          <Column>
            <Typo2 style={{ fontWeight: "bold" }}>Issue found!</Typo2>
            <Spacer size="xxs" />
            <Typo2 style={{ color: "red" }}>
              Connected to wrong <b>web3</b> wallet-address.
            </Typo2>
            <Spacer size="sm" />
            <Typo2>
              Please connect your <b>web3 wallet provider</b> (i.e. Metamask) to
              the wallet-address that matches your registered FantomStarter
              wallet-address. This modal will auto-close when you are connected
              to the correct wallet-address.
            </Typo2>
            <Spacer />
          </Column>
        )}
      </Column>
    </Modal>
  );
};

const FractalDisplay: React.FC<any> = ({ to, from, hide }) => {
  const modalContext = useContext(ModalContext);
  const { fontColor } = useContext(ThemeContext);
  const { search } = useLocation();
  const history = useHistory();
  const { login, getAccountWallet, getSessionStatus } = useFantomStarterApi();
  const { account, dispatchAccount } = useAccount();
  const { walletContext } = useWalletProvider();
  const [isCorrectWalletConnected, setIsCorrectWalletConnected] = useState(
    false
  );
  const [isNoWalletMismatchIssue, setIsNoWalletMismatchIssue] = useState(false);

  const [onPresentWalletManagementModal] = useModal(
    <WalletManagementModal />,
    "wallet-management-modal"
  );

  const setRedirect = () => {
    if (from) {
      localStorage.setItem("redirect", from);
    }
    return true;
  };

  useEffect(() => {
    if (search) {
      try {
        const params = new URLSearchParams(search);
        const code = params.get("code");
        const redirect = localStorage.getItem("redirect");

        if (code) {
          dispatchAccount({ type: "loginFractal", fractalCode: code });
          if (!account.sessionID) {
            login(code);
          }
        }

        if (redirect) {
          localStorage.removeItem("redirect");
          return history.push(redirect);
        }
        return history.push("/projects");
      } catch (err) {
        console.error(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (
      !account.accountWalletAddress &&
      walletContext.activeWallet.address &&
      account.sessionID
    ) {
      getAccountWallet(account.sessionID, walletContext.activeWallet.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account.sessionID,
    walletContext.activeWallet.address,
    account.accountWalletAddress,
    modalContext.isOpen,
  ]);

  useEffect(() => {
    const sessionID = localStorage.getItem("sessionID");
    if (sessionID) {
      getSessionStatus(sessionID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNoWalletMismatchIssue, isCorrectWalletConnected, modalContext.isOpen]);

  useEffect(() => {
    if (account.accountWalletAddress && account.fractalWalletAddress) {
      setIsNoWalletMismatchIssue(
        account.accountWalletAddress.toLowerCase().trim() ===
          account.fractalWalletAddress.toLowerCase().trim()
      );
    }
    if (account.accountWalletAddress && walletContext.activeWallet.address) {
      setIsCorrectWalletConnected(
        account.accountWalletAddress.toLowerCase().trim() ===
          walletContext.activeWallet.address?.toLowerCase().trim()
      );
    }
  }, [
    account.accountWalletAddress,
    account.fractalWalletAddress,
    walletContext.activeWallet.address,
    modalContext.isOpen,
  ]);

  const isValidSession =
    account.sessionID && account.sessionIsValid && account.sessionIsActive;

  return (
    <>
      {!hide && (
        <FractalWrapper>
          {!isValidSession ? (
            <Column style={{ padding: "0 3rem" }}>
              <Spacer size="lg" />
              <img
                alt="FractalLogo"
                style={{ alignSelf: "center", height: "28px" }}
                src={fractalLogoWithTextImg}
              />
              <Spacer size="sm" />
              <Typo2
                style={{ textAlign: "center", color: fontColor.secondary }}
              >
                In order to whitelist for this project you need to be logged in
                to Fractal.
              </Typo2>
              <Spacer size="sm" />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textDecoration: "none",
                  cursor: "pointer",
                  backgroundColor: "#fd6a04",
                  borderRadius: "20px",
                  opacity: isValidSession && 0.5,
                  alignSelf: "center",
                }}
                onClick={() =>
                  !isValidSession && setRedirect() && window.open(to, "_blank")
                }
              >
                <Typo2
                  style={{
                    padding: ".3rem 3rem",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Log in
                </Typo2>
              </div>
              {account.loginError && (
                <>
                  <Spacer size="xs" />
                  <Typo3
                    style={{
                      fontSize: "12px",
                      margin: "0 -2rem",
                      color: "red",
                      textAlign: "center",
                    }}
                  >
                    *{account.loginError}
                  </Typo3>
                </>
              )}
              <Spacer size="sm" />
            </Column>
          ) : (
            <>
              <Column
                style={{
                  alignItems: "center",
                  borderBottom:
                    !isCorrectWalletConnected && "1px solid #f3f3f3",
                }}
              >
                <FractalTooltip
                  onClick={() => window.open("https://fractal.id", "_blank")}
                >
                  <TooltipText className="tooltipText">
                    <div style={{ fontWeight: "bold", letterSpacing: 1.5 }}>
                      {account.fractalEmail || account.fractalPhone}
                    </div>
                  </TooltipText>
                  <Spacer size="sm" />
                  <Row
                    style={{
                      alignItems: "center",
                      gap: "1rem",
                      padding: "0 2rem",
                    }}
                  >
                    <img
                      alt="FractalLogo"
                      src={fractalLogoImg}
                      style={{ height: "24px" }}
                    />
                    <Column style={{ gap: ".2rem" }}>
                      <Typo3 style={{ color: fontColor.secondary }}>
                        Fractal status
                      </Typo3>
                      <Row style={{ alignItems: "center", gap: ".4rem" }}>
                        <Heading4>{`${account.sessionAccountVerification[0].toUpperCase()}${account.sessionAccountVerification.slice(
                          1
                        )}`}</Heading4>
                        <div
                          style={{
                            height: "8px",
                            width: "8px",
                            borderRadius: "50%",
                            backgroundColor:
                              account.sessionAccountVerification === "approved"
                                ? "#6dd400"
                                : account.sessionAccountVerification ===
                                  "pending"
                                ? "#f7b500"
                                : "#e02020",
                          }}
                        />
                      </Row>
                    </Column>
                  </Row>
                  <Spacer size="sm" />
                </FractalTooltip>
              </Column>
              {account.sessionAccountVerification === "approved" &&
                (!isCorrectWalletConnected || !isNoWalletMismatchIssue) && (
                  <Column style={{ alignItems: "center" }}>
                    <Spacer size="sm" />
                    <Tooltip
                      style={{
                        width: "70%",
                        textAlign: "center",
                        color: "#e02020",
                        cursor: "pointer",
                      }}
                      onClick={() => onPresentWalletManagementModal()}
                    >
                      {!isNoWalletMismatchIssue
                        ? "Wallet mismatch issue detected! ⓘ"
                        : "Please connect to registered wallet address ⓘ"}
                      {/*<TooltipText className="tooltipText">*/}
                      {/*  <div style={{ fontWeight: "bold", letterSpacing: 1.5 }}>*/}
                      {/*    {account.accountWalletAddress}*/}
                      {/*  </div>*/}
                      {/*</TooltipText>*/}
                    </Tooltip>
                    {/*<Spacer size="xxs" />*/}
                    {/*<Typo3 style={{ fontSize: "12px" }}>More info</Typo3>*/}
                    <Spacer size="sm" />
                  </Column>
                )}
            </>
          )}
        </FractalWrapper>
      )}
    </>
  );
};

const FractalWrapper = styled.div`
  background-color: white;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  box-shadow: 0 0 10px 1px #e2e2e2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  max-height: 267px;
  max-width: 300px;
  z-index: 5;

  @media (min-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    position: fixed;
    right: 2rem;
  }
`;

const Tooltip = styled((props: any) => <Typo3 {...props} />)`
  position: relative;
  :hover {
    text-decoration: underline;
  }
  :hover .tooltipText {
    visibility: visible;
  }
`;

const FractalTooltip = styled((props: any) => <Typo3 {...props} />)`
  position: relative;
  cursor: pointer;

  :hover .tooltipText {
    visibility: visible;
  }
`;

const TooltipText = styled((props: any) => <Typo3 {...props} />)`
  visibility: hidden;
  position: absolute;
  background-color: #696f79;
  color: white;
  padding: 0.7rem;
  border-radius: 10px;
  box-shadow: 0 0 10px 1px #e2e2e2;
  font-size: 14px;

  z-index: 20;
  right: 0px;
  bottom: -32px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #696f79;
    border-top: 0;
    margin-left: -10px;
    margin-top: -10px;
  }
`;

export default FractalDisplay;
