import React from "react";
import {
  Heading3,
  Heading4,
  Heading5,
  Column,
  Row,
  Spacer,
  Typo3,
} from "../../components";
import { toFormattedBalance } from "../../utils";

export const StoreCardDetails: React.FC<any> = ({
  activeCard,
  cardSupply,
  activeCardId,
}) => {
  return (
    <Column style={{ marginLeft: "24px", paddingTop: "8px" }}>
      <Heading4>{activeCard.name} NFT</Heading4>
      <Typo3>Tier {activeCard.tier}</Typo3>
      <Spacer />
      <Heading4>
        {" "}
        {activeCard.tickets === 100 ? "Guaranteed" : activeCard.tickets + "X"}
      </Heading4>
      <Typo3>Lottery tickets</Typo3>
      <Spacer />
      <Heading4>
        {activeCard.weight === 0 ? "TBD" : activeCard.weight + "X"}
      </Heading4>
      <Typo3>Pool weight</Typo3>
      <Spacer />
      <Heading4>
        {activeCard.max - cardSupply[activeCardId] === 0
          ? "Sold out"
          : activeCard.max - cardSupply[activeCardId] + " left"}
      </Heading4>
      <Typo3>Available for purchase</Typo3>
      <Spacer />
      <Heading4>{toFormattedBalance(activeCard.price)[0] + " FS"}</Heading4>
      <Typo3>Price</Typo3>
      <Spacer />
      <Spacer size="xs" />
    </Column>
  );
};
