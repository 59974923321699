import { useCallback, useContext } from "react";
import { ModalContext, IOnPresent } from "../context";

const useModal = (...[content, key, persist]: Parameters<IOnPresent>) => {
  const { onDismiss, onPresent } = useContext(ModalContext);

  const handlePresent = useCallback(() => {
    onPresent(content, key, persist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, content, onPresent]);

  return [handlePresent, onDismiss];
};

export default useModal;
