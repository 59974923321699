import { useState } from "react";
import useTransaction from "./useTransaction";

const useSendTransaction = (callback: any) => {
  const { transaction } = useTransaction();
  const [txHash, setTxHash] = useState(null);
  const [error, setError] = useState(null);
  const tx = transaction[txHash];
  const isPending = tx && tx.status === "pending";
  const isCompleted = tx && tx.status === "success";
  const isFailed = tx && tx.status === "failed";

  const reset = () => {
    setTxHash(null);
  };

  const sendTx = async () => {
    try {
      const hash = await callback();
      setTxHash(hash);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  return {
    sendTx,
    hash: txHash,
    tx,
    isPending,
    isCompleted,
    isFailed,
    error,
    reset,
  };
};

export default useSendTransaction;
