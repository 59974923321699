import { switchToChain } from "../../utils";
import { InfoModal, Spacer } from "../index";
import React from "react";
import { useWalletProvider } from "../../hooks";
import { chainToNetworkNameMap } from "../../utils/wallet";

const WrongNetworkModal: React.FC<any> = ({ toChain, onDismiss }) => {
  const { walletContext } = useWalletProvider();
  return (
    <InfoModal
      message={
        <div>
          You are connected to the wrong blockchain network
          <br />
          <Spacer size="xs" />
          That's cool, but switch to <b>{chainToNetworkNameMap[toChain]}</b> in
          order to use our dApp
        </div>
      }
      withCloseButton={false}
      handleActionButton={async () =>
        await switchToChain(
          walletContext.activeWallet.provider,
          parseInt(toChain)
        )
      }
      actionButtonText={`Switch to ${chainToNetworkNameMap[toChain]}`}
      actionButtonNoDismiss={true}
      onDismiss={onDismiss}
    />
  );
};

export default WrongNetworkModal;
