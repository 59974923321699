import React, { useState } from "react";
import styled from "styled-components";
import { Heading3, Typo3, Typo4 } from "..";

interface CountDownProps {
  timeLeftP: number;
}

interface CountDownObject {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

function calculateTimeLeft(timeLeftP: number): CountDownObject {
  const difference = +new Date(timeLeftP) - +new Date();
  let timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
}

const CountDown: React.FC<CountDownProps> = (props) => {
  const [timeLeft, setTimeLeft] = useState<CountDownObject>(
    calculateTimeLeft(props.timeLeftP)
  );

  React.useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(props.timeLeftP));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  const timerComponents = Object.keys(timeLeft).map((interval, key) => {
    return (
      <CountDownFragment key={key}>
        <Heading3 style={{ display: "flex", justifyContent: "center" }}>
          {timeLeft[interval as keyof CountDownObject]}
        </Heading3>
        <Typo4 style={{ display: "flex", justifyContent: "center" }}>
          {interval}
        </Typo4>
      </CountDownFragment>
    );
  });

  return (
    <StyledCountDown>
      {props.timeLeftP > 0 ? timerComponents : <span>Time's up!</span>}
    </StyledCountDown>
  );
};

const StyledCountDown = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
`;

const CountDownFragment = styled.div`
  position: relative;

  &:not(:last-child)::before {
    content: ":";
    position: absolute;
    right: -30%;
  }
`;

export default CountDown;
