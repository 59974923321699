import React, { useReducer } from "react";

const initial = {
  fractalCode: null,
  accountWalletAddress: null,
  fractalWalletAddress: null,
  sessionID: localStorage.getItem("sessionID") || null,
  sessionIsValid: null,
  sessionIsActive: null,
  sessionAccountVerification: null,
  loginError: null,
} as any;
export const AccountContext = React.createContext(null);

export const AccountProvider: React.FC<any> = ({ children }) => {
  const accountReducer = (state: any, action: any) => {
    switch (action.type) {
      case "loginFractal":
        return {
          ...state,
          fractalCode: action.fractalCode,
        };

      case "loginFantomStarter":
        localStorage.setItem("sessionID", action.sessionID);
        return {
          ...state,
          fractalWalletAddress: action.fractalWalletAddress,
          fractalEmail: action.fractalEmail,
          fractalPhone: action.fractalPhone,
          sessionID: action.sessionID,
          sessionAccountVerification: action.sessionAccountVerification,
          sessionIsValid: true,
          sessionIsActive: true,
          loginError: null,
        };
      case "loginError":
        return {
          ...state,
          loginError: action.message,
        };
      case "fetchAccountWallet":
        return {
          ...state,
          accountWalletAddress: action.accountWalletAddress,
          sessionAccountVerification: action.sessionAccountVerification,
          sessionIsValid: action.sessionIsValid || false,
          sessionIsActive: action.sessionIsActive || false,
          loginError: null,
        };

      case "updateFractalSession":
        return {
          ...state,
          accountWalletAddress: action.accountWalletAddress,
          fractalWalletAddress: action.fractalWalletAddress,
          fractalEmail: action.fractalEmail,
          fractalPhone: action.fractalPhone,
          sessionAccountVerification: action.sessionAccountVerification,
          sessionIsValid: action.sessionIsValid || false,
          sessionIsActive: action.sessionIsActive || false,
          loginError: null,
        };

      case "logoutFantomStarter":
        // localStorage.removeItem("sessionID");
        return {
          ...initial,
        };

      case "resetFractalSession":
        localStorage.removeItem("sessionID");
        return {
          ...initial,
          sessionID: null,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(accountReducer, initial);

  return (
    <AccountContext.Provider value={[state, dispatch]}>
      {children}
    </AccountContext.Provider>
  );
};
