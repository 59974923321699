import React from "react";
import styled, { keyframes, css } from "styled-components";

interface MenuIconProps {
  isOpen: boolean;
}

const MenuIcon: React.FC<MenuIconProps> = ({ isOpen }) => {
  return (
    <StyledMenuIcon isOpen={isOpen}>
      <TopStroke />
      <MiddleStroke />
      <BottomStroke />
    </StyledMenuIcon>
  );
};

const animateTop = keyframes`
	0% {
		top: 0%;
		transform: translateY(-50%) rotate(0);
	}
	25% {
		top: 50%;
		transform: translateY(-50%) rotate(0deg);
	}
	50% {
		top: 50%;
		transform: translateY(-50%) rotate(0deg);
	}
	100% {
		top: 50%;
		transform: translateY(-50%) rotate(45deg);
	}
`;

const animateBottom = keyframes`
	0% {
		top: 100%;
		transform: translateY(-50%) rotate(0);
	}
	25% {
		top: 50%;
		transform: translateY(-50%) rotate(0deg);
	}
	50% {
		top: 50%;
		transform: translateY(-50%) rotate(0deg);
	}
	100% {
		top: 50%;
		transform: translateY(-50%) rotate(-45deg);
	}
`;

const animateTopCss = css`
  animation: ${animateTop} 0.5s ease-out 0s 1 normal forwards;
`;

const animateBottomCss = css`
  animation: ${animateBottom} 0.5s ease-out 0s 1 normal forwards;
`;

const StyledSpan = styled.span`
  left: 0;
  position: absolute;
  right: 0;
`;

const TopStroke = styled(StyledSpan)`
  top: 0%;
`;

const MiddleStroke = styled(StyledSpan)`
  top: 50%;
  transform: translateY(-50%);
`;

const BottomStroke = styled(StyledSpan)`
  top: 100%;
  transform: translateY(-50%);
`;

const StyledMenuIcon = styled.div<MenuIconProps>`
  height: 15px;
  position: relative;
  transition: all 1s ease-in;
  width: 20px;

  span {
    background-color: #000;
    display: block;
    height: 2px;
  }

  ${TopStroke} {
    ${({ isOpen }) => isOpen && animateTopCss}
  }
  ${MiddleStroke} {
    ${({ isOpen }) => isOpen && "display: none;"}
  }
  ${BottomStroke} {
    ${({ isOpen }) => isOpen && animateBottomCss}
  }
`;

export const MenuIconButton = styled.button`
  background-color: transparent;
  border: none;
  margin-right: 0.8em;
  padding-left: 0;
  padding-right: 0;
`;

export default MenuIcon;
