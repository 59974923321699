import React, { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { ConsentGate, ConfirmicProvider } from "@confirmic/react";
import theme from "./theme/theme";
import i18next from "./i18n";
import {
  ActiveWalletProvider,
  SettingsProvider,
  TransactionProvider,
  ApiDataProvider,
  AccountProvider,
  ModalProvider,
} from "./context";
import {
  Body,
  Heading1,
  Typo1,
  Typo2,
  Column,
  Scrollbar,
  Spacer,
  InstallBanner,
} from "./components";
import { withFractalAuth } from "./hocs";
import { useDetectResolutionType } from "./hooks";
import {
  backgroundImg,
  backgroundImgDots,
  backgroundMobileImg,
  macbookFSImg,
} from "./assets";
import Loading from "./containers/Loading";
import TopBar from "./containers/TopBar";
import Footer from "./containers/Footer";
import Staking from "./containers/Staking/Staking";
const StakingOverview = lazy(() =>
  import("./containers/Staking").then((module) => ({ default: module.default }))
);
const Store = lazy(() =>
  import("./containers/Store").then((module) => ({ default: module.default }))
);
const Faucet = lazy(() =>
  import("./containers/Faucet").then((module) => ({ default: module.default }))
);
const Projects = lazy(() =>
  import("./containers/Projects").then((module) => ({
    default: module.default,
  }))
);
const Terms = lazy(() =>
  import("./containers/Terms").then((module) => ({ default: module.default }))
);
const Rewards = lazy(() =>
  import("./containers/Rewards").then((module) => ({ default: module.default }))
);
const Project = lazy(() =>
  import("./containers/Project/ICOProject").then((module) => ({
    default: module.default,
  }))
);
const LiquidityMining = lazy(() =>
  import("./containers/LiquidityMining").then((module) => ({
    default: module.default,
  }))
);
const NFTProject = lazy(() =>
  import("./containers/Project/NFTProject").then((module) => ({
    default: module.default,
  }))
);

const AuthProjects = withFractalAuth(Projects);

function App() {
  const resolutionType = useDetectResolutionType();
  const location = useLocation();

  return (
    <Providers>
      <Body>
        {(resolutionType === "mobile" || resolutionType === "tablet") &&
        !location.pathname.startsWith("/projects") &&
        !location.pathname.startsWith("/nft-projects") &&
        !location.pathname.startsWith("/staking") &&
        !location.pathname.startsWith("/earn") &&
        !location.pathname.startsWith("/store") &&
        !location.pathname.startsWith("/faucet") &&
        location.pathname !== "/" ? (
          <Column
            style={{
              width: "100%",
              alignItems: "center",
              backgroundImage: `url(${backgroundMobileImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center -50px",
            }}
          >
            <TopBar />
            <Spacer size="xxl" />
            <Column style={{ width: "90%", alignItems: "center" }}>
              <Heading1>Invest or raise capital on any blockchain</Heading1>
              <Spacer />
              <Typo1>
                Unfortunately we do not support mobile yet. Please visit
                Fantomstarter.io on your desktop or follow us on Twitter to stay
                up to date.{" "}
              </Typo1>
              <Spacer />
              <Typo2
                style={{
                  width: "100%",
                  fontWeight: "bolder",
                  color: "#765cde",
                  letterSpacing: 1.4,
                  cursor: "pointer",
                }}
                onClick={() => window.open("https://twitter.com/fantomstarter")}
              >
                {"Follow us on twitter >"}
              </Typo2>
              <Spacer size="xs" />
              <img
                alt="fantom-starter"
                style={{ width: "110%" }}
                src={macbookFSImg}
              />
              <Spacer />
            </Column>
          </Column>
        ) : (
          <Column style={{ backgroundColor: "#fff", width: "100%" }}>
            <TopBar />
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Scrollbar
                id="scroll-bar"
                style={{ height: "100%", width: "100%" }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    minHeight: "100%",
                    backgroundImage: "linear-gradient(#F8F9FA, #F8F9FA)",
                    backgroundPosition:
                      (location.pathname === "/staking" ||
                        location.pathname === "/projects" ||
                        location.pathname === "/earn" ||
                        location.pathname === "/rewards") &&
                      "center",
                    backgroundSize:
                      (location.pathname === "/staking" ||
                        location.pathname === "/projects" ||
                        location.pathname === "/rewards" ||
                        location.pathname === "/earn" ||
                        location.pathname === "/store") &&
                      "cover",
                  }}
                >
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        component={() => (
                          <Redirect
                            to={
                              process.env.REACT_APP_FS_HOME_REDIRECT
                                ? process.env.REACT_APP_FS_HOME_REDIRECT
                                : "/projects"
                            }
                          />
                        )}
                      />
                      <Route exact path="/terms" component={Terms} />
                      {process.env.REACT_APP_FS_FAUCET !== "off" && (
                        <Route path="/faucet" component={Faucet} />
                      )}
                      {/* {process.env.REACT_APP_FS_REWARDS !== "off" && (
                        <Route path="/rewards" component={Rewards} />
                      )} */}
                      {process.env.REACT_APP_FS_EARN !== "off" && (
                        <Route exact path="/earn" component={LiquidityMining} />
                      )}
                      {process.env.REACT_APP_FS_EARN !== "off" && (
                        <Route
                          path="/earn/:chainId"
                          component={LiquidityMining}
                        />
                      )}
                      {process.env.REACT_APP_FS_PROJECTS !== "off" && (
                        <Route exact path="/projects" component={Projects} />
                      )}
                      <Route
                        exact
                        path="/projects/oauth/callback"
                        component={AuthProjects}
                      />
                      <Route path="/projects/:id" component={Project} />
                      <Route path="/nft-projects/:id" component={NFTProject} />
                      {process.env.REACT_APP_FS_STAKE !== "off" && (
                        <>
                          <Route
                            exact
                            path="/staking"
                            component={StakingOverview}
                          />
                          <Route path="/staking/:chainId" component={Staking} />
                          <Route exact path="/store" component={Store} />
                          <Route path="/store/:chainId" component={Store} />
                        </>
                      )}
                      {/*{process.env.REACT_APP_FS_STORE !== "off" && (
                        <>
                          <Route exact path="/store" component={Store} />
                          <Route path="/store/:chainId" component={Store} />
                        </>
                      )}*/}
                    </Switch>
                  </Suspense>
                </div>
                <Footer />
              </Scrollbar>
            </div>
          </Column>
        )}
      </Body>
      {!(resolutionType === "mobile" || resolutionType === "tablet") && (
        <InstallBanner />
      )}
      <ConsentGate micropolicy="google-analytics">
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-Q9NN1955X0"
        ></script>
        <script>
          {/*
            // @ts-ignore */}
          window.dataLayer = window.dataLayer || [];
          {/*
            // @ts-ignore */}
          function gtag(){window.dataLayer?.push(arguments)}
          gtag('js', new Date()); gtag('config', 'G-Q9NN1955X0');
        </script>
      </ConsentGate>
    </Providers>
  );
}

const Providers: React.FC<any> = ({ children }) => {
  return (
    <ConfirmicProvider
      projectId="prj:012c51bc-c1a7-4d8f-b981-6228cb980588"
      debug={true}
    >
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={theme}>
          <AccountProvider>
            <ApiDataProvider>
              <SettingsProvider>
                <ActiveWalletProvider>
                  <TransactionProvider>
                    <ModalProvider>{children}</ModalProvider>
                  </TransactionProvider>
                </ActiveWalletProvider>
              </SettingsProvider>
            </ApiDataProvider>
          </AccountProvider>
        </ThemeProvider>
      </I18nextProvider>
    </ConfirmicProvider>
  );
};

export default App;
