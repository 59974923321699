import { MaxUint256 } from "@ethersproject/constants";
import { useTransaction, useWalletProvider } from "./index";
import { send, loadERC20Contract } from "../utils";
import { loadProvider } from "../utils/wallet";
import config from "../config/config";

const useERC20Method = () => {
  const { walletContext } = useWalletProvider();
  const { dispatchTx } = useTransaction();

  const approve = async (
    contractAddress: string,
    approveAddress: string,
    amount?: string
  ) => {
    if (!walletContext.activeWallet.signer) {
      console.error("[sendTransation] signer not found");
      return;
    }
    if (parseFloat(amount) <= 0) {
      console.error("[sendTransation] amount <= 0");
      return;
    }

    let contract = await loadERC20Contract(
      contractAddress,
      walletContext.activeWallet.provider
    );

    if (walletContext.activeWallet.provider.connection.url === "eip-1193:") {
      console.log("[eip-1193] connecting signer to ERC20 contract!");
      contract = contract.connect(walletContext.activeWallet.signer);
    }

    return send(
      walletContext.activeWallet.provider,
      () => contract.approve(approveAddress, amount || MaxUint256),
      dispatchTx
    );
  };

  const allowance = async (
    contractAddress: string,
    approvedAddress: string,
    chainId: number = config.defaultChainId
  ) => {
    const contract = await loadERC20Contract(
      contractAddress,
      await loadProvider(walletContext, chainId)
    );

    return contract.allowance(
      walletContext.activeWallet.address,
      approvedAddress
    );
  };

  const balanceOf = async (
    contractAddress: string,
    chainId: number = config.defaultChainId
  ) => {
    const contract = await loadERC20Contract(
      contractAddress,
      await loadProvider(walletContext, chainId)
    );

    return contract.balanceOf(walletContext.activeWallet.address);
  };

  const getDecimals = async (
    contractAddress: string,
    chainId: number = config.defaultChainId
  ) => {
    const contract = await loadERC20Contract(
      contractAddress,
      await loadProvider(walletContext, chainId)
    );

    return contract.decimals();
  };

  const sendTokens = async (
    contractAddress: string,
    toAddress: string,
    amount: string
  ) => {
    if (!walletContext.activeWallet.signer) {
      console.error("[sendTransation] signer not found");
      return;
    }
    if (parseFloat(amount) <= 0) {
      console.error("[sendTransation] amount <= 0");
      return;
    }

    const contract = await loadERC20Contract(
      contractAddress,
      walletContext.activeWallet.provider
    );

    return send(
      walletContext.activeWallet.provider,
      () => contract.transfer(toAddress, amount),
      dispatchTx
    );
  };

  const estimateGas = async (
    contractAddress: string,
    method: string,
    args: string[]
  ) => {
    const contract = await loadERC20Contract(
      contractAddress,
      walletContext.activeWallet.provider
    );

    return contract.estimateGas[method](...args);
  };

  const getSymbol = async (
    contractAddress: string,
    chainId: number = config.defaultChainId
  ) => {
    const contract = await loadERC20Contract(
      contractAddress,
      await loadProvider(walletContext, chainId)
    );

    return contract.symbol();
  };

  const getName = async (
    contractAddress: string,
    chainId: number = config.defaultChainId
  ) => {
    const contract = await loadERC20Contract(
      contractAddress,
      await loadProvider(walletContext, chainId)
    );

    return contract.name();
  };

  return {
    approve: async (
      contractAddress: string,
      approveAddress: string,
      amount?: string
    ) => approve(contractAddress, approveAddress, amount),
    getAllowance: async (
      contractAddress: string,
      approveAddress: string,
      chainId?: number
    ) => allowance(contractAddress, approveAddress, chainId),
    balanceOf,
    sendTokens: async (
      contractAddress: string,
      toAddress: string,
      amount: string
    ) => await sendTokens(contractAddress, toAddress, amount),
    estimateGas: async (
      contractAddress: string,
      method: string,
      args: string[]
    ) => {
      return estimateGas(contractAddress, method, args);
    },
    getDecimals,
    getSymbol,
    getName,
  };
};

export default useERC20Method;
