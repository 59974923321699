import React from "react";
import { Row, Spacer } from "../index";
import { formatAddress } from "../../utils";
import { arrowDown } from "../../assets";

const WalletSelectView: React.FC<any> = ({ wallet }) => {
  return (
    <Row style={{ alignItems: "center" }} key={wallet.address}>
      <div style={{ color: "white", fontSize: "12px" }}>
        {formatAddress(wallet.address)}
      </div>
      <Spacer size="xs" />
      <img style={{ alignSelf: "center" }} src={arrowDown} alt="" />
    </Row>
  );
};

export default WalletSelectView;
