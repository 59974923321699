import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// @ts-ignore
import { addresses } from "@fs/contracts";
import NavMenu from "../NavMenu";
import {
  animateBottom,
  animateTop,
  Heading5,
  InstallBanner,
  Row,
  ShowForBreakpoint,
  Spacer,
  Typo2,
  WalletSelector,
  Banner,
} from "../../components";
import {
  // useSettings,
  useWalletProvider,
  useERC20Method,
  useTransaction,
} from "../../hooks";
import { useDetectResolutionType, useOutsideClick } from "../../hooks";
import { toFormattedBalance, toFormattedValue, weiToUnit } from "../../utils";
import {
  fantomStarterLogo,
  fantomStarterLogoWithTextImg,
  MenuIcon,
  MenuIconButton,
} from "../../assets";
import config from "../../config/config";
import { chainToNetworkNameMap } from "../../utils/wallet";
import { initializeFirebase, getRegistrationToken } from "../../firebase";

const NetworkDisplay: React.FC<any> = ({ chainId, network }) => {
  const isTestnet = () => {
    return [
      4002,
      80001,
      97,
      process.env.REACT_APP_FS_ETHEREUM !== "off" ? 5 : null,
      process.env.REACT_APP_FS_ARBITRUM !== "off" ? 421611 : null,
      process.env.REACT_APP_FS_OPTIMISM !== "off" ? 420 : null,
      33,
      process.env.REACT_APP_FS_AVALANCHE !== "off" ? 43113 : null,
      process.env.REACT_APP_FS_SONIC !== "off" ? 64240 : null,
    ].includes(chainId);
  };
  const getDisplayName = () => {
    if (chainToNetworkNameMap[chainId]) {
      const displayNames = {
        250: "Fantom",
        4002: "Fantom",
        137: "Polygon",
        80001: "Polygon",
        56: "BNB",
        97: "BNB",
        1:
          process.env.REACT_APP_FS_ETHEREUM !== "off"
            ? "Ethereum"
            : "Unsupported",
        5:
          process.env.REACT_APP_FS_ETHEREUM !== "off"
            ? "Goerli"
            : "Unsupported",
        42161:
          process.env.REACT_APP_FS_ARBITRUM !== "off"
            ? "Arbitrum"
            : "Unsupported",
        421611:
          process.env.REACT_APP_FS_ARBITRUM !== "off"
            ? "Arbitrum"
            : "Unsupported",
        10:
          process.env.REACT_APP_FS_OPTIMISM !== "off"
            ? "Optimism"
            : "Unsupported",
        420:
          process.env.REACT_APP_FS_OPTIMISM !== "off"
            ? "Optimism"
            : "Unsupported",
        43114:
          process.env.REACT_APP_FS_AVALANCHE !== "off"
            ? "Avalanche"
            : "Unsupported",
        43113:
          process.env.REACT_APP_FS_AVALANCHE !== "off"
            ? "Avalanche"
            : "Unsupported",
        64240:
          process.env.REACT_APP_FS_SONIC !== "off" ? "Sonic" : "Unsupported",
      } as any;
      return displayNames[chainId];
    }
    if (network) {
      return "Solana";
    }
    if (chainId === config.aptosChainId) {
      return "Aptos";
    }
    return "Unsupported";
  };

  return (
    <Row
      style={{
        borderRadius: "18px",
        border: "1px solid grey",
        height: "30px",
        width: "80px",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0.6,
        cursor: "not-allowed",
      }}
    >
      <Typo2
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          fontFamily: "inter",
          color: isTestnet() ? "orange" : "black",
        }}
      >
        {getDisplayName()}
      </Typo2>
    </Row>
  );
};

const TopBar: React.FC = () => {
  const { walletContext } = useWalletProvider();
  // const { balanceOf } = useERC20Method();
  // const { transaction } = useTransaction();
  // const [balance, setBalance] = useState(0);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const resolutionType = useDetectResolutionType();

  const ref = useRef(null);
  useOutsideClick(ref, () => setMenuIsOpen(false));

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "fs-dapp.firebaseapp.com",
    projectId: "fs-dapp",
    storageBucket: "fs-dapp.appspot.com",
    messagingSenderId: "493395891751",
    appId: "1:493395891751:web:ac7f4f5a977592ee666002",
    measurementId: "G-ZEM5FBX2XL",
  };
  const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;
  const [firebaseToken, setFirebaseToken] = useState("");

  useEffect(() => {
    if (
      walletContext.activeWallet.address &&
      process.env.REACT_APP_FS_FIREBASE !== "off"
    ) {
      const firebase = initializeFirebase(firebaseConfig);
      getRegistrationToken(firebase.messaging, vapidKey, setFirebaseToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletContext.activeWallet.address]);

  // useEffect(() => {
  //   if (
  //     walletContext.activeWallet.address &&
  //     config.supportedChains.includes(walletContext.activeWallet.chainId)
  //   ) {
  //     balanceOf(
  //       addresses[walletContext.activeWallet.chainId].tokens.FS,
  //       walletContext.activeWallet.chainId
  //     ).then((balance) => {
  //       if (balance) {
  //         setBalance(weiToUnit(balance));
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   transaction,
  //   balance,
  //   walletContext.activeWallet.address,
  //   walletContext.activeWallet.chainId,
  //   walletContext.web3ProviderState.chainSelected,
  // ]);
  // const { settings, dispatchSettings } = useSettings();
  // const { t, i18n } = useTranslation("common");
  return (
    <header>
      {(resolutionType === "mobile" || resolutionType === "tablet") && (
        <InstallBanner />
      )}
      <Header ref={ref}>
        <HeaderInner>
          <Row align="center" style={{ zIndex: 10 }}>
            {(resolutionType === "mobile" || resolutionType === "tablet") && (
              <MenuIconButton onClick={() => setMenuIsOpen(!menuIsOpen)}>
                <MenuIcon isOpen={menuIsOpen} />
              </MenuIconButton>
            )}
            <Link to="/" onClick={() => setMenuIsOpen(false)}>
              <img
                height="32"
                alt="FantomStarter"
                src={
                  resolutionType === "mobile" ||
                  resolutionType === "tablet" ||
                  resolutionType === "desktop"
                    ? fantomStarterLogo
                    : fantomStarterLogoWithTextImg
                }
              />
            </Link>
          </Row>
          <Navigation isOpen={menuIsOpen}>
            <NavMenu linkCallback={() => setMenuIsOpen(false)} />
          </Navigation>
          <Row align="center">
            {/*{walletContext.activeWallet.address && (*/}
            {/*  <ShowForBreakpoint size="ultra">*/}
            {/*    <Heading5>*/}
            {/*      Balance:{" "}*/}
            {/*      {toFormattedValue(toFormattedBalance(balance), "FS", "", true)}*/}
            {/*    </Heading5>*/}
            {/*  </ShowForBreakpoint>*/}
            {/*)}*/}
            <Spacer />
            <WalletSelector walletContext={walletContext} />
            {walletContext.activeWallet.address && (
              <>
                <Spacer size="xs" />
                <NetworkDisplay
                  chainId={walletContext.activeWallet.chainId}
                  network={walletContext.activeWallet.network}
                />
              </>
            )}
          </Row>
        </HeaderInner>
      </Header>
      {/* <Banner
        title="Big things are on the horizon!"
        message="Follow us on Twitter and turn on notifications so you don't miss out!"
        buttonText="Follow"
        handleButtonClick={() =>
          window.open("https://twitter.com/fantomstarter")
        }
      /> */}
    </header>
  );
};

const Header = styled.header`
  align-items: center;
  background-color: ${(props) => props.theme.color.white};
  box-shadow: 0 1px 1px 0 #e2e2e2;
  color: ${(props) => props.theme.fontColor.primary};
  display: flex;
  flex-direction: row;
  height: ${(props) => props.theme.topBarSize}px;
  justify-content: space-between;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 10;
`;

const HeaderInner = styled(Row).attrs({
  align: "center",
  justify: "space-between",
})`
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 2em);
`;

const animateBottomCss = css`
  animation: ${animateBottom} 0.3s ease-out 0s 1 normal forwards;
`;

const animateTopCss = css`
  animation: ${animateTop} 0.2s linear 0s 1 normal forwards;
`;

const Navigation = styled.nav<{ isOpen: boolean }>`
  @media (max-width: 768px) {
    background-color: #fff;
    // box-shadow: 0px 5px 10px 0 #e2e2e2;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    left: 0;
    padding-bottom: 5vw;
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    right: 0;
    top: ${(props) => props.isOpen && "100%"};
    transform: translateY(calc(-100% - 60px));

    ${({ isOpen }) => (isOpen ? animateBottomCss : animateTopCss)}
  }
`;

export default TopBar;
