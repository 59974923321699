import React from "react";
import {
  copyIcon,
  discordIcon,
  facebookIcon,
  mediumIcon,
  telegramIcon,
  twitterIcon,
  webIcon,
} from "../../assets";
import { copyText } from "../../utils";

const SocialLinks: React.FC<{
  socialLinks: { [key: string]: string };
}> = ({ socialLinks }) => {
  return (
    <>
      {socialLinks &&
        Object.keys(socialLinks).map((index, key) => {
          let icon;
          switch (index) {
            case "telegram":
              icon = telegramIcon;
              break;
            case "medium":
              icon = mediumIcon;
              break;
            case "discord":
              icon = discordIcon;
              break;
            case "facebook":
              icon = facebookIcon;
              break;
            case "twitter":
              icon = twitterIcon;
              break;
            case "website":
            default:
              icon = webIcon;
              break;
          }
          return (
            <a
              key={key}
              href={socialLinks[index]}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={icon} alt={index} />
            </a>
          );
        })}
      <img
        src={copyIcon}
        alt="copy project title"
        style={{ cursor: "pointer" }}
        onClick={() =>
          copyText(document.location.origin + document.location.pathname)
        }
      />
    </>
  );
};

export default SocialLinks;
