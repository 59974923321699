import styled from "styled-components";

const Heading = styled.h1`
  color: ${(props) =>
    props.color ? props.color : props.theme.fontColor.black};
  font-family: "JekoVariable", sans-serif;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0;
`;

export const Heading1 = styled((props: any) => <Heading as="h1" {...props} />)`
  font-size: clamp(2.5rem, 10vw, 3rem);
`;

export const Heading2 = styled((props: any) => <Heading as="h2" {...props} />)`
  font-size: 2.125rem;
`;

export const Heading3 = styled((props: any) => <Heading as="h3" {...props} />)`
  font-size: 1.5rem;
`;

export const Heading4 = styled((props: any) => <Heading as="h4" {...props} />)`
  font-size: 1.125rem;
`;

export const Heading5 = styled((props: any) => <Heading as="h5" {...props} />)`
  font-size: 0.875rem;
`;
