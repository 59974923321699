import { useContext, useEffect } from "react";
import { useApiData, FANTOM_STARTER_API_CALL } from "./index";
import { AccountContext } from "../context";

const useAccount = () => {
  const [account, dispatchAccount] = useContext(AccountContext);
  const { apiData } = useApiData();

  const loginData = apiData[FANTOM_STARTER_API_CALL.LOGIN];
  const walletData = apiData[FANTOM_STARTER_API_CALL.GET_WALLET];
  const sessionData = apiData[FANTOM_STARTER_API_CALL.GET_ME];

  useEffect(() => {
    if (loginData?.state === "success") {
      if (!loginData?.response?.data?.sessionID) {
        console.info("Failed to fetch sessionID");
        console.info("ERROR: ", {
          code: loginData?.response?.data?.code,
          message: loginData?.response?.data?.message,
        });

        if (loginData?.response?.data?.code === "onGetOrCreateAccount") {
          return dispatchAccount({
            type: "loginError",
            message: "Failed to create account, please contact support",
          });
        }

        return dispatchAccount({
          type: "loginError",
          message: "Failed to login, please try again later",
        });
      }

      dispatchAccount({
        type: "loginFantomStarter",
        sessionID: loginData.response.data.sessionID,
        sessionAccountVerification:
          loginData.response.data.fractalAccountVerification,
        fractalWalletAddress: loginData.response.data.fractalWalletAddress,
        fractalEmail: loginData.response.data.fractalEmail,
        fractalPhone: loginData.response.data.fractalPhone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);

  useEffect(() => {
    if (walletData?.state === "success") {
      if (!walletData?.response?.data?.user?.result) {
        console.info("Failed to retrieve account wallet");
        return;
      }
      dispatchAccount({
        type: "fetchAccountWallet",
        accountWalletAddress:
          walletData.response.data.user.result.walletAddress,
        sessionIsActive: walletData.response.data.user.result.sessionIsActive,
        sessionIsValid: walletData.response.data.user.result.sessionIsValid,
        sessionAccountVerification:
          walletData.response.data.user.result.fractalAccountVerification,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletData]);

  useEffect(() => {
    if (sessionData?.state === "success") {
      if (!sessionData?.response?.data?.user?.result) {
        console.info("Failed to retrieve session status");
        return;
      }

      dispatchAccount({
        type: "updateFractalSession",
        accountWalletAddress:
          sessionData.response.data.user.result.walletAddress,
        sessionIsActive: sessionData.response.data.user.result.sessionIsActive,
        sessionIsValid: sessionData.response.data.user.result.sessionIsValid,
        sessionAccountVerification:
          sessionData.response.data.user.result.fractalAccountVerification,
        fractalWalletAddress:
          sessionData.response.data.user.result.fractalWalletAddress,
        fractalEmail: sessionData.response.data.user.result.fractalEmail,
        fractalPhone: sessionData.response.data.user.result.fractalPhone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData]);

  useEffect(() => {
    if (account.sessionIsActive === false || account.sessionIsValid === false) {
      console.info("[Invalid session] remove session");
      dispatchAccount({
        type: "resetFractalSession",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.sessionIsValid, account.sessionIsActive, account.sessionID]);

  return { account, dispatchAccount };
};

export default useAccount;
