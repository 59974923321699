interface ITIER {
  factor: number;
  name: string;
  tokens: number;
  card: [number];
  tickets: number;
}
// process.env.REACT_APP_USE = "testnet"
export const TIERS: ITIER[] = [
  { factor: 0, name: "No tier", tokens: 0, card: [0], tickets: 0 },
  { factor: 0.5, name: "Orb", tokens: 7500, card: [0.5], tickets: 1 },
  { factor: 0.75, name: "Ectoplasm", tokens: 11250, card: [0.75], tickets: 1 },
  { factor: 1, name: "Minion", tokens: 15000, card: [1], tickets: 1 },
  { factor: 2, name: "Imp", tokens: 29000, card: [2], tickets: 2 },
  { factor: 3, name: "Oni", tokens: 56000, card: [3], tickets: 4 },
  { factor: 4, name: "Banshee", tokens: 110000, card: [4], tickets: 8 },
  { factor: 5, name: "Wraith", tokens: 455000, card: [5], tickets: 100 },
  { factor: 6, name: "Kappa", tokens: 825000, card: [6], tickets: 100 },
  { factor: 7, name: "King", tokens: 2250000, card: [7], tickets: 100 },
];

const tiers = {
  0: { name: "No tier", tier: 0 },
  7500: { name: "Orb", tier: 0.5 },
  11250: { name: "Ectoplasm", tier: 0.75 },
  15000: { name: "Minion", tier: 1 },
  29000: { name: "Imp", tier: 2 },
  56000: { name: "Oni", tier: 3 },
  110000: { name: "Banshee", tier: 4 },
  455000: { name: "Wraith", tier: 5 },
  825000: { name: "Kappa", tier: 6 },
  2250000: { name: "King", tier: 7 },
} as any;

export const mapContractTierToFSTier = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 0.5,
  9: 0.75,
} as any;

const MAX_TIER = TIERS.length;

export const getTierSelected = (amountTokens: number) => {
  const keys = Object.keys(tiers);

  const tierKey = keys.find((key: string, index: number) => {
    if (index >= MAX_TIER) {
      return true;
    }
    return (
      amountTokens >= parseInt(key) && amountTokens < parseInt(keys[index + 1])
    );
  });
  return tiers[tierKey];
};

export const getTierName = (tier: number) => {
  if (tier === 0) {
    return "No tier";
  }
  if (
    tier > Math.max(...Object.keys(TIERS).map((tier: string) => parseInt(tier)))
  ) {
    return;
  }
  const findTier = getTierByKey("factor", tier);
  return findTier?.name || "Unknown";
};

export const getTierByKey = (key: string, value: any) => {
  const index = TIERS.findIndex((el: any) => el[key] === value);
  if (TIERS[index] !== undefined) {
    return TIERS[index];
  } else {
    return undefined;
  }
};

export const getTierIndexByTokens = (tokens: string) => {
  const tierIndex = TIERS.findIndex((el: any, key) => {
    if (
      parseInt(tokens) >= el.tokens &&
      parseInt(tokens) < TIERS[key + 1]?.tokens
    ) {
      return true;
    } else if (parseInt(tokens) >= el.tokens && key + 1 === TIERS.length) {
      return true;
    } else {
      return false;
    }
  });

  return tierIndex;
};
