import { useModal, useWalletProvider } from "./index";
import WrongNetworkModal from "../components/WrongNetworkModal";
import WrongWalletModal from "../components/WrongWalletModal";
import React, { useEffect, useState } from "react";

const useCorrectChain = (chainId: number) => {
  const { walletContext } = useWalletProvider();
  const [toWallet, setToWallet] = useState("metamask");

  const [onPresentWrongChainModal, onDismissWrongChainModal] = useModal(
    <WrongNetworkModal toChain={chainId} />,
    "wrong-network-modal"
  );

  const [onPresentWrongWalletModal] = useModal(
    <WrongWalletModal toWallet={toWallet} />,
    "wrong-wallet-modal"
  );

  const promptChainChangeIfNeeded = (
    callback: any,
    walletNeeded: string = "metamask"
  ) => {
    setToWallet(walletNeeded);
    if (
      walletNeeded === walletContext.activeWallet.providerType &&
      chainId === walletContext.activeWallet.chainId
    ) {
      return callback();
    }

    if (walletNeeded !== walletContext.activeWallet.providerType) {
      return onPresentWrongWalletModal();
    }

    return onPresentWrongChainModal();
  };

  useEffect(() => {
    if (chainId === walletContext.activeWallet.chainId) {
      onDismissWrongChainModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, walletContext.activeWallet.chainId]);

  return {
    promptChainChangeIfNeeded,
  };
};

export default useCorrectChain;
