import React from "react";
import styled from "styled-components";
import { slideUp } from "../../components";
import ModalClose from "../ModalClose";

export type ModalStyling = "default" | "full";

export interface ModalProps {
  onDismiss?: () => void;
}

const Modal: React.FC<any> = ({ children, style, onDismiss, styling }) => {
  return (
    <StyledResponsiveWrapper styling={styling}>
      <StyledModal
        padding={style?.padding && style.padding}
        border={style?.border && style.border}
        borderRadius={style?.borderRadius && style.borderRadius}
        styling={styling}
      >
        {onDismiss && <ModalClose onDismiss={onDismiss} />}
        <StyledModalInner>{children}</StyledModalInner>
      </StyledModal>
    </StyledResponsiveWrapper>
  );
};

const StyledResponsiveWrapper = styled.div<{ styling?: ModalStyling }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  ${({ styling }) =>
    styling === "full"
      ? `
    width: min(1040px, calc(100vw - 2em));
  `
      : `
    text-align: center;
    width: min(calc(100vw - 2em), 800px);
  `}
`;

const StyledModal = styled.div<{
  styling?: ModalStyling;
  padding?: any;
  border?: any;
  borderRadius?: any;
}>`
  animation: ${slideUp} 0.3s forwards ease-out;
  color: ${(props) =>
    props.styling === "full"
      ? props.theme.fontColor.third
      : props.theme.fontColor.secondary};
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding: ${(props) =>
    props.padding === undefined ? "20px 40px" : props.padding};
  position: relative;
  z-index: 1;

  backdrop-filter: blur(40px);
  background: ${(props) => props.theme.color.white};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "32px"};
  border: ${(props) => (props.border === undefined ? "none" : props.border)};
  box-shadow: ${(props) =>
    props.border === undefined ? "0 0 3px 1px #e2e2e2" : "none"};
  top: 50%;

  ${(props) =>
    props.styling === "full"
      ? `
    align-items: flex-start;
  `
      : `
    align-items: center;
  `}
`;

const StyledModalInner = styled.div`
  max-height: calc(100vh - 12em);
  overflow-y: scroll;
`;

export default Modal;
