import React from "react";

const CheckMark: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      style={{ verticalAlign: "middle" }}
    >
      <rect
        width="24"
        height="24"
        fill={color ? color : "#27B44F"}
        rx="12"
      ></rect>
      <path
        fill="#EFF3FB"
        d="M10.076 16.773l-3.9-3.9a.6.6 0 010-.849l.848-.848a.6.6 0 01.849 0l2.627 2.627 5.627-5.627a.6.6 0 01.849 0l.848.848a.6.6 0 010 .849l-6.9 6.9a.6.6 0 01-.848 0z"
      ></path>
    </svg>
  );
};

export default CheckMark;
