import { Contract } from "@ethersproject/contracts";
import { useWalletProvider, useTransaction } from "./index";
import { send } from "../utils";
import useLoadFSContract from "./useLoadFSContract";

const CONTRACT_NAME = "tierNFTStore";
export enum TIER_NFT_STORE_CALL_METHODS {
  NFT_PRICE = "nftPrice",
}
export enum TIER_NFT_STORE_TX_METHODS {
  BUY_TIER_NFT = "buyTierNFT",
}

const tierNFTStoreContractCall: {
  [key in TIER_NFT_STORE_CALL_METHODS]: any;
} = {
  [TIER_NFT_STORE_CALL_METHODS.NFT_PRICE]: async (
    contract: Contract,
    cardId: number
  ) => {
    return contract.nftPrice(cardId);
  },
} as any;
const tierNFTStoreContractTx: {
  [key in TIER_NFT_STORE_TX_METHODS]: any;
} = {
  [TIER_NFT_STORE_TX_METHODS.BUY_TIER_NFT]: async (
    contract: Contract,
    nftId: number
  ) => {
    return contract.buyTierNFT(nftId);
  },
};

const useTierNFTStore = (chainId: number) => {
  const { walletContext } = useWalletProvider();
  const { dispatchTx } = useTransaction();
  const { getLoadedContract, withSigner, isLoadedOnChain } = useLoadFSContract(
    CONTRACT_NAME,
    chainId
  );

  const callTierNFTStoreMethod = async (
    method: TIER_NFT_STORE_CALL_METHODS,
    args: any
  ) => {
    const loadedContract = await getLoadedContract();
    if (!loadedContract) {
      return;
    }

    if (!tierNFTStoreContractCall[method]) {
      console.error(`[callTierNFTStoreMethod] method: ${method} not found`);
      return;
    }

    return tierNFTStoreContractCall[method as TIER_NFT_STORE_CALL_METHODS](
      loadedContract,
      ...args
    );
  };

  const txTierNFTStoreMethod = async (
    method: TIER_NFT_STORE_TX_METHODS,
    args: any
  ) => {
    if (!withSigner) {
      console.error(`[txTierNFTStoreMethod] contract loaded without signer`);
      return;
    }

    let loadedContract = await getLoadedContract();
    if (!loadedContract) {
      return;
    }

    if (walletContext.activeWallet.provider.connection.url === "eip-1193:") {
      console.log("[eip-1193] connecting signer to TierNFTStore contract!");
      loadedContract = loadedContract.connect(
        walletContext.activeWallet.signer
      );
    }

    const sendTx = (callback: any) => {
      try {
        return send(walletContext.activeWallet.provider, callback, dispatchTx);
      } catch (err) {
        throw err;
      }
    };

    if (!tierNFTStoreContractTx[method]) {
      console.error(`[txTierNFTStoreMethod] method: ${method} not found`);
    }

    return sendTx(() =>
      tierNFTStoreContractTx[method](loadedContract, ...args)
    );
  };

  return {
    callTierNFTStoreMethod: async (
      method: TIER_NFT_STORE_CALL_METHODS,
      args: any[]
    ) => callTierNFTStoreMethod(method, args),
    txTierNFTStoreMethod: async (
      method: TIER_NFT_STORE_TX_METHODS,
      args: any[]
    ) => txTierNFTStoreMethod(method, args),
    contractIsLoaded: isLoadedOnChain,
  };
};

export default useTierNFTStore;
