import React, { useEffect, useState } from "react";
import { OverlayButton, Button } from "../index";
import { CrossSymbol } from "../../assets";
import { Heading4, Column, Typo3, Heading5 } from "../../components";
import styled from "styled-components";
import {
  useDetectResolutionType,
  useModal,
  useWalletProvider,
} from "../../hooks";
import InstructionModal from "../InstructionModal";

const InstallBanner: React.FC<any> = () => {
  const { walletContext } = useWalletProvider();
  const resolutionType = useDetectResolutionType();

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [show, setShow] = useState(false);

  const [onPresentApproveStakeModal] = useModal(
    <InstructionModal onClose={() => setShow(false)} />,
    "iOS-instruction-modal"
  );

  const getPWADisplayMode = () => {
    const isStandalone = window.matchMedia("(display-mode: standalone)")
      .matches;
    if (document.referrer.startsWith("android-app://")) {
      return "twa";
    } else if (isStandalone) {
      return "standalone";
    }
    return "browser";
  };

  const checkForIOSSafari = () => {
    const ua = window.navigator.userAgent;
    const webkit = !!ua.match(/WebKit/i);
    const isIPad = !!ua.match(/iPad/i);
    const isIPhone = !!ua.match(/iPhone/i);
    const isIOS = isIPad || isIPhone;
    const isIOSSafari = isIOS && webkit && !ua.match(/CriOS/i);

    return isIOSSafari;
  };

  const handleClose = () => {
    setShow(false);
    if (walletContext.activeWallet.address) {
      localStorage.setItem("disabledWithAddress", "1");
    } else {
      localStorage.setItem("disabled", "1");
    }
  };

  const handleInstall = () => {
    if (checkForIOSSafari()) {
      onPresentApproveStakeModal();
    } else {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
          setShow(false);
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    if (
      process.env.REACT_APP_FS_SERVICE_WORKER !== "off" &&
      getPWADisplayMode() === "browser"
    ) {
      if (
        checkForIOSSafari() &&
        localStorage.getItem("disabled") !== "1" &&
        localStorage.getItem("disabledWithAddress") !== "1"
      ) {
        setShow(true);
      }
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
        if (
          localStorage.getItem("disabled") !== "1" &&
          localStorage.getItem("disabledWithAddress") !== "1"
        ) {
          setShow(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      process.env.REACT_APP_FS_SERVICE_WORKER !== "off" &&
      walletContext.activeWallet.address &&
      getPWADisplayMode() === "browser"
    ) {
      if (
        localStorage.getItem("disabledWithAddress") !== "1" &&
        (checkForIOSSafari() || deferredPrompt)
      ) {
        setShow(true);
      }
      if (!deferredPrompt) {
        window.addEventListener("beforeinstallprompt", (e) => {
          e.preventDefault();
          setDeferredPrompt(e);
          if (localStorage.getItem("disabledWithAddress") !== "1") {
            setShow(true);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletContext.activeWallet.address]);

  return (
    <Banner show={show}>
      <OverlayButton onClick={() => handleClose()}>
        <img alt="" src={CrossSymbol} style={{ width: "2em", height: "2em" }} />
      </OverlayButton>
      <Column
        style={{
          maxWidth: "60vw",
        }}
      >
        <Heading4>Never miss a deal again</Heading4>
        <Typo3
          style={{
            color: "#F3F3F3",
          }}
        >
          {resolutionType === "mobile" || resolutionType === "tablet"
            ? "Get the app and participate in sales from your phone"
            : "Get the app and enjoy the full experience"}
        </Typo3>
      </Column>
      <Button variant="primary" onClick={() => handleInstall()}>
        <Heading5>Install</Heading5>
      </Button>
    </Banner>
  );
};

const Banner = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  background: rgb(118, 92, 222);
  color: rgb(243, 243, 243);
  align-items: center;
  justify-content: space-around;
  padding: 15px 0px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    position: absolute;
    bottom: 7vh;
    right: 2.5vw;
    border-radius: 10px;
    padding: 20px 20px;
    gap: 20px;
  }
`;

export default InstallBanner;
