import React, { useEffect } from "react";
import {
  Button,
  Heading4,
  Modal,
  ModalTitle,
  ModalContent,
  Row,
  OverlayButton,
  Spacer,
  Typo3,
} from "../../components";
import { useSendTransaction, useDetectResolutionType } from "../../hooks";

interface ITxModal {
  message: string | JSX.Element;
  txCallback: () => void;
  onComplete: (arg0: string) => void;
  title: string;
  withCloseButton?: boolean;
  isCompletedContent?: any;
  txButtonStates?: [string, string, string, string];
  onDismiss?: () => void;
  padding?: string;
}

const TxModal: React.FC<ITxModal> = ({
  message,
  txCallback,
  onComplete,
  title,
  withCloseButton = true,
  isCompletedContent = null,
  txButtonStates,
  onDismiss,
  padding,
}) => {
  const { sendTx, isPending, isCompleted, isFailed } = useSendTransaction(() =>
    txCallback()
  );
  const resolutionType = useDetectResolutionType();
  useEffect(() => {
    if (isCompleted) {
      onComplete("success");
      setTimeout(() => {
        if (!isCompletedContent) {
          onDismiss();
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted]);

  const actionDisabled = isCompleted || isPending;

  return (
    <>
      {isCompletedContent && isCompleted ? (
        isCompletedContent
      ) : (
        <Modal onDismiss={onDismiss} style={{ padding: padding }}>
          <ModalTitle text={title} />
          <ModalContent>
            {typeof message === "string" ? (
              <Typo3
                style={{
                  lineHeight: "1.5",
                }}
              >
                {message}
              </Typo3>
            ) : (
              message
            )}
          </ModalContent>
          <Spacer size="xs" />
          <Row
            justify="center"
            align="center"
            gap={1}
            style={{
              width: "100%",
              flexDirection:
                resolutionType === "mobile" || resolutionType === "tablet"
                  ? "column-reverse"
                  : "row",
            }}
          >
            {withCloseButton && (
              <OverlayButton onClick={() => onDismiss()}>
                <Heading4
                  style={{
                    color: "#765cde",
                    fontSize:
                      resolutionType === "mobile" || resolutionType === "tablet"
                        ? "14px"
                        : "16px",
                  }}
                >
                  Close
                </Heading4>
              </OverlayButton>
            )}
            <Button
              onClick={() => sendTx()}
              disabled={actionDisabled}
              style={{
                background:
                  "radial-gradient(circle at 50% 52%, #9f87fb, #765cde 25%)",
              }}
              variant="secondary"
              fontSize={
                resolutionType === "mobile" || resolutionType === "tablet"
                  ? "14px"
                  : "16px"
              }
              padding="10px 24px"
            >
              {
                txButtonStates[
                  isPending ? 1 : isCompleted ? 2 : isFailed ? 3 : 0
                ]
              }
            </Button>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default TxModal;
