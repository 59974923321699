import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import {
  Button,
  Typo2,
  Typo3,
  Column,
  Row,
  ShowForBreakpoint,
  Spacer,
} from "../../components";
import { fantomStarterTextLogoImg } from "../../assets";

const Footer: React.FC<any> = () => {
  const { color } = useContext(ThemeContext);
  return (
    <Column
      style={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1e2833",
        marginTop: "auto",
      }}
    >
      <Row
        style={{
          padding: "3rem 0",
          justifyContent: "space-between",
          width: "min(1040px, calc(100vw - 2em))",
        }}
      >
        <div>
          <img alt="FantomStarter" src={fantomStarterTextLogoImg} />
        </div>
        <Row style={{ alignItems: "center" }}>
          <Typo2 style={{ color: "white", fontWeight: "bold" }}>
            Let's connect!
          </Typo2>
          <Spacer />
          <ShowForBreakpoint size="tablet">
            <Button
              variant="secondary"
              onClick={() =>
                window.open("https://app.fantomstarter.io/projects", "_blank")
              }
            >
              Start investing
            </Button>
          </ShowForBreakpoint>
        </Row>
      </Row>
      <Column style={{ width: "min(1040px, calc(100vw - 2em))" }}>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "rgba(255, 255, 255, 0.12)",
          }}
        />
        <Spacer />
        <Row>
          <Column style={{ width: "300px " }}>
            <Typo3 style={{ fontWeight: "bold", color: color.white }}>
              FantomStarter
            </Typo3>
            <Spacer size="sm" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.fantomstarter.io/#about"
              style={{ textDecoration: "none" }}
            >
              <Typo3 style={{ color: "#fafaf7", opacity: 0.7 }}>About</Typo3>
            </a>
            <Spacer size="xxs" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://fantomstarterio.github.io/docs/English_Litepaper_v3.0.pdf"
              style={{ textDecoration: "none" }}
            >
              <Typo3 style={{ color: "#fafaf7", opacity: 0.7 }}>
                Litepaper
              </Typo3>
            </a>
            <Spacer size="xxs" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.fantomstarter.io/team"
              style={{ textDecoration: "none" }}
            >
              <Typo3 style={{ color: "#fafaf7", opacity: 0.7 }}>Team</Typo3>
            </a>
            <Spacer size="xxs" />
          </Column>
          <Column style={{ width: "300px " }}>
            <Typo3 style={{ fontWeight: "bold", color: color.white }}>
              Help
            </Typo3>
            <Spacer size="sm" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://blog.fantomstarter.io/fantomstarter-best-practices-52b9dba3c5f4?gi=fea4aada3a"
              style={{ textDecoration: "none" }}
            >
              <Typo3 style={{ color: "#fafaf7", opacity: 0.7 }}>FAQ</Typo3>
            </a>
            <Spacer size="xxs" />
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:hello@fantomstarter.io"
              style={{ textDecoration: "none" }}
            >
              <Typo3 style={{ color: "#fafaf7", opacity: 0.7 }}>Contact</Typo3>
            </a>
            <Spacer size="xxs" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://angel.co/company/fantomstarter/jobs"
              style={{ textDecoration: "none" }}
            >
              <Typo3 style={{ color: "#fafaf7", opacity: 0.7 }}>Careers</Typo3>
            </a>
          </Column>
          <Column style={{ width: "245px " }}>
            <Typo3 style={{ fontWeight: "bold", color: color.white }}>
              Socials
            </Typo3>
            <Spacer size="sm" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/fantomstarter"
              style={{ textDecoration: "none" }}
            >
              <Typo3 style={{ color: "#fafaf7", opacity: 0.7 }}>Twitter</Typo3>
            </a>
            <Spacer size="xxs" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://t.me/fantomstarter"
              style={{ textDecoration: "none" }}
            >
              <Typo3 style={{ color: "#fafaf7", opacity: 0.7 }}>Telegram</Typo3>
            </a>
            <Spacer size="xxs" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://github.com/fantomstarter"
              style={{ textDecoration: "none" }}
            >
              <Typo3 style={{ color: "#fafaf7", opacity: 0.7 }}>Github</Typo3>
            </a>
            <Spacer size="xxs" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://blog.fantomstarter.io"
              style={{ textDecoration: "none" }}
            >
              <Typo3 style={{ color: "#fafaf7", opacity: 0.7 }}>Blog</Typo3>
            </a>
            <Spacer size="xxs" />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://discord.com/invite/nsMxVcNwy6"
              style={{ textDecoration: "none" }}
            >
              <Typo3 style={{ color: "#fafaf7", opacity: 0.7 }}>Discord</Typo3>
            </a>
            <Spacer size="xxs" />
          </Column>
        </Row>
        <Spacer />
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "rgba(255, 255, 255, 0.12)",
          }}
        />
        <Spacer />
        <Row>
          <Link
            style={{
              color: "#fafaf7",
              opacity: 0.7,
              textDecoration: "none",
              fontSize: "1rem",
            }}
            to="/terms"
          >
            Terms of service
          </Link>
          <Typo3 style={{ color: "#fafaf7", opacity: 0.7, marginLeft: "auto" }}>
            FantomStarter.io © 2021-2022
          </Typo3>
        </Row>
        <Spacer />
      </Column>
    </Column>
  );
};

export default Footer;
