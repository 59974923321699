import { Contract } from "@ethersproject/contracts";
import { useWalletProvider, useTransaction } from "./index";
import { send } from "../utils";
import useLoadFSContract from "./useLoadFSContract";

const CONTRACT_NAME = "tierStake";
const CONTRACT_NAME_V2 = "tierStakeV2";

export enum TIER_STAKE_CALL_METHODS {
  GET_NFT_ID_ELIGIBLE_FOR_BENEFITS = "getNFTIdStaked",
  HAS_WALLET_CURRENTLY_STAKED_NFT = "hasWalletCurrentlyStakedNFT",
  GET_LOCKUP_TIME_LEFT_STAKED_NFT = "getLockupTimeLeftStakedNFT",
  GET_TOKEN_AMOUNT_STAKED_FOR_BENEFITS = "getTokensAmountStaked",
  HAS_WALLET_CURRENTLY_STAKED_FS_TOKENS = "hasWalletCurrentlyStakedFSTokens",
  GET_LOCKUP_TIME_LEFT_STAKED_FS_TOKENS = "getLockupTimeLeftStakedFSTokens",
  GET_TIER_STAKED = "getTierBenefitForStaker",
}
export enum TIER_STAKE_TX_METHODS {
  STAKE_TIER_NFT = "stakeTierNFT",
  UNSTAKE_TIER_NFT = "unstakeTierNFT",
  STAKE_TIER_FS_TOKENS = "stakeTierFSTokens",
  UPDATE_STAKED_TIER_FS_TOKENS = "updateCurrentStakedFSTokens",
  UNSTAKE_TIER_FS_TOKENS = "unstakeTierFSTokensAfterLockupPeriod",
  UNSTAKE_LOCKED_TIER_FS_TOKENS = "unstakeTierFSTokensBeforeLockupPeriod",
}

const tierStakeContractCall: {
  [key in TIER_STAKE_CALL_METHODS]: any;
} = {
  [TIER_STAKE_CALL_METHODS.GET_TIER_STAKED]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.getTierBenefitForStaker(address);
    // return contract.getNFTIdEligibleForBenefits(address);
  },
  [TIER_STAKE_CALL_METHODS.GET_NFT_ID_ELIGIBLE_FOR_BENEFITS]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.getNFTIdStaked(address);
    // return contract.getNFTIdEligibleForBenefits(address);
  },
  [TIER_STAKE_CALL_METHODS.HAS_WALLET_CURRENTLY_STAKED_NFT]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.hasWalletCurrentlyStakedNFT(address);
  },
  [TIER_STAKE_CALL_METHODS.GET_LOCKUP_TIME_LEFT_STAKED_NFT]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.getLockupTimeLeftStakedNFT(address);
  },
  [TIER_STAKE_CALL_METHODS.GET_TOKEN_AMOUNT_STAKED_FOR_BENEFITS]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.getTokensAmountStaked(address);
    // return contract.getTokenAmountStakedForBenefits(address);
  },
  [TIER_STAKE_CALL_METHODS.HAS_WALLET_CURRENTLY_STAKED_FS_TOKENS]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.hasWalletCurrentlyStakedFSTokens(address);
  },
  [TIER_STAKE_CALL_METHODS.GET_LOCKUP_TIME_LEFT_STAKED_FS_TOKENS]: async (
    contract: Contract,
    address: string
  ) => {
    return contract.getLockupTimeLeftStakedFSTokens(address);
  },
} as any;
const tierStakeContractTx: {
  [key in TIER_STAKE_TX_METHODS]: any;
} = {
  [TIER_STAKE_TX_METHODS.STAKE_TIER_NFT]: async (
    contract: Contract,
    nftId: number
  ) => {
    return contract.stakeTierNFT(nftId);
  },
  [TIER_STAKE_TX_METHODS.UNSTAKE_TIER_NFT]: async (contract: Contract) => {
    return contract.unstakeTierNFT();
  },
  [TIER_STAKE_TX_METHODS.STAKE_TIER_FS_TOKENS]: async (
    contract: Contract,
    tokenAmount: string
  ) => {
    return contract.stakeTierFSTokens(tokenAmount);
  },
  [TIER_STAKE_TX_METHODS.UNSTAKE_TIER_FS_TOKENS]: async (
    contract: Contract
  ) => {
    return contract.unstakeTierFSTokensAfterLockupPeriod();
  },
  [TIER_STAKE_TX_METHODS.UNSTAKE_LOCKED_TIER_FS_TOKENS]: async (
    contract: Contract
  ) => {
    return contract.unstakeTierFSTokensBeforeLockupPeriod();
  },
  [TIER_STAKE_TX_METHODS.UPDATE_STAKED_TIER_FS_TOKENS]: async (
    contract: Contract,
    tokenAmount: string
  ) => {
    return contract.updateCurrentStakedFSTokens(tokenAmount);
  },
};

const useTierStake = (chainId: number, useV2?: boolean) => {
  const { walletContext } = useWalletProvider();
  const { dispatchTx } = useTransaction();
  const { getLoadedContract, withSigner } = useLoadFSContract(
    useV2 ? CONTRACT_NAME_V2 : CONTRACT_NAME,
    chainId
  );

  const callTierStakeMethod = async (
    method: TIER_STAKE_CALL_METHODS,
    args: any
  ) => {
    const loadedContract = await getLoadedContract();
    if (!loadedContract) {
      return;
    }

    if (!tierStakeContractCall[method]) {
      console.error(`[callTierStakeMethod] method: ${method} not found`);
      return;
    }

    return tierStakeContractCall[method as TIER_STAKE_CALL_METHODS](
      loadedContract,
      ...args
    );
  };

  const txTierStakeMethod = async (
    method: TIER_STAKE_TX_METHODS,
    args: any
  ) => {
    if (!withSigner) {
      console.error(`[txTierStakeMethod] contract loaded without signer`);
      return;
    }

    let loadedContract = await getLoadedContract();
    if (!loadedContract) {
      return;
    }

    if (walletContext.activeWallet.provider.connection.url === "eip-1193:") {
      console.log("[eip-1193] connecting signer to TierStake contract!");
      loadedContract = loadedContract.connect(
        walletContext.activeWallet.signer
      );
    }

    const sendTx = (callback: any) => {
      try {
        return send(walletContext.activeWallet.provider, callback, dispatchTx);
      } catch (err) {
        throw err;
      }
    };

    if (!tierStakeContractTx[method]) {
      console.error(`[txTierNFTStakeMethod] method: ${method} not found`);
    }

    return sendTx(() => tierStakeContractTx[method](loadedContract, ...args));
  };

  return {
    callTierStakeMethod: async (
      method: TIER_STAKE_CALL_METHODS,
      args: any[]
    ) => {
      return callTierStakeMethod(method, args);
    },
    txTierStakeMethod: async (method: TIER_STAKE_TX_METHODS, args: any[]) =>
      txTierStakeMethod(method, args),
  };
};

export default useTierStake;
