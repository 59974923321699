import axios from "axios";
import useApiData from "./useApiData";

const BASEURL = process.env.REACT_APP_FANTOM_STARTER_API;

export enum FANTOM_STARTER_API_CALL {
  LOGIN = "/fractal/login/user",
  LOGOUT = "/fractal/logout/user",
  GET_WALLET = "/fractal/get/wallet",
  GET_ME = "/fractal/get/me",
  UPDATE_WALLET_ADDRESS = "/fractal/update/wallet",
  GET_PROJECTS = "/fantomstarter/get/projects",
  GET_PROJECT_SUMMARY = "/fantomstarter/get/project/summary",
  GET_PROJECT_META = "/fantomstarter/get/project/meta",
  WHITELIST = "/fantomstarter/set/whitelist/project",
  GET_IS_WHITELISTED = "/fantomstarter/get/whitelist/project",
  GET_WHITELISTED_PROJECTS = "/fantomstarter/get/whitelisted/projects",
  IS_ELIGIBLE_TO_WHITELIST = "/fantomstarter/get/eligible/projects",
}

const useFantomStarterApi = () => {
  const { dispatchApiData } = useApiData();

  const buildUri = ({ baseUrl, path, params, queryParams }: any) => {
    const url = `${baseUrl}${path ? path : ""}${
      params && params.length ? "/" + params.join("/") : ""
    }${
      queryParams && queryParams.length
        ? "?" + new URLSearchParams(queryParams)
        : ""
    }`;
    return url;
  };

  const post = ({ path, params, queryParams, body }: any) => {
    return handleApiCall(path, async () =>
      axios.post(
        buildUri({ baseUrl: BASEURL, path, params, queryParams }),
        body,
        {}
      )
    );
  };
  const get = ({ path, params, queryParams }: any) => {
    return handleApiCall(path, async () =>
      axios.get(buildUri({ baseUrl: BASEURL, path, params, queryParams }), {})
    );
  };

  const handleApiCall = async (
    path: FANTOM_STARTER_API_CALL,
    callback: any
  ) => {
    try {
      dispatchApiData({
        type: "pending",
        call: path,
      });

      const result = await callback();
      dispatchApiData({
        type: "success",
        call: path,
        data: result,
      });

      return result;
    } catch (err) {
      dispatchApiData({
        type: "failed",
        call: path,
        error: err,
      });
    }
  };

  const login = (code: string) => {
    return post({
      path: FANTOM_STARTER_API_CALL.LOGIN,
      body: { code },
    });
  };

  const logout = (accessToken: string) => {
    return post({
      path: FANTOM_STARTER_API_CALL.LOGOUT,
      body: { accessToken },
    });
  };

  const getSessionStatus = (accessToken: string) => {
    return post({
      path: FANTOM_STARTER_API_CALL.GET_ME,
      body: { accessToken },
    });
  };

  const getAccountWallet = (accessToken: string, walletAddress: string) => {
    return post({
      path: FANTOM_STARTER_API_CALL.GET_WALLET,
      body: { accessToken, walletAddress },
    });
  };

  const updateAccountWallet = (accessToken: string, walletAddress: string) => {
    return post({
      path: FANTOM_STARTER_API_CALL.UPDATE_WALLET_ADDRESS,
      body: { accessToken, walletAddress },
    });
  };

  const getProjects = () => {
    return get({
      path: FANTOM_STARTER_API_CALL.GET_PROJECTS,
    });
  };

  const getProjectSummary = (projectId: string) => {
    return get({
      path: FANTOM_STARTER_API_CALL.GET_PROJECT_SUMMARY,
      params: [projectId],
    });
  };

  const getProjectMeta = (projectId: string, metaName: string) => {
    return get({
      path: FANTOM_STARTER_API_CALL.GET_PROJECT_META,
      params: [projectId, metaName],
    });
  };

  const getIsAccountWhitelistedForProject = (
    accessToken: string,
    projectId: string
  ) => {
    return post({
      path: FANTOM_STARTER_API_CALL.GET_IS_WHITELISTED,
      body: { accessToken, projectId },
    });
  };

  const getIsWalletWhitelistedForProject = (
    walletAddress: string,
    projectId: string
  ) => {
    return post({
      path: FANTOM_STARTER_API_CALL.GET_IS_WHITELISTED,
      body: { walletAddress, projectId },
    });
  };

  const getWhitelistedProjects = (accessToken: string) => {
    return post({
      path: FANTOM_STARTER_API_CALL.GET_WHITELISTED_PROJECTS,
      body: { accessToken },
    });
  };

  const whitelistForProject = (
    accessToken: string,
    walletAddress: string,
    projectId: string
  ) => {
    return post({
      path: FANTOM_STARTER_API_CALL.WHITELIST,
      body: { accessToken, walletAddress, projectId },
    });
  };

  const isEligibleToWhitelistForProject = (
    accessToken: string,
    projectId: string
  ) => {
    return post({
      path: FANTOM_STARTER_API_CALL.IS_ELIGIBLE_TO_WHITELIST,
      body: { accessToken, projectId },
    });
  };

  return {
    login,
    logout,
    getAccountWallet,
    updateAccountWallet,
    getSessionStatus,
    getProjects,
    getProjectSummary,
    getProjectMeta,
    getIsAccountWhitelistedForProject,
    getIsWalletWhitelistedForProject,
    getWhitelistedProjects,
    whitelistForProject,
    isEligibleToWhitelistForProject,
  };
};

export default useFantomStarterApi;
