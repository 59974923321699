import React, { useState } from "react";
import { infoIcon, CrossSymbol } from "../../assets";
import { Typo2, Spacer, Button, Heading5 } from "../../components";
import { useDetectResolutionType } from "../../hooks";
import styled from "styled-components";

const Banner: React.FC<any> = ({
  title,
  message,
  buttonText,
  handleButtonClick,
}) => {
  const resolutionType = useDetectResolutionType();
  const [show, setShow] = useState(true);
  return (
    <BannerBox show={show}>
      <img
        alt=""
        src={infoIcon}
        style={{
          display: resolutionType === "mobile" ? "none" : "",
          width: "1.5em",
          height: "1.5em",
        }}
      />
      <Spacer />
      <Typo2 style={{ color: "white" }} weight={"bold"}>
        {title}
      </Typo2>
      <Spacer size="xs" />
      <Typo2 style={{ color: "white" }}>{message}</Typo2>
      <Spacer size="xxl" />
      <Button
        variant="primary"
        onClick={() => {
          handleButtonClick();
          setShow(false);
        }}
        style={{
          marginTop: resolutionType === "mobile" && ".5em",
          width: resolutionType === "mobile" && "60%",
          alignSelf: "center",
          background: "#3AB8FE",
        }}
      >
        <Heading5>{buttonText}</Heading5>
      </Button>
      <Spacer size="sm" />
      <Cross alt="Cross" src={CrossSymbol} onClick={() => setShow(false)} />
    </BannerBox>
  );
};

const BannerBox = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: row;
  margin-top: 72px;
  height: 48px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  background-color: #0c1727;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
    height: 98px;
    padding: 20px;
    align-items: flex-start;
    gap: 2.5px;
    position: absolute;
    z-index: 3;
  }
`;

const Cross = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    position: absolute;
    top: 10%;
    right: 15px;
  }
`;

export default Banner;
