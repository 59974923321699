import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useAccount, useFantomStarterApi } from "../hooks";

/**
 *
 * @param WrappedComponent
 * @returns WrappedComponent
 * This hoc is used to detect fractal authorisation and redirect to a specific
 * project, if a redirect item is set in local storage
 */
function withFractalAuth(WrappedComponent: React.FC): React.FC {
  return function WithConnectedWalletComponent({ ...props }) {
    const { search } = useLocation();
    const history = useHistory();
    const { login } = useFantomStarterApi();
    const { account, dispatchAccount } = useAccount();

    useEffect(() => {
      if (search) {
        try {
          const params = new URLSearchParams(search);
          const code = params.get("code");
          const redirect = localStorage.getItem("redirect");

          if (code) {
            dispatchAccount({ type: "loginFractal", fractalCode: code });
            if (!account.sessionID) {
              login(code);
            }
          }

          if (redirect) {
            localStorage.removeItem("redirect");
            return history.push(redirect);
          }
          return history.push("/projects");
        } catch (err) {
          console.error(err);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return <WrappedComponent {...props} />;
  };
}

export default withFractalAuth;
