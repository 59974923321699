import React from "react";
import styled from "styled-components";
import MainContainer from "../MainContainer";
import { fSLoading } from "../../assets";

const Loading: React.FC = () => (
  <MainContainer>
    <Animation src={fSLoading} alt="loading" title="loading" />
  </MainContainer>
);

const Animation = styled.img`
  max-width: 25em;
  margin: 0 auto;
`;

export default Loading;
