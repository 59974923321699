import { useEffect, useState } from "react";
import { Contract } from "@ethersproject/contracts";
// @ts-ignore
import { abis, addresses } from "@fs/contracts";
import { useTransaction, useWalletProvider } from "./index";
import { isSameAddress, loadContract, send } from "../utils";
import { loadProvider } from "../utils/wallet";
import { BigNumber } from "@ethersproject/bignumber";

export enum STAKING_CALL_METHODS {
  GET_POOLS = "getPools",
  IS_STAKING_IN = "isStakingIn",
  GET_STAKES_OF = "getStakesOf",
  GET_STAKE_INDEX = "getStakeIndex",
  POOLS = "pools",
  STAKES = "stakes",
}

export enum STAKING_TX_METHODS {
  STAKE_TOKENS = "stakeTokens",
  UNSTAKE_TOKENS = "unstakeTokens",
}

export enum STAKING_EVENTS {
  TOKEN_STAKED = "tokenStaked",
  TOKEN_UNSTAKED = "tokenUnstaked",
}

const stakingContractCall: {
  [key in STAKING_CALL_METHODS]: any;
} = {
  [STAKING_CALL_METHODS.GET_POOLS]: async (contract: Contract) => {
    return contract.getPools();
  },
  [STAKING_CALL_METHODS.IS_STAKING_IN]: async (
    contract: Contract,
    poolIndex: number,
    user: string
  ) => {
    return contract.isStakingIn(poolIndex, user);
  },
  [STAKING_CALL_METHODS.GET_STAKES_OF]: async (
    contract: Contract,
    user: string
  ) => {
    return contract.getStakesOf(user);
  },
  [STAKING_CALL_METHODS.GET_STAKE_INDEX]: async (
    contract: Contract,
    poolIndex: number,
    user: string
  ) => {
    return contract.getStakeIndex(poolIndex, user);
  },
  [STAKING_CALL_METHODS.POOLS]: async (
    contract: Contract,
    poolIndex: number
  ) => {
    return contract.pools(poolIndex);
  },
  [STAKING_CALL_METHODS.STAKES]: async (
    contract: Contract,
    stakeIndex: number
  ) => {
    return contract.stakes(stakeIndex);
  },
} as any;

const stakingContractTx: {
  [key in STAKING_TX_METHODS]: any;
} = {
  [STAKING_TX_METHODS.STAKE_TOKENS]: async (
    contract: Contract,
    amount: number,
    sFsAmount: number,
    poolIndex: number,
    lockIndex: number
  ) => {
    return contract.stakeTokens(amount, sFsAmount, poolIndex, lockIndex);
  },
  [STAKING_TX_METHODS.UNSTAKE_TOKENS]: async (
    contract: Contract,
    poolIndex: number
  ) => {
    return contract.unstakeTokens(poolIndex);
  },
} as any;

const stakingContractEventListeners: {
  [key in STAKING_EVENTS]: any;
} = {
  [STAKING_EVENTS.TOKEN_STAKED]: async (
    contract: Contract,
    address: string,
    callback: (info: any) => void
  ) => {
    contract.on(
      contract.filters.tokenStaked(address),
      (
        staker: string,
        poolIndex: BigNumber,
        amountStaked: BigNumber,
        claimableReward: BigNumber,
        unstakeTimestamp: BigNumber
      ) => {
        const info = {
          staker: staker,
          poolIndex: poolIndex.toNumber(),
          amountStaked: amountStaked,
          claimableReward: claimableReward,
          unstakeTimestamp: unstakeTimestamp.toNumber(),
        };
        callback(info);
      }
    );
  },
  [STAKING_EVENTS.TOKEN_UNSTAKED]: async (
    contract: Contract,
    address: string,
    callback: (info: any) => void
  ) => {
    contract.on(
      contract.filters.tokenUnstaked(address),
      (
        staker: string,
        stakeIndex: BigNumber,
        poolIndex: BigNumber,
        reward: BigNumber
      ) => {
        const info = {
          staker: staker,
          stakeIndex: stakeIndex,
          poolIndex: poolIndex,
          reward: reward,
        };
        callback(info);
      }
    );
  },
} as any;

const useStakingContract = (contractAddress: string, chainId: number) => {
  const { walletContext } = useWalletProvider();
  const [contract, setContract] = useState(null);
  const [withSigner, setWithSigner] = useState(false);
  const { dispatchTx } = useTransaction();
  const [isLoadedOnChain, setIsLoadedOnChain] = useState(null);

  const loadStakingContract = async (provider: any) => {
    if (!provider) {
      console.error("[loadStakingContract] Failed to load provider");
      return;
    }
    let address;
    try {
      // Check if provider can also be the signer
      const signer = await provider.getSigner();
      address = await signer.getAddress();
    } catch (err) {
      console.warn("[loadStakingContract] Failed to load as Signer");
    }
    setWithSigner(isSameAddress(address, walletContext.activeWallet.address));
    return loadContract(
      provider,
      addresses[chainId].stakingContract,
      abis.stakingContract
    );
  };
  useEffect(() => {
    if (contractAddress) {
      loadProvider(walletContext, chainId).then((provider) => {
        loadStakingContract(provider).then((contract) => {
          if (provider.connection.url === "eip-1193:") {
            console.log("[eip-1193] connecting signer to staking contract!");
            contract = contract.connect(walletContext.activeWallet.signer);
          }
          if (!withSigner && walletContext.activeWallet.signer) {
            contract = contract.connect(walletContext.activeWallet.signer);
          }
          setContract(contract);
          setIsLoadedOnChain(chainId);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractAddress, chainId, walletContext.activeWallet.address]);

  const contractIsLoaded = () => {
    return !!contract;
  };

  const callStakingContractMethod = async (
    method: STAKING_CALL_METHODS,
    args: any
  ) => {
    if (!contractIsLoaded()) {
      console.error(
        `[callStakingContractMethod] method: ${method} | failed loading staking contract`
      );
      return;
    }
    if (!stakingContractCall[method]) {
      console.error(
        `[callStakingContractMethod] method: ${method} | not found`
      );
      return;
    }
    return stakingContractCall[method as STAKING_CALL_METHODS](
      contract,
      ...args
    );
  };

  const txStakingContractMethod = async (
    method: STAKING_TX_METHODS,
    args: any
  ) => {
    if (!contractIsLoaded()) {
      console.error(
        `[txStakingContractMethod] failed loading staking contract`
      );
      return;
    }
    if (!withSigner) {
      console.error(`[txStakingContractMethod] need signer for sending tx`);
      return;
    }
    const sendTx = (callback: any) => {
      try {
        return send(walletContext.activeWallet.provider, callback, dispatchTx);
      } catch (err) {
        throw err;
      }
    };
    if (!stakingContractTx[method]) {
      console.error(`[txStakingContractMethod] method: ${method} not found`);
    }
    return sendTx(() => stakingContractTx[method](contract, ...args));
  };

  const listenStakingContractEvents = async (
    event: STAKING_EVENTS,
    args: any
  ) => {
    if (!contractIsLoaded()) {
      console.error(
        `[listenStakingContractEvents] failed loading staking contract`
      );
      return;
    }
    if (!stakingContractEventListeners[event]) {
      console.error(`[listenStakingContractEvents] event: ${event} not found`);
    }
    return stakingContractEventListeners[event as STAKING_EVENTS](
      contract,
      ...args
    );
  };

  return {
    callStakingContractMethod: async (
      method: STAKING_CALL_METHODS,
      args: any[]
    ) => callStakingContractMethod(method, args),
    txStakingContractMethod: async (method: STAKING_TX_METHODS, args: any[]) =>
      txStakingContractMethod(method, args),
    listenStakingContractEvents: async (event: STAKING_EVENTS, args: any[]) =>
      listenStakingContractEvents(event, args),
    isLoadedOnChain,
  };
};

export default useStakingContract;
