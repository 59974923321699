import styled, { keyframes, css } from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { zoomIn } from "../../components";

const zoomInCss = css`
  animation: ${zoomIn} 0.2s ease-in 0s 1 normal forwards;
`;

interface ICard {
  padding?: string;
  shadow?: boolean;
}

const Card = styled.div<ICard>`
  background-color: white;
  display: inline-flex;
  border-radius: 32px;
  box-shadow: ${({ shadow }) =>
    shadow &&
    `0 0px 0px 0 rgba(0, 0, 0, 0.08), 0 -3px 10px 0 rgba(0, 0, 0, 0.13)`};
  transition: box-shadow 0.2s ease-in;
  overflow: hidden;
  ${zoomInCss}

  &:hover, &:focus {
    box-shadow: 0 10.3px 6.9px 0 rgba(0, 0, 0, 0.08),
      0 33.2px 29.2px 0 rgba(0, 0, 0, 0.13);
  }
`;

const loading = keyframes`
    0% {
        background-position-x: 0%
    }
    100% {
        background-position-x: 100%
    }
`;

const loadingCss = css`
  animation: ${loading} 1.5s infinite;
`;

export const CardSkeleton = styled.div`
  background-image: linear-gradient(100deg, #c0c0c1 0%, #fff 100%);
  background-position-x: -50%;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 550px;
  width: 100%;
  ${loadingCss}
`;

export const CardLink = styled(RouterLink)`
  color: #000;
  text-decoration: none;
`;

export default Card;
