import { IConsent } from "../shared";

export const STAKING_CONSENT_REQUIREMENTS: IConsent[] = [
  {
    heading: "Risk",
    text:
      "Investing in crypto startups is very risky. You should only invest an amount you can afford to lose.",
    labeledCheckbox: {
      name: "Risk",
      text: "I understand that I can lose the money I'm investing",
    },
  },
  {
    heading: "Limited transfer",
    text:
      "It may be difficult to transfer the tokens acquired on FantomStarter and some tokens may not allow transfers altogether.",
    labeledCheckbox: {
      name: "LimitedTransfer",
      text: "I understand that it may be difficult to transfer my investments",
    },
  },
  {
    heading: "Diversification",
    text:
      "Crypto startups investing is highly speculative and every investment may result in a loss. By investing small amounts across multiple deals, you can reduce your risk compared to a large investment in a single project.",
    labeledCheckbox: {
      name: "Diversification",
      text:
        "I understand that it's safer to split money across many investments across asset classes",
    },
  },
  {
    heading: "Research",
    text:
      "Do your own research. Read the documents provided by each company. Get independent legal, accounting, financial advice. If you have any questions or need more information, ask the company directly via official channels.",
    labeledCheckbox: {
      name: "Research",
      text: "I understand that doing research is my own responsbility",
    },
  },
];
