import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { clusterApiUrl } from "@solana/web3.js";

// eslint-disable-next-line import/no-anonymous-default-export
const config = {
  supportedChains: [
    process.env.REACT_APP_FS_FANTOM !== "off" ? 250 : null,
    process.env.REACT_APP_FS_POLYGON !== "off" ? 137 : null,
    process.env.REACT_APP_FS_BINANCE !== "off" ? 56 : null,
    process.env.REACT_APP_FS_ETHEREUM !== "off" ? 1 : null,
    process.env.REACT_APP_FS_ARBITRUM !== "off" ? 42161 : null,
    process.env.REACT_APP_FS_OPTIMISM !== "off" ? 10 : null,
    process.env.REACT_APP_FS_AVALANCHE !== "off" ? 43114 : null,
  ],
  // Opera chain id
  defaultChainId: 250,
  // JSON-RPC endpoint
  rpc: {
    250: "https://rpc.ankr.com/fantom",
    137: "https://rpc.ankr.com/polygon",
    56: "https://rpc.ankr.com/bsc",
    1:
      process.env.REACT_APP_FS_ETHEREUM !== "off"
        ? "https://rpc.ankr.com/eth"
        : null,
    42161:
      process.env.REACT_APP_FS_ARBITRUM !== "off"
        ? "https://rpc.ankr.com/arbitrum"
        : null,
    10:
      process.env.REACT_APP_FS_OPTIMISM !== "off"
        ? "https://rpc.ankr.com/optimism"
        : null,
    43114:
      process.env.REACT_APP_FS_AVALANCHE !== "off"
        ? "https://rpc.ankr.com/avalanche"
        : null,
  },
  isTestnet: false,
  solNetwork: WalletAdapterNetwork.Mainnet,
  solEndpoint: "https://rpc.ankr.com/solana",
  aptosChainId: null,
  aptosNodeUrl: null,
};

if (process.env.REACT_APP_USE === "testnet") {
  console.warn("FantomStarter app is using TESTNET!");
  config.supportedChains = [
    process.env.REACT_APP_FS_FANTOM !== "off" ? 4002 : null,
    process.env.REACT_APP_FS_POLYGON !== "off" ? 80001 : null,
    process.env.REACT_APP_FS_BINANCE !== "off" ? 97 : null,
    process.env.REACT_APP_FS_ETHEREUM !== "off" ? 5 : null,
    process.env.REACT_APP_FS_ARBITRUM !== "off" ? 421611 : null,
    process.env.REACT_APP_FS_OPTIMISM !== "off" ? 420 : null,
    process.env.REACT_APP_FS_AVALANCHE !== "off" ? 43113 : null,
    process.env.REACT_APP_FS_SONIC !== "off" ? 64240 : null,
  ];
  config.rpc = {
    4002:
      process.env.REACT_APP_FS_FANTOM !== "off"
        ? "https://rpc.ankr.com/fantom_testnet"
        : null,
    80001:
      process.env.REACT_APP_FS_POLYGON !== "off"
        ? "https://rpc.ankr.com/polygon_mumbai"
        : null,
    // 97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    97:
      process.env.REACT_APP_FS_BINANCE !== "off"
        ? "https://rpc.ankr.com/bsc_testnet_chapel"
        : null,
    5:
      process.env.REACT_APP_FS_ETHEREUM !== "off"
        ? "https://rpc.ankr.com/eth_goerli"
        : null,
    421611:
      process.env.REACT_APP_FS_ARBITRUM !== "off"
        ? "https://rinkeby.arbitrum.io/rpc"
        : null,
    420:
      process.env.REACT_APP_FS_OPTIMISM !== "off"
        ? "https://goerli.optimism.io"
        : null,
    43113:
      process.env.REACT_APP_FS_AVALANCHE !== "off"
        ? "https://api.avax-test.network/ext/bc/C/rpc"
        : null,
    64240:
      process.env.REACT_APP_FS_SONIC !== "off"
        ? "https://rpcapi.sonic.fantom.network/"
        : null,
  };
  config.defaultChainId = process.env.REACT_APP_FS_TESTNET_DEFAULT_CHAIN_ID
    ? parseInt(process.env.REACT_APP_FS_TESTNET_DEFAULT_CHAIN_ID)
    : 4002;
  config.isTestnet = true;

  config.solNetwork = WalletAdapterNetwork.Devnet;
  config.solEndpoint = clusterApiUrl(config.solNetwork);

  config.aptosChainId = 33;
  config.aptosNodeUrl = "https://fullnode.devnet.aptoslabs.com/v1";
}

export default config;
