import React from "react";
import Modal from "../Modal";
import ModalContent from "../ModalContent";
import ModalTitle from "../ModalTitle";
import { Button, Heading4, OverlayButton, Typo2 } from "../index";
import Row from "../Row";
import Spacer from "../Spacer";
import { useDetectResolutionType } from "../../hooks";

const InfoModal: React.FC<any> = ({
  title,
  message,
  actionButtonText,
  handleActionButton,
  actionButtonNoDismiss,
  buttonStyle,
  withCloseButton = true,
  onDismiss,
}) => {
  const resolutionType = useDetectResolutionType();
  return (
    <Modal onDismiss={onDismiss}>
      <ModalTitle text={title ? title : "Warning"} />
      <ModalContent>
        <Typo2>{message}</Typo2>
      </ModalContent>
      <Spacer size="xs" />
      <Row
        style={{
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          flexDirection:
            resolutionType === "mobile" || resolutionType === "tablet"
              ? "column-reverse"
              : "row",
        }}
      >
        {withCloseButton && (
          <OverlayButton onClick={() => onDismiss()}>
            <Heading4
              style={{
                color: "#765cde",
                fontSize:
                  resolutionType === "mobile" || resolutionType === "tablet"
                    ? "14px"
                    : "16px",
              }}
            >
              Close
            </Heading4>
          </OverlayButton>
        )}
        {handleActionButton && actionButtonText && (
          <Button
            style={{ ...buttonStyle }}
            onClick={() => {
              handleActionButton();
              !actionButtonNoDismiss && onDismiss();
            }}
            variant="primary"
            fontSize={
              resolutionType === "mobile" || resolutionType === "tablet"
                ? "14px"
                : "16px"
            }
            padding="10px 24px"
          >
            {actionButtonText}
          </Button>
        )}
      </Row>
    </Modal>
  );
};

export default InfoModal;
