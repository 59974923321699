import React, { useReducer } from "react";
import { JsonRpcProvider } from "@ethersproject/providers";
import config from "../config/config";
import { loadContracts } from "../utils";

const fallBackFtmProvider = new JsonRpcProvider(
  (config.rpc as any)[config.defaultChainId]
);
const fallbackPolygonProvider = new JsonRpcProvider(
  (config.rpc as any)[process.env.REACT_APP_USE === "testnet" ? 80001 : 137]
);
const fallbackBSCProvider = new JsonRpcProvider(
  (config.rpc as any)[process.env.REACT_APP_USE === "testnet" ? 97 : 56]
);
const fallbackEthProvider = new JsonRpcProvider(
  (config.rpc as any)[process.env.REACT_APP_USE === "testnet" ? 5 : 1]
);
const fallbackArbitrumProvider = new JsonRpcProvider(
  (config.rpc as any)[process.env.REACT_APP_USE === "testnet" ? 421611 : 42161]
);
const fallbackOptimismProvider = new JsonRpcProvider(
  (config.rpc as any)[process.env.REACT_APP_USE === "testnet" ? 420 : 10]
);
const fallbackAvalancheProvider = new JsonRpcProvider(
  (config.rpc as any)[process.env.REACT_APP_USE === "testnet" ? 43113 : 43114]
);
const fallbackSonicProvider = new JsonRpcProvider(
  (config.rpc as any)[process.env.REACT_APP_USE === "testnet" ? 64240 : 64240]
);
export const ActiveWalletContext = React.createContext(null);
const initial = {
  RPCProvider: {
    fantom: {
      provider: fallBackFtmProvider,
      contracts: loadContracts(fallBackFtmProvider, config.defaultChainId),
      chainId: config.defaultChainId,
    },
    polygon: {
      provider: fallbackPolygonProvider,
      contracts: loadContracts(
        fallbackPolygonProvider,
        process.env.REACT_APP_USE === "testnet" ? 80001 : 137
      ),
      chainId: process.env.REACT_APP_USE === "testnet" ? 80001 : 137,
    },
    bsc: {
      provider: fallbackBSCProvider,
      contracts: loadContracts(
        fallbackBSCProvider,
        process.env.REACT_APP_USE === "testnet" ? 97 : 56
      ),
      chainId: process.env.REACT_APP_USE === "testnet" ? 97 : 56,
    },
    eth: {
      provider: fallbackEthProvider,
      contracts: loadContracts(
        fallbackEthProvider,
        process.env.REACT_APP_USE === "testnet" ? 5 : 1
      ),
      chainId: process.env.REACT_APP_USE === "testnet" ? 5 : 1,
    },
    arbitrum: {
      provider: fallbackArbitrumProvider,
      contracts: loadContracts(
        fallbackArbitrumProvider,
        process.env.REACT_APP_USE === "testnet" ? 421611 : 42161
      ),
      chainId: process.env.REACT_APP_USE === "testnet" ? 421611 : 42161,
    },
    optimism: {
      provider: fallbackOptimismProvider,
      contracts: loadContracts(
        fallbackOptimismProvider,
        process.env.REACT_APP_USE === "testnet" ? 420 : 10
      ),
      chainId: process.env.REACT_APP_USE === "testnet" ? 420 : 10,
    },
    avalanche: {
      provider: fallbackAvalancheProvider,
      contracts: loadContracts(
        fallbackAvalancheProvider,
        process.env.REACT_APP_USE === "testnet" ? 43113 : 43114
      ),
      chainId: process.env.REACT_APP_USE === "testnet" ? 43113 : 43114,
    },
    sonic: {
      provider: fallbackSonicProvider,
      contracts: loadContracts(
        fallbackSonicProvider,
        process.env.REACT_APP_USE === "testnet" ? 64240 : 64240
      ),
      chainId: process.env.REACT_APP_USE === "testnet" ? 64240 : 64240,
    },
  },
  activeWallet: {
    address: null,
    chainId: null,
    contracts: new Map([]),
    provider: null,
    signer: null,
    providerType: null,
    network: null,
    endpoint: null,
  },
  web3ProviderState: {
    accountSelected: null,
    chainSelected: null,
  },
  appState: {
    activeChainId: null,
  },
} as any;

export const ActiveWalletProvider: React.FC = ({ children }) => {
  const activeWalletReducer = (state: any, action: any) => {
    switch (action.type) {
      case "setInActiveWallet":
        return {
          ...state,
          activeWallet: action.data,
          web3ProviderState: {
            accountSelected:
              action.data.providerType === "metamask"
                ? action.data.address
                : state.metamaskAccountSelected,
            chainSelected:
              action.data.providerType === "metamask" &&
              action.data.web3ProviderChainSelected,
          },
        };
      case "setActiveWallet":
        return {
          ...state,
          activeWallet: action.data,
          web3ProviderState: {
            accountSelected:
              action.data.providerType === "metamask" ||
              action.data.providerType === "Phantom" ||
              action.data.providerType === "Martian"
                ? action.data.address
                : state.metamaskAccountSelected,
            chainSelected:
              (action.data.providerType === "metamask" ||
                action.data.providerType === "Martian") &&
              action.data.chainId,
            networkSelected:
              action.data.providerType === "Phantom" && action.data.network,
          },
        };
      case "chainChanged":
        return {
          ...state,
          activeWallet: action.data,
          web3ProviderState: {
            ...state.web3ProviderState,
            chainSelected: action.data.chainId,
          },
        };
      case "web3ProviderAccountChanged":
        return {
          ...state,
          web3ProviderState: {
            ...state.web3ProviderState,
            accountSelected: action.data.accountSelected,
          },
        };
      case "web3ProviderChainChanged":
        return {
          ...state,
          activeWallet: {
            ...state.activeWallet,
            chainId: action.data.chainChanged,
          },
          web3ProviderState: {
            ...state.web3ProviderState,
            chainSelected: action.data.chainChanged,
          },
        };

      case "reset":
        localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
        return initial;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(activeWalletReducer, initial);

  return (
    <ActiveWalletContext.Provider value={[state, dispatch]}>
      {children}
    </ActiveWalletContext.Provider>
  );
};
