import React from "react";
import styled from "styled-components";
import Slider, { createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";

const SliderWithTooltip = createSliderWithTooltip(Slider);

const SliderWithMarks: React.FC<any> = ({
  value,
  setValue,
  min = 0,
  max,
  steps,
  disabled = false,
  markPoints = [0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000],
  color = "#a7785b",
  railColor = "#0A162E",
  tooltip = false,
  tooltipColor,
  tooltipTextColor,
}) => {
  const markStyle = (value: number, mark: number) => {
    return {
      // color: value < mark ? secondaryColor : color,
      // fontWeight: "bold",
      // fontSize: "16px",
      // paddingTop: "10px",
      // width: "5rem",
      color: "transparent",
    };
  };

  const markLabels = [
    "Tier 1",
    "Tier 2",
    "Tier 3",
    "Tier 4",
    "Tier 5",
    "Tier 6",
    "Tier 7",
    "Tier 8",
    "Tier 9",
  ];
  const marks = markPoints.reduce(
    (accumulator: any, current: number, index: number) => {
      return {
        ...accumulator,
        [current]: {
          style: markStyle(value, current),
          label: markLabels ? markLabels[index] : `${current}%`,
        },
      };
    },
    {}
  );

  return (
    <StyledSlider>
      {tooltip ? (
        <StyledSliderTooltip
          tooltipColor={tooltipColor || "white"}
          tooltipTextColor={tooltipTextColor || color}
        >
          <SliderWithTooltip
            tipProps={{
              prefixCls: "rc-slider-tooltip",
              placement: "top",
              visible: true,
            }}
            tipFormatter={(value) => `${value} $FS`}
            disabled={disabled}
            onChange={(val) => setValue(val)}
            value={value}
            min={min}
            max={max}
            step={steps}
            marks={marks}
            trackStyle={{ backgroundColor: color, height: 6 }}
            handleStyle={{
              borderColor: color,
              height: 10,
              width: 10,
              marginLeft: 0,
              marginTop: -9,
              backgroundColor: color,
            }}
            railStyle={{ backgroundColor: railColor, height: 6 }}
            dotStyle={{ backgroundColor: railColor, border: 10 }}
          />
        </StyledSliderTooltip>
      ) : (
        <Slider
          disabled={disabled}
          onChange={(val) => setValue(val)}
          value={value}
          min={min}
          max={max}
          step={steps}
          marks={marks}
          trackStyle={{
            backgroundImage:
              "linear-gradient(281deg, #fcba57, #df73ec, #765cde)",
            height: 6,
            zIndex: 1,
          }}
          handleStyle={{
            borderColor: "#fcba57",
            height: 16,
            width: 16,
            marginTop: -6,
            backgroundColor: "#fcba57",
          }}
          railStyle={{ backgroundColor: railColor, height: 6 }}
          dotStyle={{
            backgroundColor: railColor,
            height: 16,
            width: 16,
            marginLeft: -8,
            bottom: -7,
            border: "unset",
          }}
        />
      )}
    </StyledSlider>
  );
};

export const StyledSlider = styled.div`
  margin: 0 0.5rem;
  .rc-slider-disabled {
    background-color: transparent !important;
  }
`;

export const StyledSliderTooltip = styled.div<{
  tooltipColor: string;
  tooltipTextColor: string;
}>`
  .rc-slider-tooltip-inner {
    margin-top: 0.3rem;
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => props.tooltipTextColor};
    padding: 0.25rem 0.5rem;
    background-color: ${(props) => props.tooltipColor};
    white-space: nowrap;
  }
  .rc-slider-tooltip-arrow {
    margin-top: -0.3rem;
    left: 50%;
    margin-left: -6px;
    border-width: 0 6px 8px;
    border-bottom-color: ${(props) => props.tooltipColor};
    transform: rotate(180deg);
  }
`;

export default SliderWithMarks;
