import { keyframes } from "styled-components";

export const animateBottom = keyframes`
	0% {
		transform: translateY(calc(-100% - 60px));
	}
	50% {
		transform: translateY(-50%);
	}
	100% {
		transform: translateY(0%);
	}
`;

export const animateTop = keyframes`
	0% {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(-50%);
	}
	100% {
		transform: translateY(calc(-100% - 60px));
	}
`;

export const slideUp = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-50%);
  }
`;

export const slideToTop = keyframes`
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const zoomIn = keyframes`
	0% {
		transform: scale(50%);
	}
	50% {
		transform: scale(75%);
	}
	100% {
		transform: scale(100%);
	}
`;
