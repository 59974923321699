import {
  tier1Img,
  tier2Img,
  tier3Img,
  tier4Img,
  tier5Img,
  tier6Img,
  tier7Img,
} from "../assets";

export const ALL_CARDS = [1, 2, 3, 4, 5, 6, 7];
export const ALL_SHOP_CARDS = [1, 2, 3, 4, 5];
export const ALL_FCFS_CARDS = [8];
export const ALL_CARD_INFO = {
  0: {
    img: null,
    name: "No tier",
    tier: 0,
    tickets: 0,
    weight: 0,
    price: 0,
    max: 0,
  },
  1: {
    img: tier1Img,
    name: "Minion",
    tier: 1,
    tickets: 1,
    weight: 1,
    price: 13500,
    // price: 11250,
    max: 500,
  },
  2: {
    img: tier2Img,
    name: "Imp",
    tier: 2,
    tickets: 2,
    weight: 1.5,
    price: 26100,
    // price: 21750,
    max: 250,
  },
  3: {
    img: tier3Img,
    name: "Oni",
    tier: 3,
    tickets: 4,
    weight: 2.25,
    price: 50400,
    // price: 42000,
    max: 150,
  },
  4: {
    img: tier4Img,
    name: "Banshee",
    tier: 4,
    tickets: 8,
    weight: 3.25,
    price: 99000,
    // price: 82500,
    max: 100,
  },
  5: {
    img: tier5Img,
    name: "Wraith",
    tier: 5,
    tickets: 100,
    weight: 4.5,
    price: 409500,
    // price: 341250,
    max: 50,
  },
  6: {
    img: tier6Img,
    name: "Kappa",
    tier: 6,
    tickets: 100,
    weight: 9,
    price: 742500,
    // price: 618750,
    max: 35,
  },
  7: {
    img: tier7Img,
    name: "King",
    tier: 7,
    tickets: 100,
    weight: 27,
    price: 2025000,
    // price: 1687500,
    max: 15,
  },
} as any;

export const getNFTInfoById = (id: number) => {
  return ALL_CARD_INFO[id];
};
