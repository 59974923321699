/**
 * See all ids below
 * https://ethereum.stackexchange.com/questions/17051/how-to-select-a-network-id-or-is-there-a-list-of-network-ids
 */
export const FANTOM_MAIN_ID = 250;
export const FANTOM_TEST_ID = 4002;
export const POLYGON_MAIN_ID = 137;
export const POLYGON_TEST_ID = 80001;
export const BSC_MAIN_ID = 56;
export const BSC_TEST_ID = 97;
export const ETH_MAIN_ID = 1;
export const ETH_TEST_ID = 5;
export const ARBITRUM_MAIN_ID = 42161;
export const ARBITRUM_TEST_ID = 421611;
export const OPTIMISM_MAIN_ID = 10;
export const OPTIMISM_TEST_ID = 420;
export const AVALANCHE_TEST_ID = 43113;
export const AVALANCHE_MAIN_ID = 43114;
export const SONIC_TEST_ID = 64240;

export default {
  [FANTOM_MAIN_ID]: {
    tokens: {
      FS: "0xc758295cd1a564cdb020a78a681a838cf8e0627d",
      sFS: "0x1dFD4924Ed9b63DA1874BE926DcCC96eA9Ca74a4",
      USDC: "",
    },
    tierNFTFactory: "0x4Aa7324857b02Ed50bbD490D254918Db8708D8c0",
    tierNFTStore: "0xc5a3d647458ab15b3a2fb49b9c7c5f752763d034",
    tierStake: "0x22755c231fAFEefd6Cbb49CeEAa9d4831FfFa500",
    tierStakeV2: "0x1c2442A922ffda9A2429adDC52740eD688F819a7",
    tierNftAirdrop: "0x724c778Bb25f670b014b858E626A1E93E4c4F4a3",
    tierNFTAirdropFactory: "0x0631860db9fc93163c5cd914e41fb444628d4fab",
    stakingContract: "0x19a277b7BB45AcC7a5a89C02D9c56c7b90790e09",
  },
  [FANTOM_TEST_ID]: {
    tokens: {
      FS: "0x237Ed5598899e6fBdb1062D8556E28C3ECc400f8",
      sFS: "0xf940919BD5a1bB47764289394AF66937B1c3EA13",
      USDC: "",
    },
    tierNFTFactory: "0x806b891bad6A1B713Afc03cf2149E6170aD81996",
    tierNFTStore: "0xc6Cd61CeB87C7EF65071072277F52471f1D39F36",
    tierStake: "0xB2E88da3D236e2cb739a37ff61E0e1687B8b68b3",
    tierStakeV2: "0x111E30F80E8Ee3C1f1c588370370B5C006e2f32f",
    tierConfiguration: "0x5b275df32b25f0793a06dc7f605bfb9d5fe4b5e2",
    fsFaucet: "0x4824292dD7f9aCa724F267093cb340cCB629B5B1",
    tierNftAirdrop: "0x614b55861d8a339519549F1E665A0D691BaAAF29",
    tierNFTAirdropFactory: "0x0dfe9489a265968d3cf3203cf2c1e73f163801f4",
    stakingContract: "0xa5FBB0ba9718548b58a96A818da2c77262831A23",
  },
  [POLYGON_MAIN_ID]: {
    tokens: {
      FS: "0x525b43A49bE2Ed530e3516C22bd7ECbcF1586AD4",
      USDC: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    },
    tierNFTFactory: "0xBC0d0650412EF353D672c0Bbd12eFFF90591B251",
    tierNFTStore: "0x4db1e329713F3b4d606f3D3956Eed3EcA7dc6E99",
    tierStake: "0xC758295Cd1A564cdb020a78a681a838CF8e0627D",
    tierStakeV2: "",
    tierConfiguration: "",
  },
  [POLYGON_TEST_ID]: {
    tokens: {
      FS: "0xDa7a7bf73E118A9BC13b09EC08DCF708A1E3BE0D",
      sFS: "0x4d6DfF4BD05A59F4eE44BA26cB06066e5C79DcF2",
      USDC: "0xb9e0503bd4a5b3ab57254fd99160d6a40bf9d443",
    },
    tierNFTFactory: "0x935C321e35F0F57796Ede1A06f8bBC02062d5C84",
    tierNFTStore: "0x8cA4A86139AeA811357903Fa15215965e1e11eCc",
    tierStake: "0x219d6e16aCcfe4EE0Fa2f23b45b1176fe9CBaa50",
    tierStakeV2: "0x67F7A45b2a6E02a1bE7d8e7e2433F3F8E41CD827",
    // tierConfiguration: "",
    fsFaucet: "0xe9FEBB27888A1aD9129f069525993241C385a975",
  },
  [BSC_MAIN_ID]: {
    tokens: {
      FS: "0x1935C03bB7DbC3623bD992CE599bC26bD30A43EB",
      sFS: "0xDCB892eCAD37504ABf84938Ce888D19563060E78",
      USDC: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    },
    tierNFTFactory: "0xBC0d0650412EF353D672c0Bbd12eFFF90591B251",
    tierNFTStore: "0x4db1e329713F3b4d606f3D3956Eed3EcA7dc6E99",
    tierStake: "0xa81b289dc053c242B43aed38F2B77E30dd459Ec1",
    stakingContract: "0xBcc931EE700D036ac860Fbe1Dfd21CDa577AEAea",
    tierStakeV2: "0xF559A51049d5dFCa808f0718e41164f48AE86DA4",
    tierConfiguration: "",
  },
  [BSC_TEST_ID]: {
    tokens: {
      FS: "0x3037C5143b9d3b9861d27a3Ed42fc9fDC6864BD1",
      sFS: "0x4d6DfF4BD05A59F4eE44BA26cB06066e5C79DcF2",
      USDC: "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee",
    },
    tierNFTFactory: "0xD00b17C06e18FdD856f9503153293658BC28EB97",
    tierNFTStore: "0xd7A26D86C3881150A496Cc3ed4AC52A651C82078",
    tierStake: "0xdeeBe106A61787f0d37Ca289dc94536Eb96Ab52B",
    tierStakeV2: "0x67F7A45b2a6E02a1bE7d8e7e2433F3F8E41CD827",
    // tierConfiguration: "",
    fsFaucet: "0xC74c94C830ee23dddaa3735E5406eF9098A872c9",
    stakingContract: "0xBb431982B927F3bfA377c26fcAf95B930723f054",
  },
  [ETH_MAIN_ID]: {
    tokens: {
      FS: "0x05eC81610aB47A094502bAC0D7eC256f06a1c6f8",
      USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    },
    tierNFTFactory: "0x6854306bB50f99DAeF6258D430bDA5cd5a24479D",
    tierNFTStore: "0xebC79EAdA0751BD04d6Da6565800d69BCDbB6D01",
    tierStake: "0x8a72edBFB00E5CfC8cFE2B4Dd4049F944231320d",
    tierStakeV2: "",
  },
  [ETH_TEST_ID]: {
    tokens: {
      FS: "0x2A00Fed743Cc5312BA88D009c6135C096A6784a3",
      sFS: "0xbe5352775Cb6e890025257f3a8E2d2C2B374F396",
      USDC: "0xE9E231c897A2b6E8A17b74ec0cC81C2C9812b920",
    },
    tierNFTFactory: "0x5cF1Fa559B2E99DdB290df8Cd5E7bc5e24330b18",
    tierNFTStore: "0xBAEC59D709690a2D87EC0b1e0824870f0333d5F4",
    tierStake: "0x5DC0F8D6eFCBC887632456Bc5D497CF8e0a5ac19",
    tierStakeV2: "0x0CEbf76415b885fD49c4D0Ad6145C7289b5dA2F0",
  },
  [ARBITRUM_MAIN_ID]: {
    tokens: {
      FS: "0xbB85D38fAA5064FAB8bf3E0a79583a2670F03DbC",
      USDC: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    },
    tierNFTFactory: "0xBC0d0650412EF353D672c0Bbd12eFFF90591B251",
    tierNFTStore: "0x4db1e329713F3b4d606f3D3956Eed3EcA7dc6E99",
    tierStake: "0x1a203be68982b9062144CDe218Ca142286FFC879",
  },
  [ARBITRUM_TEST_ID]: {
    tokens: {
      FS: "0xE9E231c897A2b6E8A17b74ec0cC81C2C9812b920",
      sFS: "0xAa1B558577fbe7774f95A82436B839d44EfB3CFa",
      USDC: "0x2A00Fed743Cc5312BA88D009c6135C096A6784a3",
    },
    tierNFTFactory: "0x5cF1Fa559B2E99DdB290df8Cd5E7bc5e24330b18",
    tierNFTStore: "0xBAEC59D709690a2D87EC0b1e0824870f0333d5F4",
    tierStake: "0x5DC0F8D6eFCBC887632456Bc5D497CF8e0a5ac19",
    tierStakeV2: "0x6cBDbEb57b55f54f7de216D8E7538B7E807b1D6E",
  },
  [OPTIMISM_MAIN_ID]: {
    tokens: {
      FS: "0x22648C12acD87912EA1710357B1302c6a4154Ebc",
      USDC: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
    },
    tierNFTFactory: "0xBC0d0650412EF353D672c0Bbd12eFFF90591B251",
    tierNFTStore: "0x4db1e329713F3b4d606f3D3956Eed3EcA7dc6E99",
    tierStake: "0x1a203be68982b9062144CDe218Ca142286FFC879",
  },
  [OPTIMISM_TEST_ID]: {
    tokens: {
      FS: "0xd365F85AdED85D990981725fd54c3C0119399AC1",
      sFS: "0xE6e95A3dE16A4556BEFc74A67aE508E3bC2cECB1",
      USDC: "0xDd8bEC1421698E628bD1708e7F7f7F922c0B4d72",
    },
    tierNFTFactory: "0xfbFda45a54c736D42858d301f17C132301cCBCAd",
    tierNFTStore: "0xd8c64970CFB8Fc73bDF1bFBbf4ed4FfE7555d94A",
    tierStake: "0x3fAeBe0Ca1f1Ac5aa8a9f4e880893589d917Bf76",
    tierStakeV2: "0x963e524E7abC767AF78d7c7FC7aF0F2835310edC",
  },
  [AVALANCHE_TEST_ID]: {
    tokens: {
      FS: "0xE075f2Fe6F120575e0d550117A9842d8f7C19dF9",
      sFS: "0x47b83126c9e6139204bD506f3230349433789A83",
    },
    tierNFTFactory: "0xBb431982B927F3bfA377c26fcAf95B930723f054",
    tierNFTStore: "0x386eeB89Fc0D10283266A3c5D0339497E47CF452",
    tierStake: "0x888572d6958D37e4d53175D9f118632D55EBFc16",
    tierStakeV2: "0x13e57a463bF2Fec6E660dBbd06461Aa5486C4700",
    stakingContract: "0x459484B34c5A29cd4A8E245745F152FD111495a5",
  },
  [AVALANCHE_MAIN_ID]: {
    tokens: {
      FS: "0x3D7b4f222E40f7dcddc3B3b396cD683b8d528396",
      sFS: "0x3A8B3C8FF07f041b0A783A8849cbC02f903d47e9",
    },
    tierNFTFactory: "0x63DBBa40C2e0b9821Dd3850d51516065c308cd9e",
    tierNFTStore: "0x350824Af5010e6276eb83111c97aD9CF8Fb1a945",
    tierStake: "0xe3bc2DF0d378A7408C92ce028c380474B0aC0A25",
    tierStakeV2: "0xe3bc2DF0d378A7408C92ce028c380474B0aC0A25",
    stakingContract: "0xCED5423cd10825444c07001c8785a138F3586cD5",
  },
  [SONIC_TEST_ID]: {
    tokens: {
      FS: "0x2a00fed743cc5312ba88d009c6135c096a6784a3",
      sFS: "0xBAEC59D709690a2D87EC0b1e0824870f0333d5F4",
    },
    fsFaucet: "0xAa1B558577fbe7774f95A82436B839d44EfB3CFa",
    tierNFTFactory: "0xE9E231c897A2b6E8A17b74ec0cC81C2C9812b920",
    tierNFTStore: "0x5cF1Fa559B2E99DdB290df8Cd5E7bc5e24330b18",
    tierStakeV2: "0x509F9EDDEcA9FF861ACCFF01c1a7Aeb1920eE407",
    stakingContract: "0x247d4f42480449b2e7454943643CdDDF9965d050",
  },
};
